import React, { useState, useEffect, useCallback } from 'react'
import { Box, Container ,Radio ,RadioGroup, FormControlLabel, FormLabel , Checkbox, TextField} from '@mui/material'
import { Col, Row, Button } from 'react-bootstrap'
import './oneWayResults.css'
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux'
import OnewaySearchSkelton from '../../skeleton/OnewaySearchSkelton'
import { bookingSources, setAllAirline, setMinMaxValue } from '../../../store/flights/actions/flightSearchActions'
import { fareIndentifire, stops } from '../../../store/flights/actions/flightFilterActions'
import Pricerange from './filters/Pricerange'
import Shownet from './filters/Shownet'
import Stops from './filters/Stops'
import Fareidentifier from './filters/Fareidentifier'
import Airlinesfilter from './filters/Airlinesfilter'
import './flightSearchResults.css'
import Timing from './filters/Timing'
import RoundTripInternationalResults from './RoundTripInternationalResult'
import NotFoundFlight from './NotFoundFlight'
import FlightLoader from '../../skeleton/FlightLoader'
import InfiniteScroll from 'react-infinite-scroll-component'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import NotFindData from './NotFindData'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { flightDate } from '../../../core/helper';
import CloseIcon from '@mui/icons-material/Close';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SortIcon from '@mui/icons-material/Sort';
import groupfareG from '../../../assets/images/groupfare.webp'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  tltreviewleft: {
    fontSize: '13px',
    fontWeight: '500',
    color: '#1675b8',
    flexBasis: '50%',
    textAlign: 'right',
    textTransform: 'capitalize'
  },
  tltreviewright: {
    fontSize: '13px',
    fontWeight: '500',
    color: '#1675b8',
    flexBasis: '50%',
    textAlign: 'left',
    textTransform: 'capitalize'
  },
  flexset:
    {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      padding: '0px 0px'
    }
}))

const RoundTripInternationalSearchResults = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [skeltonCount, setSkeltonCount] = useState(10)
  const [flightDetails, setFlightDetails] = useState([])
  const [filter, setFilter] = useState({
    priceMinRange: 0,
    priceMaxRange: 0,
    showNet: false,
    stops: null,
    arrivalTime: null,
    depatureTime: null,
    fareIdentifire: [],
    airline: []
  })
  const [loader, setLoader] = useState(true)

  const range = useSelector(state => state.rangeValue)

  const { fsRoundReqData, fsRoundInternationalData, fsRoundInternationalCount, fsRoundInternationalError, fsRoundInternationalLoading } = useSelector(state => state.flightResult)
  const { data } = { ...fsRoundInternationalData }
  const { raw_flight_list } = { ...data }
  const { Flights } = { ...raw_flight_list }
  const [filterDataLength, setFilterDataLength] = useState(false)
  const [loadMore, setLoadMore] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [paginationData, setPaginationData] = useState([])

  const [sharedFlightList, setSharedFlightList] = useState([]);
  const [sharedMobileNum, setSharedMobileNum] = useState(null);
  const [sharedMobileNumError, setSharedMobileNumError] = useState(null);
  const [selectedFlight, setSelectedFlight] = useState([]);

  const [showFilterMob, setshowFilterMob] = useState(true)
  const [showSortByMob, setshowSortByMob] = useState(true)
  const [addMarkup, setAddMarkup] = useState(false);
  const [addMarkupValue, setAddMarkupValue] = useState(null);


  const hideMarkup = () =>{
    setAddMarkup(false)
    setAddMarkupValue(null);
  } 

  const toggleFilter = () =>{
    setshowFilterMob(!showFilterMob)
  }

  const toggleSortBy = () =>{
    setshowSortByMob(!showSortByMob)
  }

  const [toggleSort, setToggleSort] = useState({
    departure: false,
    duration: false,
    arrival: false,
    price: true,
    type: null,
    airline: false
  })
  const BookingSourceLength = bookingSources.length

  function groupBy (list, keyGetter) {
    const map = new Map()
    list.forEach((item) => {
      const key = keyGetter(item)
      const collection = map.get(key)
      if (!collection) {
        map.set(key, [item])
      } else {
        collection.push(item)
      }
    })
    return map
  }

  function groupFlights (array, f) {
    const groups = {}
    array.forEach(function (o) {
      const group = JSON.stringify(f(o))
      groups[group] = groups[group] || []
      groups[group].push(o)
    })
    return Object.keys(groups).map(function (group) {
      return groups[group]
    })
  }

  const getMinMaxValue = (fligths) => {
    let min = Flights[0][0].FareDetails.b2b_PriceDetails._CustomerBuying
    let max = Flights[0][0].FareDetails.b2b_PriceDetails._CustomerBuying

    const airlines = []
    const airMap = []
    const fareMap = []

    {
      fligths.map((value, index) => {
        airlines.push(value.SegmentSummary[0].AirlineDetails.AirlineName)
        const v = value.FareDetails.b2b_PriceDetails._CustomerBuying
        min = (v < min) ? v : min
        max = (v > max) ? v : max
      })
    }

    //  const result =  airlines.reduce((json,val)=>({...json, [val]:(json[val] | 0) + 1}),{});
    //   let aa = uniqueAirline(airlines);
    const grouped = groupBy(fligths, value => value.SegmentSummary[0].AirlineDetails.AirlineName)

    const fareIdentifires = groupBy(fligths, value => value.Attr.Faretype)

    for (const entry of grouped.entries()) {
      let airMinPrice = entry[1][0].FareDetails.b2b_PriceDetails._CustomerBuying

      {
        entry[1].map((value, index) => {
          const v = value.FareDetails.b2b_PriceDetails._CustomerBuying
          airMinPrice = (v < airMinPrice) ? v : airMinPrice
        })
      }

      const obj = {
        count: Object.keys(entry[1]).length,
        price: airMinPrice,
        name: entry[0],
        isChecked: false
      }
      airMap.push(obj)
    }

    for (const fi of fareIdentifires.entries()) {
      const obj = {}

      if (fi[0] === 'REGULAR') {
        obj.color = 'colordefine3'
      } else if (fi[0] === 'TACTICAL') {
        obj.color = 'colordefine6'
      } else if (fi[0] === 'SME') {
        obj.color = 'colordefine4'
      } else if (fi[0] === 'DEAL') {
        obj.color = 'colordefine2'
      } else if (fi[0] === 'CORPORATE') {
        obj.color = 'colordefine1'
      } else {
        obj.color = 'colordefine5'
      }

      obj.count = Object.keys(fi[1]).length
      obj.name = fi[0]
      obj.isChecked = false

      fareMap.push(obj)
    }
    dispatch(setMinMaxValue([min, max]))
    dispatch(setAllAirline(airMap))
    dispatch(fareIndentifire(fareMap))
  }

  const onChangeDepFrom = useCallback(
    (time) => {
      const flit = { filter }
      flit.filter.depatureTime = time
      onFilterData()
    }
  )

  const onChangeArrAt = useCallback(
    (time) => {
      const flit = { filter }
      flit.filter.arrivalTime = time
      onFilterData()
    }
  )

  const onPriceRageChange = useCallback(
    (ranges) => {
      const flit = { filter }
      flit.filter.priceMinRange = ranges[0]
      flit.filter.priceMaxRange = ranges[1]
      onFilterData()
    }
  )

  const handleRangChange1 = useCallback(
    (ranges) => {
      setFilter({
        ...filter,
        priceMinRange: ranges[0],
        priceMaxRange: ranges[1]
      })
    }
  )

  const onStopChange = useCallback(
    (stop) => {
      const flit = { filter }
      flit.filter.stops = stop

      onFilterData()
    }
  )

  const onShowNetChanges = useCallback(
    (datas) => {
      const flit = { filter }
      flit.filter.showNet = datas
      onFilterData()
    }
  )
  const onAirlinesfilterChange = useCallback(
    (datas, status) => {
      const flit = { filter }

      if (status) {
        flit.filter.airline.push(datas)
      } else {
        flit.filter.airline.splice(flit.filter.airline.indexOf(datas), 1)
      }
      onFilterData()
    }
  )

  const onFareIdentifireChange = useCallback(
    (datas, status) => {
      const flit = { filter }
      if (flit.filter.fareIdentifire.includes(datas)) {
        flit.filter.fareIdentifire.splice(flit.filter.fareIdentifire.indexOf(datas), 1)
      } else {
        flit.filter.fareIdentifire.push(datas)
      }

      onFilterData()
    }
  )

  const clearAirline = useCallback(
    () => {
      const flit = { filter }
      flit.filter.airline = []
      onFilterData()
    }

  )

  const timeSplitDash = (time) => {
    const arr = time.split('-')
    const data1 = arr[0] * 3600
    const data2 = arr[1] * 3600

    return [data1, data2]
  }

  const timeSplitColon = (time) => {
    const arr = time.split(':')
    const data = (arr[0] * 3600) + (arr[1] * 60)
    return data
  }

  const onFilterData = () => {

    if (data) {
      let result = null

      result = Flights[0].filter((value) => {
        if (filter.priceMinRange <= value.FareDetails.b2b_PriceDetails._CustomerBuying && value.FareDetails.b2b_PriceDetails._CustomerBuying <= filter.priceMaxRange) {
          return value
        } else {
          return Flights[0]
        }
      }
      )
      if (filter.stops === null) {
        result = result
      } else {
        result = result.filter((value) => {
          if (filter.stops === null) {
            return value
          } else {

            if (filter.stops === 3) {
              return filter.stops <= value.SegmentSummary[0].TotalStops
            } else {
              return value.SegmentSummary[0].TotalStops === filter.stops
            }
          }
        })
      }

      if (filter.depatureTime === null) {
        result = result
      } else {
        result = result.filter((value) => {
          const d = timeSplitDash(filter.depatureTime)
          const t = timeSplitColon(value.SegmentSummary[0].OriginDetails._DateTime)
          if (d[0] <= t && d[1] >= t) {
            return value
          }
        })
      }

      if (filter.arrivalTime === null) {
        result = result
      } else {
        result = result.filter((value) => {
          const d = timeSplitDash(filter.arrivalTime)
          const t = timeSplitColon(value.SegmentSummary[0].DestinationDetails._DateTime)
          if (d[0] <= t && d[1] >= t) {
            return value
          }
        })
      }

      if (filter.airline.length === 0) {
        result = result
      } else {
        result = result.filter((value) => {
          if (filter.airline.includes(value.SegmentSummary[0].AirlineDetails.AirlineName)) {
            return value
          }
        })
      }

      if (filter.fareIdentifire.length === 0) {
        result = result
      } else {
        result = result.filter((value) => {
          if (filter.fareIdentifire.includes(value.Attr.Faretype)) {
            return value
          }
        })
      }

      result = sortByPrice(result)
      if (result.length > 0) {
        setFilterDataLength(true)
      } else {
        setFilterDataLength(false)
      }
      window.scrollTo(0, 0)
      setFlightDetails(result)
      sliceArray(result)
      setLoadMore(true)
      setCurrentPage(0)
    }
  }

  const handleReset = () => {
    const filt = { filter }
    filt.filter.priceMinRange = range.minValue
    filt.filter.priceMaxRange = range.maxValue
    filt.filter.showNet = false
    filt.filter.stops = null
    filt.filter.arrivalTime = null
    filt.filter.depatureTime = null
    filt.filter.fareIdentifire = []
    filt.filter.airline = []
    setFilterDataLength(false)
    dispatch(setMinMaxValue([range.minValue, range.maxValue]))
    onShowNetChanges(false)
    dispatch(stops(null))
    onFilterData()
  }

  useEffect(() => {
    setFlightDetails([])
    setPaginationData([])

    if (data) {
      getMinMaxValue(Flights[0])
      const results = sortByPrice(Flights[0])
      setFlightDetails(results)
      sliceArray(results)
      setLoader(false)
    } else {
      setLoader(false)
      setFlightDetails([])
      sliceArray([])
    }
    if (fsRoundInternationalCount === BookingSourceLength) {
      setLoadMore(true)
    }
  }, [fsRoundInternationalCount])

  const sliceArray = (resultss) => {
    const startIndex = 0
    const endIndex = 20
    const slicedRecords = resultss.slice(startIndex, endIndex)
    setPaginationData(slicedRecords)
  }

  const handleObserver = () => {
    setTimeout(() => {
      setCurrentPage((page) => page + 1)
    }, 500)
  }

  useEffect(() => {
    if (currentPage > 0 && fsRoundInternationalCount === BookingSourceLength) {
      const dataLimit = 20
      const startIndex = (currentPage * dataLimit)
      const endIndex = (startIndex + dataLimit)
      const slicedRecords = flightDetails.slice(startIndex, endIndex)
      const data = [...paginationData, ...slicedRecords]
      setPaginationData(data)
      if (data.length === flightDetails.length) {
        setLoadMore(false)
      }
    }
  }, [currentPage])

  // function to sort array by price
  const sortByPrice = (resultss) => {
    // sorting price inside grouping
    for (let i = 0; i < resultss.length; i++) {
      resultss.sort(function (a, b) { return a.FareDetails.b2b_PriceDetails._CustomerBuying - b.FareDetails.b2b_PriceDetails._CustomerBuying })
    }
    // sorting price inside sections
    // resultss.sort(function (a, b) { return a[0].FareDetails.b2b_PriceDetails._CustomerBuying - b[0].FareDetails.b2b_PriceDetails._CustomerBuying });

    return resultss
  }

  useEffect(() => {
    if (toggleSort.type) {
      sortByAsceDesc(toggleSort.type)
    }
  }, [toggleSort])

  const sortByAsceDesc = (type) => {
    const result = [...flightDetails]
    if (type === 'duration') {
      if (toggleSort.duration) {
        result.sort(function (a, b) {
          const from = a.SegmentSummary[0].TotalDuaration.split(/[hm]/)
          const to = b.SegmentSummary[0].TotalDuaration.split(/[hm]/)
          return (from[0] * 60 * 60 + from[1] * 60) * 1000 - (to[0] * 60 * 60 + to[1] * 60) * 1000
        })
      } else {
        result.sort(function (a, b) {
          const from = a.SegmentSummary[0].TotalDuaration.split(/[hm]/)
          const to = b.SegmentSummary[0].TotalDuaration.split(/[hm]/)
          return (to[0] * 60 * 60 + to[1] * 60) * 1000 - (from[0] * 60 * 60 + from[1] * 60) * 1000
        })
      }
    } else if (type === 'departure') {
      if (toggleSort.departure) {
        result.sort(function (a, b) { return a.SegmentSummary[0].OriginDetails.FDTV - b.SegmentSummary[0].OriginDetails.FDTV })
      } else {
        result.sort(function (a, b) { return b.SegmentSummary[0].OriginDetails.FDTV - a.SegmentSummary[0].OriginDetails.FDTV })
      }
    } else if (type === 'arrival') {
      if (toggleSort.arrival) {
        result.sort(function (a, b) { return a.SegmentSummary[0].DestinationDetails.FATV - b.SegmentSummary[0].DestinationDetails.FATV })
      } else {
        result.sort(function (a, b) { return b.SegmentSummary[0].DestinationDetails.FATV - a.SegmentSummary[0].DestinationDetails.FATV })
      }
    } else if (type === 'price') {
      if (toggleSort.price) {
        result.sort(function (a, b) { return a.FareDetails.b2b_PriceDetails._CustomerBuying - b.FareDetails.b2b_PriceDetails._CustomerBuying })
      } else {
        result.sort(function (a, b) { return b.FareDetails.b2b_PriceDetails._CustomerBuying - a.FareDetails.b2b_PriceDetails._CustomerBuying })
      }
    } else if (type === 'airline') {
      if (toggleSort.airline) {
        result.sort(function (a, b) {
          return a.SegmentSummary[0].AirlineDetails.AirlineName.localeCompare(b.SegmentSummary[0].AirlineDetails.AirlineName)
        })
      } else {
        result.sort(function (a, b) {
          return b.SegmentSummary[0].AirlineDetails.AirlineName.localeCompare(a.SegmentSummary[0].AirlineDetails.AirlineName)
        })
      }
    }

    window.scrollTo(0, 0)
    setFlightDetails(result)
    sliceArray(result)
    setLoadMore(true)
    setCurrentPage(0)
  }

  const toggleSortFnc = (type) => {
    const obj = {
      departure: false,
      arrival: false,
      duration: false,
      price: false,
      type: type,
      airline: false
    }
    if (type === 'departure') {
      obj.departure = !toggleSort[type]
    } else if (type === 'arrival') {
      obj.arrival = !toggleSort[type]
    } else if (type === 'duration') {
      obj.duration = !toggleSort[type]
    } else if (type === 'price') {
      obj.price = !toggleSort[type]
    } else if (type === 'airline') {
      obj.airline = !toggleSort[type]
    }

    setToggleSort(obj)
  }

  const onToggleSelectedFlight= (item, origin, destination, attr, price, data, type)=> {
    //console.log("I am on", item)
    let id = origin !== undefined ? data.ProvabAuthKey : item;
    if(selectedFlight.length > 0 && selectedFlight.some(item => item === id)){
     // console.log("I am on 1",id)
      let newSelectedItem = selectedFlight.filter(item => item !== id);
      let newSharedFlightList = sharedFlightList.filter(item=> item.provabAuthKey !== id);
      setSelectedFlight(newSelectedItem);
      setSharedFlightList(newSharedFlightList);
    }
    else{
    // console.log("I am on2",id)
      let newSelectedItem = [...selectedFlight, id];
     // console.log("newSelectedItem2",newSelectedItem)
      setSelectedFlight(newSelectedItem);
      let dataObj = 
                {
                  "id": item.AirlineDetails.FlightNumber,
                  "flightCode": item.AirlineDetails.AirlineCode,
                  "flightName": item.AirlineDetails.AirlineName,
                  "flightNumber": item.AirlineDetails.FlightNumber,
                  "total_duration": item.TotalDuaration,
                  "total_stops": item.TotalStops,
                  "flightDetails": data.SegmentSummary.map(item=>({
                                  "id": item.AirlineDetails.FlightNumber,
                                  "flightCode": item.AirlineDetails.AirlineCode,
                                  "flightName": item.AirlineDetails.AirlineName,
                                  "flightNumber": item.AirlineDetails.FlightNumber,
                                  "total_duration": item.TotalDuaration,
                                  "total_stops": item.TotalStops,
                                  })),
                  "originDetails": data.SegmentSummary.map(item=> item.OriginDetails).map(origin=> ({
                                    "from_city": origin.CityName,
                                    "from_city_time": origin._DateTime,
                                    "from_city_date": origin._Date,
                                    "from_city_year": origin.DateTime,
                                  })),
                  "destinationDetails": data.SegmentSummary.map(item=> item.DestinationDetails).map(destination=> ({
                                    "to_city": destination.CityName,
                                    "to_city_time": destination._DateTime,
                                    "to_city_date": destination._Date,
                                    "to_city_year": destination.DateTime,
                                  })),
                  "segmentDetails": data.SegmentDetails.map(item=>({
                    "segment_details": item
                  })),
                  "segment_details": data.SegmentDetails[0],
                  "from_city": origin.CityName,
                  "from_city_time": origin._DateTime,
                  "from_city_date": origin._Date,
                  "from_city_year": origin.DateTime,
                  "to_city": destination.CityName,
                  "to_city_time": destination._DateTime,
                  "to_city_date": destination._Date,
                  "to_city_year": destination.DateTime,
                  "segment_details": data.SegmentDetails[0],
                  "provabAuthKey": data.ProvabAuthKey,
                  "fare_type": attr.Faretype,
                  "price": price,
                  "type": type,
                  "remarks": null,}
           // console.log("Data",dataObj)
        setSharedFlightList([...sharedFlightList, dataObj]);
    }
  }

  const handleInputChange = (e, index, value) => {
    let sharedFlightListArray = JSON.parse(JSON.stringify(sharedFlightList));
    // let objIndex = sharedFlightList.findIndex((obj => obj.id === id));
    // sharedFlightListArray[index].price = e.target.value;
    // setSharedFlightList(sharedFlightListArray);
    if (value === "price") {
      sharedFlightListArray[index].price = e.target.value;
      setSharedFlightList(sharedFlightListArray);
    }
    else {
      sharedFlightListArray[index].remarks = e.target.value;
      setSharedFlightList(sharedFlightListArray);
    }
  }
  const handleSharedMobileNum = (e) => {
    setSharedMobileNumError(null);
    const onlyNums = e.target.value.replace(/[^0-9]/g, '');
    if (onlyNums.length < 10) {
      setSharedMobileNum(onlyNums);
    } else if (onlyNums.length === 10) {
      const number = onlyNums;
      setSharedMobileNum(number);
    }
  }

  const onShareByWhatsApp= ()=> {
    if(sharedMobileNum === null){
      setSharedMobileNumError("Please enter mobile number");
      return;
    }
    else if(sharedMobileNum === ""){
      setSharedMobileNumError("Please enter mobile number");
      return;
    }
    else if(sharedMobileNum && sharedMobileNum.length < 10){
      setSharedMobileNumError("Please enter 10 digit mobile number");
      return;
    }
    // `*${item.flightName}*(${item.flightCode+ "-" + item.flightNumber}): Onward%0A*${item.from_city+ "-"+ item.to_city}*%0A(${item.from_city_time + ", "+ flightDate(item.from_city_date) + ", " + new Date(item.from_city_year).getFullYear() + " - "+ item.to_city_time + ", "+ flightDate(item.to_city_date) + ", " + new Date(item.to_city_year).getFullYear()})%0A*Duration:* ${item.total_duration}%0A*Faretype:* ${item.fare_type}%0A*${item.price !== 0 ? `Price:* ${item.price}`: ""}%0A${item.remarks !== null ? `*Remarks:* ${item.remarks}` : ''}%0A%0A`
     
    
    else{
    let url = `https://api.whatsapp.com/send/?phone=91${sharedMobileNum}&text= ${sharedFlightList.map(item => {
      return (
        `${item.flightDetails.map((x,i)=>
          {return(`*${x.flightName}* (${x.flightCode + "-" + x.flightNumber}): ${i == 0 ? 'Onward' : 'Return'}%0A*${item.originDetails[i].from_city + " - " + item.destinationDetails[i].to_city}*${(x.total_stops > 0 && item.segmentDetails.length > 0) ? " Via (" + item.segmentDetails[i].segment_details.slice(0, -1).map(x => x.DestinationDetails.CityName).join(", ") + ")" : ""}*%0A*Departure:* ${item.originDetails[i].from_city_time + ", " + flightDate(item.originDetails[i].from_city_date) + " " + new Date(item.originDetails[i].from_city_year).getFullYear() + ", " + new Date(item.originDetails[i].from_city_year).toLocaleString('en-us', {weekday:'short'}) + "%0A*Arrival:* "+ item.destinationDetails[i].to_city_time + ", "+ flightDate(item.destinationDetails[i].to_city_date) + " " + new Date(item.destinationDetails[i].to_city_year).getFullYear() + ", " + new Date(item.destinationDetails[i].to_city_year).toLocaleString('en-us', {weekday:'short'})}%0A*Duration:* ${x.total_duration} ${x.total_stops > 0 ? `(${x.total_stops} ${x.total_stops > 1 ? "stops" : "stop"})` : ""}%0A%0A`)}).join('\n')}*Fare type:* ${item.fare_type}%0A${item.price !== 0 ? `*Price:* INR ${item.price + (addMarkupValue === null ? '' : parseInt(addMarkupValue))}` : ""}%0A${item.remarks !== null ? `*Remarks*: ${item.remarks}` : ''}%0A%0A`)
    }).join('')}*Note* :  All airfares are subject to availability at time of booking and are not guaranteed until purchased and the e-tickets have been issued.&app_absent=0`
    window.open(url, "_blank")
    }
  }

  const copy = () => {
    let a=  `${sharedFlightList.map(item => {return(
        `${item.flightName}(${item.flightCode+ "-" + item.flightNumber}): Onward\n${item.from_city+ "-"+ item.to_city}\n(${item.from_city_time + ", "+ flightDate(item.from_city_date) + ", " + new Date(item.from_city_year).getFullYear() + " - "+ item.to_city_time + ", "+ flightDate(item.to_city_date) + ", " + new Date(item.to_city_year).getFullYear()})\nDuration: ${item.total_duration}\nFaretype: ${item.fare_type}\n${item.price !== 0 ? `Price: ${item.price}`: ""}\n${item.remarks !== null ? `Remarks: ${item.remarks}` : ''}\n\n\n`
      )}).join('')}`
     // console.log("sharedFlightList",a)
      navigator.clipboard.writeText(a)
    }
  //console.log("sharedFlightList",sharedFlightList)

  const {
    fsData, isLoading, fsRequestData, fsInvData, error,
    fsOneWay6E,
    fsOneWayGDS,
    fsOneWayTBO,
    fsOnewayCount
  } = useSelector(state => state.flightResult)

  const [isVisible, setIsVisible] = useState(false);
  
  useEffect(() => {   
    window.addEventListener("scroll", listenToScroll);
    return () => 
        window.removeEventListener("scroll", listenToScroll); 
  }, [])
  
  const listenToScroll = () => {
    let heightToHideFrom = document.getElementById('filterheight').offsetHeight
    const winScroll = document.body.scrollTop || 
      document.documentElement.scrollTop;
      if (winScroll > heightToHideFrom) {  
          setIsVisible(true);
      } else{
          setIsVisible(false);
      }  
  };
  const copyGroupFareData = () => {
    var urlField = document.querySelector('#groupFareData');
    // create a Range object
    var selection = window.getSelection();
    var range = document.createRange();
    range.selectNodeContents(urlField);
    selection.removeAllRanges();
    selection.addRange(range);

    // execute 'copy', can't 'cut' in this case
    document.execCommand('copy');
  }
  const copyTableFormat = () => {
    var urlField = document.querySelector('#tableDataRound');
    // create a Range object
    var selection = window.getSelection();
    var range = document.createRange();
    range.selectNodeContents(urlField);
    selection.removeAllRanges();
    selection.addRange(range);

    // execute 'copy', can't 'cut' in this case
    document.execCommand('copy');
  }

  return (
        <>

            <Container fluid className="flightresultcontainer flightresultcontainer-r flight-internation-round">
                <Row className="mt-2 mb-5">
                    <Col xs={12} sm={3} md={3} lg={3} className="flitercolumstick colspace filter-mob" style={{ display: !showFilterMob ? "block" : "none" }}>
                        <Box className="filter-content" id="filterheight">
                            <div className="tlt-filter-header">
                                <div className="tltrange-filter">Filter Search</div>

                                <div className="tltrangereset" onClick={handleReset}>Reset All</div>
                            </div>
                            <div className={ isVisible ? 'showFixedFilter' : ''}>
                              <Airlinesfilter handleChange={onAirlinesfilterChange} airlineClear={clearAirline} isSelected={filter.airline} />
                            </div>
                            <Stops handleChange={onStopChange} isStops={filter.stops} />
                            <Pricerange handleRangChange={onPriceRageChange} onPrice={handleRangChange1} />
                            <Timing title="Departure From " source={fsRoundReqData?.from} handleChange={onChangeDepFrom} isSelected={filter.depatureTime} />
                            <Timing title="Arrival At " source={fsRoundReqData?.to} handleChange={onChangeArrAt} isSelected={filter.arrivalTime} />
                            <Fareidentifier handleChange={onFareIdentifireChange} isSelected={filter.fareIdentifire} />
                        </Box>
                    </Col>
                    <Col xs={12} sm={12} md={3} lg={3} className="flitercolumstick colspace sortby-mob"  style={{ display: !showSortByMob ? "block" : "none" }}>
                        <Box className="filter-content">
                            <div className="tlt-filter-header">
                                <div className="tltrangereset">Sort By</div>
                                <div className="tltrange-minimize">-</div>
                            </div>
                            <Row className="viewresultbooking addonbackground">
                              <Col xs={12} sm={2} md={2} lg={2} xl={2} className="zerogutter">
                              <Row className='sort-by-border'>
                                  <Col xs={6} className="px-0">
                                    <FormLabel className='sortby-label' id="demo-radio-buttons-group-label">Price</FormLabel>
                                  </Col>
                                  <Col xs={6} className="px-0">
                                    <RadioGroup className='sortby-radiogrp' aria-labelledby="demo-radio-buttons-group-label" defaultValue="low" name="radio-buttons-group">
                                      <FormControlLabel labelPlacement="start" onChange={() => { toggleSortFnc('price') }} value="low" control={<Radio />} label="Low to High" />
                                      <FormControlLabel labelPlacement="start" onChange={() => { toggleSortFnc('price') }} value="high" control={<Radio />} label="High to Low" />
                                    </RadioGroup>
                                  </Col>
                                </Row>

                                <Row className='sort-by-border'>
                                  <Col xs={6} className="px-0">
                                    <FormLabel className='sortby-label' id="demo-radio-buttons-group-label">Duration</FormLabel>
                                  </Col>
                                  <Col xs={6} className="px-0">
                                    <RadioGroup className='sortby-radiogrp' aria-labelledby="demo-radio-buttons-group-label" defaultValue="low" name="radio-buttons-group">
                                      <FormControlLabel labelPlacement="start" onChange={() => { toggleSortFnc('duration') }} value="shortest" control={<Radio />} label="Shortest First" />
                                      <FormControlLabel labelPlacement="start" onChange={() => { toggleSortFnc('duration') }} value="longest" control={<Radio />} label="Longest First" />
                                    </RadioGroup>
                                  </Col>
                                </Row>
                                
                                <Row className='sort-by-border'>
                                  <Col xs={6} className="px-0">
                                    <FormLabel className='sortby-label' id="demo-radio-buttons-group-label">Departure</FormLabel>
                                  </Col>
                                  <Col xs={6} className="px-0">
                                    <RadioGroup className='sortby-radiogrp' aria-labelledby="demo-radio-buttons-group-label" defaultValue="low" name="radio-buttons-group">
                                      <FormControlLabel labelPlacement="start" onChange={() => { toggleSortFnc('departure') }} value="earliest" control={<Radio />} label="Earliest First" />
                                      <FormControlLabel labelPlacement="start" onChange={() => { toggleSortFnc('departure') }} value="latest" control={<Radio />} label="Latest First" />
                                    </RadioGroup>
                                  </Col>
                                </Row>

                                <Row className='sort-by-border'>
                                  <Col xs={6} className="px-0">
                                    <FormLabel className='sortby-label' id="demo-radio-buttons-group-label">Arrival</FormLabel>
                                  </Col>
                                  <Col xs={6} className="px-0">
                                    <RadioGroup className='sortby-radiogrp' aria-labelledby="demo-radio-buttons-group-label" defaultValue="low" name="radio-buttons-group">
                                      <FormControlLabel labelPlacement="start" onChange={() => { toggleSortFnc('arrival') }} value="earliest" control={<Radio />} label="Earliest First" />
                                      <FormControlLabel labelPlacement="start" onChange={() => { toggleSortFnc('arrival') }} value="latest" control={<Radio />} label="Latest First" />
                                    </RadioGroup>
                                  </Col>
                                </Row>

                                <Row className='sort-by-border'>
                                  <Col xs={6} className="px-0">
                                    <FormLabel className='sortby-label' id="demo-radio-buttons-group-label">Airline</FormLabel>
                                  </Col>
                                  <Col xs={6} className="px-0">
                                    <RadioGroup className='sortby-radiogrp' aria-labelledby="demo-radio-buttons-group-label" defaultValue="low" name="radio-buttons-group">
                                      <FormControlLabel labelPlacement="start" onChange={() => { toggleSortFnc('airline') }} value="earliest" control={<Radio />} label="Earliest First" />
                                      <FormControlLabel labelPlacement="start" onChange={() => { toggleSortFnc('airline') }} value="latest" control={<Radio />} label="Latest First" />
                                    </RadioGroup>
                                  </Col>
                                </Row>

                              </Col>
                            </Row>
                        </Box>
                    </Col>
                    <Col xs={12} sm={12} md={9} lg={9} className="white-bg-xs">

                        {fsRoundInternationalCount !== BookingSourceLength && (
                            <div>
                                <FlightLoader />
                            </div>
                        )}
                        {(fsRoundInternationalLoading) && (
                            <div>

                                {[...Array(skeltonCount)].map((e, i) => <OnewaySearchSkelton key={i} />)}
                            </div>
                        )}

                            <>
                                {paginationData.length > 0 ? (

                                    <Box>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                              <Box className="filter-msg-output filter-msg-output-multi px-0">
                                                  found {Object.keys(flightDetails).length} flights from {fsRoundReqData.from} to {fsRoundReqData.to}
                                              </Box>
                                            </Col>
                                            {/* <Col xs={12} sm={5} md={5} lg={5}>
                                              <Box className="setbtnprevnext-w">
                                              <Box className='whatsapp-share-text pl-2'>
                                                  <FormControlLabel className="check-whatsapp"
                                                    control={<Checkbox checked={filter.showWhatsApp} onChange={()=>setFilter({...filter, showWhatsApp: !filter.showWhatsApp})} name="checkedA" />}
                                                    label=""
                                                  />
                                                  <span className='whatsapp-share-icon'><WhatsAppIcon /> WhatsApp</span>
                                                </Box>
                                                <Shownet onShowNetChange={onShowNetChanges} isChecked={filter.showNet} />
                                              </Box>
                                            </Col> */}
                                        </Row>
                                        <Row className="viewresultbooking addonbackground sortby-des">
                                            <Col xs={3} sm={3} md={2} lg={2} xl={2} className="zerogutter">
                                                {/* <Box className="tltreviewheader">sort By : Duration</Box> */}
                                                <Box className="tltreviewheader text-center" onClick={() => { toggleSortFnc('airline') }}>Airline
                                                {
                                                        toggleSort.type === 'airline'
                                                          ? toggleSort.airline ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />
                                                          : null
                                                    }
                                                </Box>
                                            </Col>
                                            <Col xs={4} sm={4} md={5} >
                                            <Box className={classes.flexset}>
                                                <Box className="tltreviewleft d-flex align-items-center" onClick={() => { toggleSortFnc('departure') }}>
                                                    Departure
                                                    {
                                                        toggleSort.type === 'departure'
                                                          ? toggleSort.departure ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />
                                                          : null
                                                    }
                                                </Box>
                                                <Box className="tltreviewcenter d-flex align-items-center" onClick={() => { toggleSortFnc('duration') }}>
                                                    Duration
                                                    {
                                                        toggleSort.type === 'duration'
                                                          ? toggleSort.duration ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />
                                                          : null
                                                    }
                                                </Box>
                                                <Box className="tltreviewright d-flex align-items-center" onClick={() => { toggleSortFnc('arrival') }}>
                                                    Arrival
                                                    {
                                                        toggleSort.type === 'arrival'
                                                          ? toggleSort.arrival ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />
                                                          : null
                                                    }
                                                </Box>
                                            </Box>
                                            </Col>
                                            <Col xs={5} sm={5} md={5} lg={5} xl={5} className="zerogutter">
                                              <Box  className='d-flex'>
                                                <Box className="tltreviewheader" onClick={() => { toggleSortFnc('price') }}>
                                                    Price
                                                    {
                                                        toggleSort.type === 'price' || !toggleSort.type
                                                          ? toggleSort.price ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />
                                                          : null
                                                    }
                                                </Box>
                                                {filter.showNet ? (
                                                <Box className="tltreviewheader netpricealign" onClick={() => { toggleSortFnc('price') }}>
                                                    Net Price
                                                    {
                                                        toggleSort.type === 'price' || !toggleSort.type
                                                          ? toggleSort.price ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />
                                                          : null
                                                    }
                                                </Box>
                                                ): null}
                                              </Box>
                                            </Col>
                                        </Row>

                                        <InfiniteScroll
                                            dataLength={currentPage * 20}
                                            next={handleObserver}
                                            hasMore={loadMore}
                                            loader={<div>
                                                {[...Array(3)].map((e, i) => <OnewaySearchSkelton key={i} />)}
                                            </div>}>
                                            {paginationData.map((value, index) => (
                                                <RoundTripInternationalResults 
                                                results={value} 
                                                key={index} 
                                                showNetFare={filter.showNet} 
                                                showWhatsApp={filter.showWhatsApp}
                                                onToggleSelectedFlight={onToggleSelectedFlight}
                                                selectedFlight={selectedFlight}
                                                setModalFlight={props.setModalFlight}
                                                setOnewayFlight={props.setOnewayFlight}
                                                setOnwardFlight={props.setOnwardFlight}
                                                setReturnFlight={props.setReturnFlight}
                                                setIsInt={props.setIsInt}
                                                />
                                            ))
                                            }
                                        </InfiniteScroll>
                                    </Box>
                                )
                                  : filterDataLength
                                    ? <NotFindData handleReset={handleReset} />
                                    : !fsRoundInternationalLoading && fsRoundInternationalCount === BookingSourceLength
                                        ? <NotFoundFlight />
                                        : null
                                }
                            </>

                    </Col>
                </Row>
                {sharedFlightList.length > 0 &&(
                  <Box className='share-container'> 
                    <Box className='reset-btn'>
                      <Button variant="text" onClick={()=> {setSharedFlightList([]); setSelectedFlight([])}}>Reset All</Button>
                    </Box>
                    <Row className='mb-0'>
                      {sharedFlightList.length > 0 && sharedFlightList.map((item, index) => 
                      <>
                        <Col xs={12}>
                          <Button onClick={()=>{onToggleSelectedFlight(item.provabAuthKey)}} className='share-cross-btn'><CloseIcon /></Button>
                        </Col>
                        <Row className='share-row-wrapper'>
                        
                        
                          <Col xs={3} className="share-img-wrapper">
                            {/* <Box className='share-img'><img src={airlineImg} /></Box> */}
                            <Box className={'itwgo-60x35_' + item.flightCode}></Box>
                          </Col>
                          <Col  xs={7}>
                            <p className='share-ttl-1'>{item.flightName + " (" + item.flightCode + "-" + item.flightNumber + ")"}</p>
                            <p className='share-ttl-1'>{item.from_city + " - " + item.to_city }</p>
                            <p className='share-ttl-1'>{(item.total_stops > 0 && item.segment_details.length > 0) ? "Via (" + item.segment_details.slice(0, -1).map(x=> x.DestinationDetails.CityName).join(", ") + ")" : null}</p>
                          </Col>
                          
                          <Col xs={12}>
                            
                            <p className='share-ttl-1'>{"Departure: " + item.from_city_time + ", " + flightDate(item.from_city_date) + ", " + new Date(item.from_city_year).getFullYear() + ", " + new Date(item.from_city_year).toLocaleString('en-us', {weekday:'short'})}</p>
                            <p className='share-ttl-1'>{"Arrival: " + item.to_city_time + ", " + flightDate(item.to_city_date) + ", " + new Date(item.to_city_year).getFullYear() + ", " + new Date(item.to_city_year).toLocaleString('en-us', {weekday:'short'})}</p>
                            <Box className='d-flex'>
                                <p className='share-ttl-1'>Faretype - {item.fare_type}  </p>
                              </Box>
                              <Box className='d-flex'>
                                <p className='share-ttl-1'>Duration - {item.total_duration} {item.total_stops > 0 ? `(${item.total_stops} ${item.total_stops > 1 ? "stops" : "stop"})` : null} </p>
                              </Box>
                            {sharedFlightList.length > 0 && (
                              <Box className='d-flex'>
                                <p className='share-ttl-1'>Price - Rs.  </p>
                                <TextField 
                                  className='share-price-input' 
                                  type="text" 
                                  variant="standard" 
                                  size="small" 
                                  placeholder="Enter Price Here"
                                  value={sharedFlightList[index].price || ""}
                                  onChange={(e)=>handleInputChange(e, index, "price")}
                                />
                              </Box>
                            )}

                              <TextField 
                                className='share-text-input mb-3' 
                                type="text" 
                                variant="standard" 
                                size="small" 
                                placeholder="Type Your Text Here"
                                value={sharedFlightList[index].remarks || ""}
                                onChange={(e)=>handleInputChange(e, index, "remarks")}
                                ></TextField>
                          </Col>
                        </Row>
                      </>
                      )}
                      <Col xs={12}>
                      <TextField 
                            className='share-outline-input mt-3' 
                            type="text" 
                            variant="outlined" 
                            size="small" 
                            placeholder="Type mobile number"
                            onChange={(e)=>handleSharedMobileNum(e)}
                            name="sharedMobileNum"
                            value={sharedMobileNum}
                            error={sharedMobileNumError}
                            helperText={sharedMobileNumError}
                            />
                      </Col>
                      <Col xs={6}>
                        <Button onClick={()=> onShareByWhatsApp()} className='share-btn'>Share</Button>
                      </Col>
                      <Col xs={6}>
                        <CopyToClipboard text={`${sharedFlightList.map(item => {
                          return (
                            `${item.flightName} (${item.flightCode + "-" + item.flightNumber}): Onward\n${item.from_city + " - " + item.to_city}${(item.total_stops > 0 && item.segment_details.length > 0) ? " Via (" + item.segment_details.slice(0, -1).map(x => x.DestinationDetails.CityName).join(", ") + ")" : ""}\nDeparture: (${item.from_city_time + ", " + flightDate(item.from_city_date) + ", " + new Date(item.from_city_year).getFullYear() + ", " + new Date(item.from_city_year).toLocaleString('en-us', {weekday:'short'}) + "\nArrival: " + item.to_city_time + ", " + flightDate(item.to_city_date) + ", " + new Date(item.to_city_year).getFullYear() + ", " + new Date(item.to_city_year).toLocaleString('en-us', {weekday:'short'})}\nDuration: ${item.total_duration}${item.total_stops > 0 ? `(${item.total_stops} ${item.total_stops > 1 ? "stops" : "stop"})` : ""}\nFare type: ${item.fare_type}\n${item.price !== 0 ? `Price: ${item.price}` : ""}\n${item.remarks !== null ? `Remarks: ${item.remarks}` : ''}\n\n`
                          )
                        }).join('')}Note :  All airfares are subject to availability at time of booking and are not guaranteed until purchased and the e-tickets have been issued.`}
                          onCopy={() => console.log("Coppied")}>
                          <Button className='share-btn'>Copy</Button>
                        </CopyToClipboard>
                      </Col>
                    </Row>
                  </Box>
                )}

            {sharedFlightList.length > 0 &&(
              <Box className='share-container'> 
                <Box className='reset-btn'>
                  <Button variant="text" onClick={()=> {setSharedFlightList([]); setSelectedFlight([])}}>Reset All</Button>
                </Box>
                <Row className='mb-0'>
                  {sharedFlightList.length > 0 && sharedFlightList.map((item, index) => 
                  <>
                    <Col xs={12}>
                      <Button onClick={()=>{onToggleSelectedFlight(item.provabAuthKey)}} className='share-cross-btn'><CloseIcon /></Button>
                    </Col>
                    <Row className='share-row-wrapper'>
                     
                    
                      {/* <Col xs={3} className="share-img-wrapper">
                        <Box className={'itwgo-60x35_' + item.flightCode}></Box>
                      </Col> */}
                      {item.flightDetails.map((x,i)=>
                      <>
                         <Col xs={3} className="share-img-wrapper">
                         <Box className={'itwgo-60x35_' + x.flightCode}></Box>
                       </Col>
                       <Col  xs={7}>
                       <p className='share-ttl-1'>{x.flightName + " (" + x.flightCode + "-" + x.flightNumber + ")"} </p>
                       <p className='share-ttl-1'>{item.originDetails[i].from_city + " - " + item.destinationDetails[i].to_city }</p>
                       <p className='share-ttl-1'>{(x.total_stops > 0 && item.segmentDetails.length > 0) ? "Via (" + item.segmentDetails[i].segment_details.slice(0, -1).map(y=> y.DestinationDetails.CityName).join(", ") + ")" : null}  - { i == 0 ? 'Onward' : 'Return'}</p>
                     </Col>
                     <Col xs={12}>
                        
                        <p className='share-ttl-1'>{"Departure: " + item.from_city_time + ", " + flightDate(item.from_city_date) + ", " + new Date(item.from_city_year).getFullYear() + ", " + new Date(item.from_city_year).toLocaleString('en-us', {weekday:'short'})}</p>
                        <p className='share-ttl-1'>{"Arrival: " + item.to_city_time + ", " + flightDate(item.to_city_date) + ", " + new Date(item.to_city_year).getFullYear() + ", " + new Date(item.to_city_year).toLocaleString('en-us', {weekday:'short'})}</p>
                        <Box className='d-flex'>
                        <p className='share-ttl-1'>Duration - {x.total_duration} {x.total_stops > 0 ? `(${x.total_stops} ${x.total_stops > 1 ? "stops" : "stop"})` : null} </p>
                      </Box>
                      </Col>
                     </>
                        )}
                      {/* <Col  xs={7}>
                        <p className='share-ttl-1'>{item.flightName + " (" + item.flightCode + "-" + item.flightNumber + ")"}</p>
                        <p className='share-ttl-1'>{item.from_city + " - " + item.to_city }</p>
                        <p className='share-ttl-1'>{(item.total_stops > 0 && item.segment_details.length > 0) ? "Via (" + item.segment_details.slice(0, -1).map(x=> x.DestinationDetails.CityName).join(", ") + ")" : null}</p>
                      </Col> */}
                      
                      <Col xs={12}>
                        
                        {/* <p className='share-ttl-1'>{"(" + item.from_city_time + ", " + flightDate(item.from_city_date) + ", " + new Date(item.from_city_year).getFullYear() + " - " + item.to_city_time + ", " + flightDate(item.to_city_date) + ", " + new Date(item.to_city_year).getFullYear() + ")"}</p>
                        <Box className='d-flex'>
                            <p className='share-ttl-1'>Duration - {item.total_duration} {item.total_stops > 0 ? `(${item.total_stops} ${item.total_stops > 1 ? "stops" : "stop"})` : null} </p>
                          </Box> */}
                        <Box className='d-flex'>
                            <p className='share-ttl-1'>Faretype - {item.fare_type}  </p>
                          </Box>
                        {sharedFlightList.length > 0 && (
                          <Box className='d-flex'>
                            <p className='share-ttl-1'>Price - Rs.  </p>
                            <TextField 
                              className='share-price-input' 
                              type="text" 
                              variant="standard" 
                              size="small" 
                              placeholder="Enter Price Here"
                              value={sharedFlightList[index].price || ""}
                              onChange={(e)=>handleInputChange(e, index, "price")}
                            />
                          </Box>
                        )}

                          <TextField 
                            className='share-text-input mb-3' 
                            type="text" 
                            variant="standard" 
                            size="small" 
                            placeholder="Type Your Text Here"
                            value={sharedFlightList[index].remarks || ""}
                            onChange={(e)=>handleInputChange(e, index, "remarks")}
                            ></TextField>
                      </Col>
                    </Row>
                  </>
                  )}
                  <Col xs={12}>
                  {addMarkup && (
                    <Box className='d-flex mb-2'>
                      <p className='share-ttl-1'>Add Markup - Rs.  </p>
                      <TextField 
                        className='share-price-input' 
                        type="number" 
                        variant="standard" 
                        size="small" 
                        inputProps={{ min: 0}}
                        placeholder="Enter Markup"
                        value={addMarkupValue}
                        onChange={(e)=>setAddMarkupValue(e.target.value)}
                      />
                    </Box>
                  )}
                  </Col>
                  <Col xs={12}>
                   <TextField 
                        className='share-outline-input mt-3' 
                        type="text" 
                        variant="outlined" 
                        size="small" 
                        placeholder="Type mobile number"
                        onChange={(e)=>handleSharedMobileNum(e)}
                        name="sharedMobileNum"
                        value={sharedMobileNum}
                        error={sharedMobileNumError}
                        helperText={sharedMobileNumError}
                        />
                  </Col>
                  <Col md={3} xs={6} className="pr-0">
                    <Button onClick={()=> onShareByWhatsApp()} className='share-btn btn-green'>Share</Button>
                  </Col>
                  <Col md={3} xs={5} className="pr-0">
                    <CopyToClipboard text={`${sharedFlightList.map(item => {
                      return (
                        `${item.flightDetails.map((x,i)=>
                          {return(`${x.flightName} (${x.flightCode + "-" + x.flightNumber}): ${i ==0 ? 'Onward' : 'Return'}\n${item.originDetails[i].from_city + " - " + item.destinationDetails[i].to_city}${(x.total_stops > 0 && item.segmentDetails.length > 0) ? " Via (" + item.segmentDetails[i].segment_details.slice(0, -1).map(x => x.DestinationDetails.CityName).join(", ") + ")" : ""}\nDeparture: (${item.originDetails[i].from_city_time + ", " + flightDate(item.originDetails[i].from_city_date) + " " + new Date(item.originDetails[i].from_city_year).getFullYear() + ", " + new Date(item.originDetails[i].from_city_year).toLocaleString('en-us', {weekday:'short'}) + "\nArrival:  " + item.destinationDetails[i].to_city_time + ", "+ flightDate(item.destinationDetails[i].to_city_date) + " " + new Date(item.destinationDetails[i].to_city_year).getFullYear() + ", " + new Date(item.destinationDetails[i].to_city_year).toLocaleString('en-us', {weekday:'short'})}\nDuration: ${x.total_duration}${x.total_stops > 0 ? `(${x.total_stops} ${x.total_stops > 1 ? "stops" : "stop"})\n` : ""}`)}).join('\n\n')}\n\nFare type: ${item.fare_type}\n${item.price !== 0 ? `Price: INR ${item.price + (addMarkupValue === null ? '' : parseInt(addMarkupValue))}` : ""}\n${item.remarks !== null ? `Remarks: ${item.remarks}` : ''}\n\n`)
                    }).join('')}Note :  All airfares are subject to availability at time of booking and are not guaranteed until purchased and the e-tickets have been issued.`}
                      onCopy={() => console.log("Coppied")}>
                      <Button className='share-btn'>Copy</Button>
                    </CopyToClipboard>
                  </Col>
                  <Col md={3} xs={12} className="pr-0">
                      <Button type="button" className='share-btn mt-3' value="btn_email" onClick={copyTableFormat} >Email</Button>
                  </Col>
                  <Col md={3} xs={12}>
                    <Button type="button" className='share-btn mt-3 white-bg' value="btn_gd" onClick={copyGroupFareData} ><img className='groupfareG' src={groupfareG} /></Button>
                  </Col>
                  <Col md={6} xs={12}>
                    {addMarkup ? <Button type="button" className='share-btn mt-3 btn-danger' value="btn_gd" onClick={(e) => hideMarkup()} > - Markup</Button> 
                    : 
                    <Button type="button" className='share-btn mt-3 btn-green' value="btn_gd" onClick={() => {setAddMarkup(true)}} > + Markup</Button>
                    }
                  </Col>
                  <div id="tableDataRound" className='table-copy'>
                    <table>
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Sector</th>
                          <th>Stop(s)</th>
                          <th>Flight No</th>
                          <th>Airline</th>
                          <th>Dept Time</th>
                          <th>Arrival Time</th>
                          <th>Duration</th>
                          <th>Fare</th>
                          <th>Fare Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sharedFlightList.map(item => {
                          return (
                            <>
                            {item.flightDetails.map((x,i) => {
                              return (
                                <tr>
                                  <td>{ i == 0 ? 'Onward' : 'Return'}</td>
                                  <td>{item.originDetails[i].from_city + " - " + item.destinationDetails[i].to_city} </td>
                                  <td>{x.total_stops > 0 ? `${x.total_stops} ${x.total_stops > 1 ? "stops" : "stop"}` : "Non-Stop"} {(x.total_stops > 0 && item.segmentDetails.length > 0) ? "Via (" + item.segmentDetails[i].segment_details.slice(0, -1).map(y=> y.DestinationDetails.CityName).join(", ") + ")" : null}</td>
                                  <td>{x.flightCode + "-" + x.flightNumber}  </td>
                                  <td>{x.flightName}</td>
                                  <td>{item.originDetails[i].from_city_time + ", " + flightDate(item.originDetails[i].from_city_date) + " " + new Date(item.originDetails[i].from_city_year).getFullYear() + ", " + new Date(item.originDetails[i].from_city_year).toLocaleString('en-us', {weekday:'short'})}</td>
                                  <td>{item.destinationDetails[i].to_city_time + ", " + flightDate(item.destinationDetails[i].to_city_date) + " " + new Date(item.destinationDetails[i].to_city_year).getFullYear() + ", " + new Date(item.destinationDetails[i].to_city_year).toLocaleString('en-us', {weekday:'short'})}</td>
                                  <td>{x.total_duration}</td>
                                  <td>{item.price + ` (For ${fsRoundReqData.child + fsRoundReqData.adult + fsRoundReqData.infant} Traveller(s))`}</td>
                                  <td>{item.fare_type}</td>
                                </tr>
                                )
                              })
                            }
                            </>
                            )
                          })}
                      </tbody>
                    </table>
                    <p><b>Note: </b>All airfares are subject to availability at time of booking and are not guaranteed 
                    until purchased and the e-tickets have been issued. </p>
                  </div>

                  <div id="groupFareData" className='table-copy'>
                    <p style={{margin: '2px 0px'}}>Good Day Team,</p><br></br>
                    <p style={{margin: '2px 0px'}}>Please quote rates for the below group,</p>
                    <br></br>
                    <table>
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Sector</th>
                          <th>Stop(s)</th>
                          <th>Flight No</th>
                          <th>Airline</th>
                          <th>Dept Time</th>
                          <th>Arrival Time</th>
                          <th>Duration</th>
                          <th>No. of Pax</th>
                          <th>Expected Fare</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sharedFlightList.map(item => {
                          return (
                            <>
                            {item.flightDetails.map((x,i) => {
                              return (
                                <tr>
                                  <td>{ i == 0 ? 'Onward' : 'Return'}</td>
                                  <td>{item.originDetails[i].from_city + " - " + item.destinationDetails[i].to_city} </td>
                                  <td>{x.total_stops > 0 ? `${x.total_stops} ${x.total_stops > 1 ? "stops" : "stop"}` : "Non-Stop"} {(x.total_stops > 0 && item.segmentDetails.length > 0) ? "Via (" + item.segmentDetails[i].segment_details.slice(0, -1).map(y=> y.DestinationDetails.CityName).join(", ") + ")" : null}</td>
                                  <td>{x.flightCode + "-" + x.flightNumber}  </td>
                                  <td>{x.flightName}</td>
                                  <td>{item.originDetails[i].from_city_time + ", " + flightDate(item.originDetails[i].from_city_date) + " " + new Date(item.originDetails[i].from_city_year).getFullYear() + ", " + new Date(item.originDetails[i].from_city_year).toLocaleString('en-us', {weekday:'short'})}</td>
                                  <td>{item.destinationDetails[i].to_city_time + ", " + flightDate(item.destinationDetails[i].to_city_date) + " " + new Date(item.destinationDetails[i].to_city_year).getFullYear() + ", " + new Date(item.destinationDetails[i].to_city_year).toLocaleString('en-us', {weekday:'short'})}</td>
                                  <td>{x.total_duration}</td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                )
                              })
                            }
                            </>
                            )
                          })}
                      </tbody>
                    </table>
                    <br></br>
                    <p style={{margin: '2px 0px'}}>Regards,</p>
                    <p style={{margin: '2px 0px'}}>Team ITW-Groupfares</p>
                    <p style={{margin: '2px 0px'}}>080 41277577</p>
                  </div>
                </Row>
              </Box>
              )}
            </Container>
            <div>
              <div className='filter-btn-float'>
                <Row>
                  <Col xs={6}>
                    <Button onClick={toggleFilter}><FilterAltIcon /> Apply Filter</Button>
                  </Col>
                  <Col xs={6}>
                    <Button onClick={toggleSortBy}><SortIcon /> Sort By</Button>
                  </Col>
                </Row>
              </div>
            </div>
        </>
  )
}

export default RoundTripInternationalSearchResults
