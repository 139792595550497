import {
  AIRLINES_UNI, FS_ERROR, FS_LOADING,
  FS_NULL, FS_REQUEST, FS_SUCCESS,
  MIN_MAX, FS_MULTI_SUCCESS, FS_ROUND_SUCCESS, FS_MULTI_REQ, FS_ROUND_REQ, FS_ROUND_LOADING, FS_MULTI_LOADING, FS_INV_SUCCESS, FS_ROUND_ERROR, FS_MULTI_ERROR, FS_ROUND_NULL, FS_MULTI_NULL, FS_ONEWAY_SUCCESS, FS_ONEWAY_COUNT, FS_ROUND_COUNT, FS_ROUND_COMPLETE_DATA, FS_ROUND_INTERNATIONAL_ERROR, FS_ROUND_INTERNATIONAL_DATA, FS_ROUND_INTERNATIONAL_COUNT, FS_ROUND_INTERNATIONAL_LOADING
} from '../actions/flightSearchActions'

const initialFSState = {
  isLoading: false,
  isRoundLoading: false,
  isMultiLoading: false,
  fsRequestData: null,
  fsRoundReqData: null,
  fsMultiReqData: null,
  fsData: null,
  fsInvData: null,
  fsRoundData: null,
  fsOnewayMultipleApiData: [],
  fsOneWay6E: null,
  fsOneWay6ECorp: null,
  fsOneWayGDS: null,
  fsOneWayTBO: null,
  fsRoundtripMultipleApiData: [],
  fsMultiData: null,
  error: null,
  roundError: null,
  multiError: null,
  fsOnewayCount: 0,
  fsRoundCount: 0,
  fsRoundCompleteData: null,
  fsRoundInternationalCount: null,
  fsRoundInternationalData: null,
  fsRoundInternationalError: false,
  fsRoundInternationalLoading: false
}

const iniMinMacState = {
  minValue: 0,
  maxValue: 0,
  airlines: null
}

const flightSearchReducers = (state = initialFSState, { type, payload, index }) => {
  switch (type) {
    case FS_LOADING:
      return {
        ...state,
        isLoading: true
      }

    case FS_ROUND_LOADING:
      return {
        ...state,
        isRoundLoading: true
      }
    case FS_MULTI_LOADING:
      return {
        ...state,
        isMultiLoading: true
      }

    case FS_REQUEST:
      return {
        ...state,
        fsRequestData: payload
      }

    case FS_ROUND_REQ:
      return {
        ...state,
        fsRoundReqData: payload
      }

    case FS_MULTI_REQ:
      return {
        ...state,
        fsMultiReqData: payload
      }

    case FS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        fsData: payload
      }
    case FS_ONEWAY_COUNT:
      return {
        ...state,
        isLoading: false,
        fsOnewayCount: payload
      }

    case FS_ONEWAY_SUCCESS:

      if (index === 0) {
        return {
          ...state,
          isLoading: false,
          fsOneWayTBO: payload
        }
      } else if (index === 1) {
        return {
          ...state,
          isLoading: false,
          fsOneWayGDS: payload
        }
      } else if (index === 2) {
        return {
          ...state,
          isLoading: false,
          fsOneWay6E: payload
        }
      } else {
        return {
          ...state,
          isLoading: false,
          fsOneWay6ECorp: payload
        }
      }

    case FS_INV_SUCCESS:
      return {
        ...state,
        // isLoading:false,
        fsInvData: payload
      }

    case FS_MULTI_SUCCESS:
      return {
        ...state,
        isMultiLoading: false,
        fsMultiData: payload
      }
    case FS_ROUND_SUCCESS:
      return {
        ...state,
        isRoundLoading: false,
        fsRoundData: payload
      }
    case FS_ERROR:
      return {
        ...state,
        isLoading: false,
        fsData: null,
        error: payload
      }
    case FS_ROUND_ERROR:
      return {
        ...state,
        isRoundLoading: false,
        // fsRoundData:state.fsRoundData,
        roundError: payload
      }

    case FS_MULTI_ERROR:
      return {
        ...state,
        isMultiLoading: false,
        fsMultiData: null,
        multiError: payload
      }

    case FS_NULL:
      return {
        ...state,
        fsData: null,
        fsOneWay6E: null,
        fsOneWay6ECorp: null,
        fsOneWayGDS: null,
        fsOneWayTBO: null,
        error: ''
      }
    case FS_ROUND_NULL:
      return {
        ...state,
        fsRoundData: null,
        roundError: '',
        fsRoundCompleteData: null,
        fsRoundCount: 0
      }
    case FS_MULTI_NULL:
      return {
        ...state,
        fsMultiData: null,
        multiError: ''
      }
    case FS_ROUND_COUNT:
      return {
        ...state,
        fsRoundCount: payload
      }
    case FS_ROUND_COMPLETE_DATA:
      return {
        ...state,
        fsRoundCompleteData: payload
      }
    case FS_ROUND_INTERNATIONAL_ERROR:
      return {
        ...state,
        fsRoundInternationalError: payload,
        fsRoundInternationalLoading: false
      }
    case FS_ROUND_INTERNATIONAL_DATA:
      return {
        ...state,
        fsRoundInternationalData: payload,
        fsRoundInternationalLoading: false
      }
    case FS_ROUND_INTERNATIONAL_COUNT:
      return {
        ...state,
        fsRoundInternationalCount: payload
      }
    case FS_ROUND_INTERNATIONAL_LOADING:
      return {
        ...state,
        fsRoundInternationalLoading: true
      }
    default:
      return state
  }
}

export default flightSearchReducers

export const minMaxReducers = (state = iniMinMacState, { type, payload }) => {
  switch (type) {
    case MIN_MAX:
      return {
        ...state,
        minValue: payload[0],
        maxValue: payload[1]
      }
    case AIRLINES_UNI:
      return {
        ...state,
        airlines: payload
      }
    default:
      return state
  }
}
