import { Button } from '@mui/material'
import React from 'react'
import { Col , Row} from 'react-bootstrap'
import imgnodata from '../../../assets/images/filter.png'
import { makeStyles } from '@mui/styles';
import './flights.css'


export default function NotFindData (props) {
  return (
        <div className='filtererrwrp' >
          <Row>
            <Col xs={3}  md={2} >
              <div className='notfoundddata'>
                <div className='imgfnd'><img src={imgnodata} className='imgdata'/></div>
              </div>
            </Col>
            <Col xs={9}  md={10} className="m-auto">
              <div className='errfiltertxt'>
                <div className='para'>We couldn't find flights to match your filters</div>
                <div className='minipara'>Please reset your filters to see flights</div>
                <Button className='btnresetfilter' variant="contained" color='secondary' onClick = {() => props.handleReset()}>Reset Filter</Button>
              </div>
            </Col>
          </Row>
           
        </div>
  )
}
