import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import { Col, Row } from 'react-bootstrap'
import { Box } from '@mui/material'
import '../skeleton/skeleton.css'
const OnewaySearchSkelton = () => {
  return (
      <div>
          <Box className="flightsearchskeletonview">
              <Row>
                  <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                      <Box><Skeleton variant="rectangular" animation="wave" width={120} height={35} /></Box>
                      <Box className="btnsktn"><Skeleton variant="rectangular" height={30} /></Box>
                  </Col>

                  <Col xs={5} sm={5} md={5} lg={5} xl={5} >
                      <Box><Skeleton variant="rectangular" animation="wave" height={75} /></Box>
                  </Col>
                  <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                      <Box className="pricelistsktn"><Skeleton variant="rectangular" animation="wave" height={20} /></Box>
                      <Box className="pricelistsktn"><Skeleton variant="rectangular" animation="wave" height={20} /></Box>
                      <Box className="pricelistsktn"><Skeleton variant="rectangular" animation="wave" height={20} /></Box>
                  </Col>
                  <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                      <Box><Skeleton variant="rectangular" animation="wave" height={35} /></Box>
                      <Box className="btnsktn"><Skeleton variant="rectangular" height={30} /></Box>
                  </Col>
              </Row>
          </Box>
      </div>
  );
}

export default OnewaySearchSkelton
