import { authPost } from '../../../utility/apiConfig'
import { API_BASE_URL } from '../../../utility/apiConstants'

export const PROFILE_ERROR = 'PROFILE_ERROR'
export const PROFILE_LOADER = 'PROFILE_LOADER'
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS'

export const profileLoader = () => {
  return {
    type: PROFILE_LOADER
  }
}

export const profileError = (error) => {
  return {
    type: PROFILE_ERROR,
    payload: error
  }
}

export const profileSuccess = (data) => {
  return {
    type: PROFILE_SUCCESS,
    payload: data
  }
}

export const profileUpdate = (data, history) => {
  return (dispatch) => {
    try {
      dispatch(profileLoader())

      authPost(API_BASE_URL + 'user/update_account', data)
        .then((response) => {
          if (response.data.status === true) {
            dispatch(profileSuccess(response.data.message))

            history.push('/profile')

            // dispatch(getUserAccountDetails())
          } else {
            dispatch(profileError(response.data.message))
          }
        })
        .catch((error) => {
          if (error === 'Error: Network Error') {
            dispatch(profileError('Network error please try again'))
          } else {
            dispatch(profileError('something went wrong.'))
          }
        })
    } catch (error) {

    }
  }
}
