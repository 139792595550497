import { post } from '../../../utility/apiConfig'
import { API_BASE_URL } from '../../../utility/apiConstants'

export const FORGOT_LOADER = 'FORGOT_LOADER'
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS'
export const FORGOT_ERROR = 'FORGOT_ERROR'
export const FORGOT_STATUS = 'FORGOT_STATUS'

export const forgotLoader = () => {
  return {
    type: FORGOT_LOADER
  }
}

export const forgotStatus = (data) => {
  return {
    type: FORGOT_STATUS,
    payload: data
  }
}

export const forgotSuccess = (data) => {
  return {
    type: FORGOT_SUCCESS,
    payload: data
  }
}

export const forgotError = (error) => {
  return {
    type: FORGOT_ERROR,
    payload: error
  }
}

export const forgotRequest = (datas) => {
  return (dispatch) => {
    try {
      dispatch(forgotLoader())
      post(API_BASE_URL + 'auth/forgot_password/', datas)
        .then((response) => {
          if (response.data.status === true) {
            dispatch(forgotSuccess(response.data.data))
          } else {
            dispatch(forgotError(response.data.data))
          }
        })
        .catch((error) => {
          if (error === 'Error: Network Error') {
            dispatch(forgotError('Network error please try again'))
          } else {
            dispatch(forgotError('something went wrong.'))
          }
        })
    } catch (error) {

    }
  }
}
