import { LOADER, LOGIN, ERROR } from '../actions/loginActions'

const initialLoginState = {
  isLoading: false,
  loginData: null,
  error: ''
}

const loginReducers = (state = initialLoginState, { type, payload }) => {
  
  switch (type) {
    case LOADER:
      return {
        ...state,
        isLoading: true
      }

    case LOGIN:
      return {
        ...state,
        isLoading: false,
        loginData: payload,
        error: ''
      }

    case ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload
      }
    default:
      return state
  }
}

export default loginReducers
