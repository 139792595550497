import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import { Col, Row } from 'react-bootstrap'
import { Box } from '@mui/material'
import '../skeleton/skeleton.css'
const RoundTripSearchSkelton = () => {
  return (
      <div className="roundtripskeleton">
          <Box className="fltsrchrnddtripskeleton">
              <Row>
                  <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                      <Box><Skeleton variant="rectangular" animation="wave" width={61} height={28} /></Box>
                      <Box className="btnsktn"><Skeleton variant="rectangular" height={30} /></Box>
                  </Col>

                  <Col xs={6} sm={6} md={6} lg={6} xl={6} >
                      <Box><Skeleton variant="rectangular" animation="wave" height={75} /></Box>
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                      <Box className="pricelistsktn"><Skeleton variant="rectangular" animation="wave" height={20} /></Box>
                      <Box className="pricelistsktn"><Skeleton variant="rectangular" animation="wave" height={20} /></Box>
                      <Box className="pricelistsktn"><Skeleton variant="rectangular" animation="wave" height={20} /></Box>
                  </Col>
              </Row>
          </Box>

          <Box className="fltsrchrnddtripskeleton">
              <Row>
                  <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                      <Box><Skeleton variant="rectangular" animation="wave" width={61} height={28} /></Box>
                      <Box className="btnsktn"><Skeleton variant="rectangular" height={30} /></Box>
                  </Col>

                  <Col xs={6} sm={6} md={6} lg={6} xl={6} >
                      <Box><Skeleton variant="rectangular" animation="wave" height={75} /></Box>
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                      <Box className="pricelistsktn"><Skeleton variant="rectangular" animation="wave" height={20} /></Box>
                      <Box className="pricelistsktn"><Skeleton variant="rectangular" animation="wave" height={20} /></Box>
                      <Box className="pricelistsktn"><Skeleton variant="rectangular" animation="wave" height={20} /></Box>
                  </Col>
              </Row>
          </Box>
      </div>
  );
}

export default RoundTripSearchSkelton
