import { Box, Button, Snackbar, SnackbarContent, Container } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Whitearrow from '../../../assets/images/whitearrow.svg'
import { useNavigate } from 'react-router-dom'
import '../../../assets/airlines/airlineLogoSprites.css'
import { flightDate } from '../../../core/helper'
import { stopsCount } from '../../../core/airlineStops'
import { useDispatch, useSelector } from 'react-redux'
import { ssrRequest } from '../../../store/flights/actions/flightBookingSSRActions'

const RoundTripBook = (props) => {
    const { onward, returns } = props
    const { fsRoundData, fsRoundReqData, fsRoundCompleteData } = useSelector(state => state.flightResult)
    const [errorMsg, setErrorMsg] = useState(false)
    const dispatch = useDispatch()
    const history = useNavigate()

    const roundTripBooking = () => {
        const date1 = new Date(onward && onward.SegmentSummary &&  onward.SegmentSummary[0] && onward.SegmentSummary[0].DestinationDetails.DateTime)
        const date2 = new Date(returns && returns.SegmentSummary && returns.SegmentSummary[0] && returns.SegmentSummary[0].OriginDetails.DateTime)
        
        let hours = false;
        if (date1.getDate() === date2.getDate())
        {
            const seconds = Math.round((date2.getTime() - date1.getTime()) / 1000)
            hours = Math.round(seconds / 60 / 60)
        }
        

        if (hours && hours < 4) {
            setErrorMsg(true)
        } else {
            const obj = {
                is_domestic: fsRoundCompleteData.data.IsDomestic,
                token: [onward.Token, returns.Token],
                token_key: [onward.TokenKey, returns.TokenKey],
                search_access_key: [onward.ProvabAuthKey, returns.ProvabAuthKey],
                promotional_plan_type: [''],
                booking_source: [onward.booking_source, returns.booking_source],
                alternate_flights: '',
                search_hash: fsRoundCompleteData.session_expiry_details.search_hash,
                iti_origin: [onward && onward.iti_origin, returns && returns.iti_origin],
            }

            dispatch(ssrRequest(obj))

            const data = {
                adult: fsRoundReqData.adult,
                child: fsRoundReqData.child,
                infant: fsRoundReqData.infant,
                isDomestic: fsRoundCompleteData.data.IsDomestic,
                domestic_round_way_flight: fsRoundCompleteData.data.domestic_round_way_flight
            }

        //    history.push({
        //         pathname: '/flight/booking',
        //         state: [obj, fsRoundCompleteData.data.search_id, onward, fsRoundCompleteData.data.cabin_class, data, returns]
        //     })
        props.setModalFlight(false);
        props.setOnewayFlight(null);
        props.setOnwardFlight(onward);
        props.setReturnFlight(returns);
        props.setIsInt(false);
        }
    }

    const handleCloseMsg = () => {
        setErrorMsg(false)
    }


    const fetchTotalAmount = () => {
        let total = 0;
        if (onward)
        {   
            total += onward.FareDetails.b2b_PriceDetails._CustomerBuying
        }

        if(returns)
        {
            total += returns.FareDetails.b2b_PriceDetails._CustomerBuying
        }
        
        return total
    }


    useEffect(() => {
    }, [props])

    return (

        <>

            <Snackbar

                autoHideDuration={5000}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                open={errorMsg}
                onClose={handleCloseMsg}
                message="The onward and return flights are overlapping with each other. Please change one of these selections"
                key={{
                    vertical: 'top',
                    horizontal: 'center'
                }}

            >

                <SnackbarContent style={{
                    backgroundColor: 'orange'
                }}
                    message={<span id="client-snackbar">The onward and return flights are overlapping with each other. Please change one of these selections</span>}
                />

            </Snackbar>
            <Container className='sticky-parent'>
                <Box className="bookingflightroundtrip mt-3">
                <Container>
                    <Row>
                        {onward ? 
                        
                        <Col xs={6} sm={6} md={5}  className="align-self-center px-xs-0">
                            <Box className="flight_details_oneway">
                                <Row>
                                    <Col xs={9} sm={9} md={9} lg={4} xl={4} className="">
                                        <Box className="imgfooterbooking">
                                            <Box className="footerbookingimgminifly">
                                                <div className={'flagflt itwgo-60x35_' + onward.SegmentSummary[0].AirlineDetails.AirlineCode}></div>
                                            </Box>
                                            <Box className="footerbookingmini-tag">
                                                <Box className="footerbookingflightname">{onward.SegmentSummary[0].AirlineDetails.AirlineName}</Box>
                                                <Box className="footerbookingflightnumber">{onward.SegmentSummary[0].AirlineDetails.AirlineCode}-{onward.SegmentSummary[0].AirlineDetails.FlightNumber}</Box>
                                            </Box>
                                        </Box>
                                    </Col>
                                    <Col xs={5} sm={5} md={5} lg={5} xl={5} className="footerflightdetailgap flight-details-mob">
                                        <Box className="flexset-footerbooking-inside">
                                            <Box className="definefooterroundtripwidth">
                                                <Box className="footerflightmininame">{onward.SegmentSummary[0].OriginDetails.AirportCode}</Box>
                                                <Box className="footerflightminitime">{onward.SegmentSummary[0].OriginDetails._DateTime}</Box>
                                                <Box className="footerflightminidata">{flightDate(onward.SegmentSummary[0].OriginDetails._Date)}</Box>
                                            </Box>
                                            <Box className="footerroundarrowbox">
                                                <Box className="footerdefine-stop">{stopsCount[onward.SegmentSummary[0].TotalStops]}</Box>
                                                <Box className="footerroundarrow-img"><img src={Whitearrow}></img></Box>
                                                <Box className="footerdefine-timecalc">{onward.SegmentSummary[0].TotalDuaration}</Box>
                                            </Box>
                                            <Box className="footerdefineroundtripwidth">
                                                <Box className="footerflightmininame">{onward.SegmentSummary[0].DestinationDetails.AirportCode}</Box>
                                                <Box className="footerflightminitime">{onward.SegmentSummary[0].DestinationDetails._DateTime}</Box>
                                                <Box className="footerflightminidata">{flightDate(onward.SegmentSummary[0].DestinationDetails._Date)}</Box>
                                            </Box>
                                        </Box>
                                    </Col>
                                    <Col xs={3} sm={3} md={3} lg={3} xl={3} className="footerflightdetailgap">
                                        <Box className="price">
                                            <span>&#x20B9; </span> <span>{Math.round(onward.FareDetails.b2b_PriceDetails._CustomerBuying)}</span>
                                            {/* <div className="itemssecttag-book">{onward.Attr.Faretype}</div> */}
                                        </Box>
                                    </Col>
                                </Row>
                            </Box>
                        </Col>

                        : null
                        }


                        {
                            returns ? 
                            <Col xs={6} sm={6} md={5} className="align-self-center px-xs-0">
                            <Box className="flight_details_round">
                                <Row>
                                    <Col xs={9} sm={9} md={9} lg={4} xl={4} className="">
                                        <Box className="imgfooterbooking">
                                            <Box className="footerbookingimgminifly">
                                                <div className={'flagflt itwgo-60x35_' + returns.SegmentSummary[0].AirlineDetails.AirlineCode}></div>
                                            </Box>
                                            <Box className="footerbookingmini-tag">
                                                <Box className="footerbookingflightname">{returns.SegmentSummary[0].AirlineDetails.AirlineName}</Box>
                                                <Box className="footerbookingflightnumber">{returns.SegmentSummary[0].AirlineDetails.AirlineCode}-{returns.SegmentSummary[0].AirlineDetails.FlightNumber}</Box>
                                            </Box>
                                        </Box>
                                    </Col>
                                    <Col xs={5} sm={5} md={5} lg={5} xl={5} className="footerflightdetailgap flight-details-mob">
                                        <Box className="flexset-footerbooking-inside">
                                            <Box className="definefooterroundtripwidth">
                                                <Box className="footerflightmininame">{returns.SegmentSummary[0].OriginDetails.AirportCode}</Box>
                                                <Box className="footerflightminitime">{returns.SegmentSummary[0].OriginDetails._DateTime}</Box>
                                                <Box className="footerflightminidata">{flightDate(returns.SegmentSummary[0].OriginDetails._Date)}</Box>
                                            </Box>
                                            <Box className="footerroundarrowbox">
                                                <Box className="footerdefine-stop">{stopsCount[returns.SegmentSummary[0].TotalStops]}</Box>
                                                <Box className="footerroundarrow-img"><img src={Whitearrow}></img></Box>
                                                <Box className="footerdefine-timecalc">{returns.SegmentSummary[0].TotalDuaration}</Box>
                                            </Box>
                                            <Box className="footerdefineroundtripwidth">
                                                <Box className="footerflightmininame">{returns.SegmentSummary[0].DestinationDetails.AirportCode}</Box>
                                                <Box className="footerflightminitime">{returns.SegmentSummary[0].DestinationDetails._DateTime}</Box>
                                                <Box className="footerflightminidata">{flightDate(returns.SegmentSummary[0].DestinationDetails._Date)}</Box>
                                            </Box>
                                        </Box>
                                    </Col>
                                    <Col xs={3} sm={3} md={3} lg={3} xl={3} className="footerflightdetailgap">
                                        <Box className="price">
                                            <span>&#x20B9; </span> <span>{Math.round(returns.FareDetails.b2b_PriceDetails._CustomerBuying)}</span>
                                            {/* <div class="itemssecttag-book">{returns.Attr.Faretype}</div> */}
                                        </Box>
                                    </Col>
                                </Row>
                            </Box>
                        </Col>

                        : null 
                        }
                        
                        <Col xs={12} sm={12} md={2} className="align-self-center">
                            <Box className="flight_details_btn ">
                                <Box className='d-flex mb-1'>
                                    <div className="pricetotal">Total</div>
                                    {/* <Box className="price"><span>&#x20B9; </span> <span>{Math.round(onward ? (onward.FareDetails.b2b_PriceDetails._CustomerBuying) : 0 + (returns ? (returns.FareDetails.b2b_PriceDetails._CustomerBuying) : 0))}</span></Box> */}
                                    <Box className="price d-flex"><span>&#x20B9; {fetchTotalAmount()}</span></Box>

                                </Box>
                               
                                <Button className="btn btn-primary mailbtn" variant="contained" color='success'
                                    onClick={roundTripBooking}
                                >Book</Button>

                            </Box>
                        </Col>
                    </Row>
                </Container>
               
            </Box>
            </Container>
        </>
    )
}

export default RoundTripBook
