import { authPost } from '../../../utility/apiConfig'
import { API_BASE_URL } from '../../../utility/apiConstants'

export const CHANGEPASS_LOADER = 'CHANGEPASS_LOADER'
export const CHANGEPASS_SUCCESS = 'CHANGEPASS_SUCCESS'
export const CHANGEPASS_ERROR = 'CHANGEPASS_ERROR'

export const changePassLoader = () => {
  return {
    type: CHANGEPASS_LOADER
  }
}

export const changePassSuccess = (data) => {
  return {
    type: CHANGEPASS_SUCCESS,
    payload: data
  }
}

export const changePassError = (data) => {
  return {
    type: CHANGEPASS_ERROR,
    payload: data
  }
}

export const changePassReq = (data) => {
  return (dispatch) => {
    try {
      dispatch(changePassLoader())
      authPost(API_BASE_URL + '/user/change_password', data)
        .then((res) => {
          if (res.data.status === true) {
            dispatch(changePassSuccess(res.data.message))
          } else {
            dispatch(changePassError(res.data.message))
          }
        })
        .catch((error) => {
          if (error === 'Error: Network Error') {
            dispatch(changePassError('Network error please try again'))
          } else {
            dispatch(changePassError('something went wrong.'))
          }
        })
    } catch (error) {

    }
  }
}
