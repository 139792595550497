import { Box, SvgIcon } from '@mui/material'
import React from 'react'
import { Col, Container, NavLink, Row } from 'react-bootstrap'
import "./footer.css"
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import AddIcCallTwoToneIcon from '@mui/icons-material/AddIcCallTwoTone';
import WhatsappTwoToneIcon from '@mui/icons-material/WhatsApp';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

function Footer() {
    return (
        <div className="footer" id="footer">
            <Container fluid className="footer-bottom">
                <Box >
                    <Typography variant="body2" color="text.secondary" align="center">
                    {'Copyright © 2023 Coke Crew Management. All Rights Reserved '}
                   
                    </Typography>
                </Box>
            </Container>
        </div>
    )
}

export default Footer
