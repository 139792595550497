import { Box, Button } from '@mui/material'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

const Profileheader=(props)=> {
    const {userDetails } = useSelector(state => state.user)
    return (
        <div>
           {userDetails ? (<Container fluid className="profileheader">
                <Row>
                    <Col xs={6} sm={6} md={8} lg={8} xl={8}>
                        <Box className="tltprofilename">Hi, {userDetails.agency_name}<span className="tltnamegray">{' (' + userDetails.first_name + ' ' + userDetails.last_name+')'}</span></Box>
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={4} xl={4}>
                        <Box className="btnedit">
                            <NavLink to={props.link}>
                                <Button className="btnEditProfile" variant="contained">{props.title}</Button>
                            </NavLink>
                        </Box>
                    </Col>
                </Row>
            </Container>):(<span>Loading...</span>)}
        </div>
    );
}

export default Profileheader
