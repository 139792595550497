import React, { useEffect, useState } from 'react'
import './flights.css'
import moment from 'moment'
import FlightSearch from './FlightSearch'
import SearchResults from './SearchResults'
import { useDispatch } from 'react-redux'
import { flightSearchRequest } from '../../../store/flights/actions/flightSearchActions'

const FlightModal = (props) => {
  const dispatch = useDispatch()
  const [destination, setDestination] = useState({});
  const [showModify, setShowModify] = useState(false);
  const [fromAirport, setFromAirport] = useState([]);
  const [fromValue, setFromValue] = useState(null);
  const [fromCountry, setFromCountry] = useState(null);
  const [toAirport, setToAirport] = useState([]);
  const [toValue, setToValue] = useState(null);
  const [toCountry, setToCountry] = useState(null);
  const [depDate, setDepDate] = useState();
  const [retDate, setRetDate] = useState(null);
  const [searchObj, setSearchObj] = useState({});

  useEffect(() => {
    // setDestination(airports.filter(x=> x.airport_city === props.packageDetails.package.package_city.split(",")[0])[0])
    // setDepDate(moment(new Date()).add('days', 5).format('DD-MMM-yyyy'));
    // setRetDate(moment(new Date()).add('days', Number(props?.packageDetails?.package?.duration)+5).format('DD-MMM-yyyy'));
    // setFromAirport([...fromAirport, airports.filter(x=> x.airport_city === "Bangalore")[0]]);
    // setFromValue(airports.filter(x=> x.airport_city === "Bangalore")[0]);
    // setFromCountry(airports.filter(x=> x.airport_city === "Bangalore")[0].country)
    // setToAirport([...toAirport, airports.filter(x=> x.airport_city === props.packageDetails.package.package_city.split(",")[0])[0]]);
    // setToValue(airports.filter(x=> x.airport_city === props.packageDetails.package.package_city.split(",")[0])[0]);
    // setToCountry(airports.filter(x=> x.airport_city === props.packageDetails.package.package_city.split(",")[0])[0]?.country)
    // let search_obj = {
    //   trip_type: 'circle',
    //   from: airports.filter(x=> x.airport_city === "Bangalore")[0]?.airport_city + ` (${airports.filter(x=> x.airport_city === "Bangalore")[0]?.airport_code})`,
    //   from_loc_id: airports.filter(x=> x.airport_city === "Bangalore")[0]?.origin,
    //   to: airports.filter(x=> x.airport_city === props.packageDetails.package.package_city.split(",")[0])[0]?.airport_city + ` (${airports.filter(x=> x.airport_city === props.packageDetails.package.package_city.split(",")[0])[0]?.airport_code})`,
    //   to_loc_id: airports.filter(x=> x.airport_city === props.packageDetails.package.package_city.split(",")[0])[0]?.origin,
    //   depature: moment(new Date()).add('days', 5).format('DD-MMM-yyyy'),
    //   return: moment(new Date()).add('days', Number(props?.packageDetails?.package?.duration)+5).format('DD-MMM-yyyy'),
    //   adult: props.searchObj.adult ? props.searchObj.adult : 1,
    //   child: props.searchObj.child ? props.searchObj.child : 0,
    //   infant: 0,
    //   nonStop: false,
    //   studentFare: false,
    //   search_flight: 'search',
    //   v_class: 'Economy',
    //   carrier: ["UK"],
    //   fromCountry: airports.filter(x=> x.airport_city === "Bangalore")[0].country,
    //   toCountry: airports.filter(x=> x.airport_city === props.packageDetails.package.package_city.split(",")[0])[0]?.country,
    //   specialFare: false,
    //   // booking_source: 'PTBSID0000000008',
    //   fareType: []  
    // }
    // setSearchObj(search_obj);
    // props.setSearchObj(search_obj);
  }, [])

  useEffect(() => {
    dispatch(flightSearchRequest(searchObj));
    props.setSearchObj(searchObj)
  }, [searchObj])

  function dateFormat(date) {
    let formattedDate = moment(date).format('dddd Do MMMM YYYY');
    return formattedDate;
  }

  return (
    <div>
      <FlightSearch
        showModify={showModify}
        setShowModify={setShowModify}
        destination={destination}
        setDestination={setDestination}
        packageDetails={props.packageDetails}
        fromAirport={fromAirport}
        setFromAirport={setFromAirport}
        fromValue={fromValue}
        setFromValue={setFromValue}
        fromCountry={fromCountry}
        setFromCountry={setFromCountry}
        toAirport={toAirport}
        setToAirport={setToAirport}
        toValue={toValue}
        setToValue={setToValue}
        toCountry={toCountry}
        setToCountry={setToCountry}
        depDate={depDate}
        setDepDate={setDepDate}
        retDate={retDate}
        setRetDate={setRetDate}
        searchObj={searchObj}
        setSearchObj={setSearchObj}
      />
      <SearchResults
        searchObj={searchObj}
        setModalFlight={props.setModalFlight}
        setOnewayFlight={props.setOnewayFlight}
        setOnwardFlight={props.setOnwardFlight}
        setReturnFlight={props.setReturnFlight}
        setIsInt={props.setIsInt}
      />


    </div>
  );
}

export default FlightModal
