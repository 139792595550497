import { Skeleton, Box ,Container} from '@mui/material';
import React from 'react'
import { Col, Row } from 'react-bootstrap'

const RoundTripBookSkltn = () => {
  return (
      <Container className='sticky-parent'>
        <Box className="bookingflightroundtrip ">

          <Row className="mt-3">
              <Col xs={6} sm={6} md={4} lg={4} xl={4}>
                  <div>
                      <div className="sktdiv"><Skeleton animation="wave" variant="rectangular" height={20} /></div>
                  </div>
              </Col>
              <Col xs={6} sm={6} md={4} lg={4} xl={4}>
                  <div>
                      <div className="sktdiv"><Skeleton animation="wave" variant="rectangular" height={20} /></div>
                  </div>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div>
                      <div className="sktdiv"><Skeleton animation="wave" variant="rectangular" height={20} /></div>
                  </div>
              </Col>
            
          </Row>
        
      </Box>
      </Container>

  );
}

export default RoundTripBookSkltn

