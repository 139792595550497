import { Box, Button, FormControlLabel, Radio, RadioGroup, Tab, Tabs, Typography, Tooltip, Checkbox } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import arrowimg from '../../../assets/images/arrow.svg'
import luggage from '../../../assets/images/luggage.svg'
import refund from '../../../assets/images/refund.svg'
import nonrefund from '../../../assets/images/nonrefund.svg'
import seat from '../../../assets/images/seat.svg'
import CloseIcon from '@mui/icons-material/Close'
import { makeStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import '../../../assets/airlines/airlineLogoSprites.css'

import PropTypes from 'prop-types'
import { flightDate, formatDate, formatDate1, toDigit } from '../../../core/helper'
import { stopsCount } from '../../../core/airlineStops'
import { useSelector } from 'react-redux'
// import ReactHtmlParser from 'react-html-parser'
import { API_BASE_URL } from '../../../utility/apiConstants'
import { authPost } from '../../../utility/apiConfig'
import { fareRulsDetails } from '../../../services/fareDetails'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import arrowFlight from '../../../assets/images/arrowflight.png';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const Regular = withStyles({
  root: {
    color: '#fc9e15',
    '&$checked': {
      color: '#fc9e15'
    }
  }
})(props => <Radio color="default" {...props} />)

const Tactical = withStyles({
  root: {
    color: '#e91e63',
    '&$checked': {
      color: '#e91e63'
    }
  }
})(props => <Radio color="default" {...props} />)

const SME = withStyles({
  root: {
    color: '#3A62D9',
    '&$checked': {
      color: '#3A62D9'
    }
  }
})(props => <Radio color="default" {...props} />)

const Deal = withStyles({
  root: {
    color: '#ff0000',
    '&$checked': {
      color: '#ff0000'
    }
  }
})(props => <Radio color="default" {...props} />)

const Corporate = withStyles({
  root: {
    color: '#28a745',
    '&$checked': {
      color: '#28a745'
    }
  }
})(props => <Radio color="default" {...props} />)

const Others = withStyles({
  root: {
    color: '#17a2b8',
    '&$checked': {
      color: '#17a2b8'
    }
  }
})(props => <Radio color="default" {...props} />)



function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function viewDetailsToggle(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
}))

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {

  }
}))(Tooltip)

const RoundTripResults = (props) => {
  const result = props.results
  const { onwardSelected, returnSelected, type } = props

  const { fsRoundReqData } = useSelector(state => state.flightResult)
  const [fareDetails, setFareDetails] = useState({})
  const [fareValues, setFareValues] = useState('0')
  const [fareRules, setFareRules] = useState(null)
  const [fareLoader, setFareLoader] = useState(false)

  const [paxCount, setpaxCount] = useState(fsRoundReqData.adult + fsRoundReqData.child)

  const Roundclass = useStyles()
  const [value, setValue] = useState(0)
  const [toggle, setToggle] = useState(false)
  const [toggleMoreFlight, setToggleMoreFlight] = useState(false)

  const segSumry = props.results[0].SegmentSummary[0]
  const segments = props.results[0].SegmentDetails[0]
  const airlines = segments[0].AirlineDetails

  const handleChangeFares = (event) => {
    setFareValues(event.target.value)
    setFareDetails(props.results[event.target.value])
    if(toggle && value === 2)
    {
      getFareRules(props.results[event.target.value])
    }
  }

  const getFareRules = useCallback(
    (data) => {
      setFareLoader(true)
      const obj = {
        data_access_key: data.Token,
        booking_source: data.booking_source,
        search_access_key: data.ProvabAuthKey,
        provab_auth_key: 'undefined'
      }

      try {
        authPost(API_BASE_URL + 'ajax/get_fare_details', obj)
          .then((response) => {
            setFareLoader(false)
            if (response.data.status) {
              const info = response.data.data[0]?.fare_rules
              if (info !== undefined) {
                const iic = info.concat(response.data.data[0]?.cancellation_policy)
                setFareRules(iic)
              } else {
                setFareRules(response.data.data.data[0].FareRules)
              }
            } else {
              setFareRules(response.data.data.msg)
            }
          }).catch((error) => {
            setFareRules("Fare details not available at this moment.")
          })
      } catch (error) {
        setFareLoader(false)
        setFareRules('Somthing went wrong please try again...')
      }
    }
  )

  const handleFlighttabsChange = (event, newValue) => {
    setValue(newValue)

    if (newValue === 2) {
      getFareRules(props.results[0])
    }
  }

  const viewDetailToggle = (item, type) => {
    if(type === "1"){
      setToggle(true)
      setFareDetails(props.results[fareValues])
      setValue(0)
      setToggleMoreFlight(false)
      }
      else if(type === "2"){
      setToggle(true)
      setFareDetails(item)
      setValue(0)
      setToggleMoreFlight(false)
      }
      else{
      setToggle(false)
      setValue(0)
      setToggleMoreFlight(false)
      }
      setToggle(!toggle)
  }
  
  const moreFlightToggle = () => {
    setToggleMoreFlight(!toggleMoreFlight)
    setFareDetails(props.results[fareValues])
    setValue(0)
    setToggle(false)
  }

  const selectFlight = (data, selct, index) => {
    if(index !== undefined && index !== null){
      if (props.type === 'onward') {
        props.onwardSelected(props.results[index], selct)
      } else {
        props.returnSelected(props.results[index], selct)
      }
    }
    else{
    if (props.type === 'onward') {
      props.onwardSelected(props.results[fareValues], selct)
    } else {
      props.returnSelected(props.results[fareValues], selct)
    }
  }
    
  }

  const getSectionSelected = () => {
      let obj = props.results.filter((item)=> {return item.Token===props.onActiveBg})
       if (obj && obj.length > 0)
           return true
       else
           return false
  }

  useEffect(() => {
    setToggle(false)
    setFareValues('0')
  }, [props.results])
  let minDuaration =  Math.min(...props.paginationData.map(x=> x.map(y=> y.SegmentSummary[0].TotalDuaration_min)).flat());
  let minimum = props.paginationData.map(x=> x.find(y=> y.SegmentSummary[0].TotalDuaration_min === minDuaration)).filter(z=> z !== undefined)[0].ProvabAuthKey;
  let maxDuaration =  Math.max(...props.paginationData.map(x=> x.map(y=> y.SegmentSummary[0].TotalDuaration_min)).flat());
  let maximum = props.paginationData.map(x=> x.find(y=> y.SegmentSummary[0].TotalDuaration_min === maxDuaration)).filter(z=> z !== undefined)[0].ProvabAuthKey;
  return (
    <>
      <Box className={[getSectionSelected() ? 'roundflightdetailscard activerndbg' : 'roundflightdetailscard',  "roundtripresult-des"]}>
      {((props.results.map(x=> x.Attr.Faretype).indexOf("Deal") === -1) && 
        (minimum !== undefined && props.results.map(x=> x.ProvabAuthKey).indexOf(minimum) !== -1)) && (
          maximum !== undefined && props.results.map(x=> x.ProvabAuthKey).indexOf(maximum) === -1
        ) && (
         <Box className='info-label-box'>
          <span className='info-label-r shortdistance-info-bg'>Shortest Duration</span>
        </Box>
        )}
        {((props.results.map(x=> x.Attr.Faretype).indexOf("Deal") === -1) &&
          maximum !== undefined && props.results.map(x=> x.ProvabAuthKey).indexOf(maximum) !== -1) && (
            minimum !== undefined && props.results.map(x=> x.ProvabAuthKey).indexOf(minimum) === -1
          ) && (
         <Box className='info-label-box'>
          <span className='info-label-r longdistance-info-bg'>Longest Duration</span>
        </Box>
        )}
      {(props.results.map(x=> x.Attr.Faretype).indexOf("Deal") !== -1) && (
         <Box className='info-label-box'>
          <span className='info-label-r dealavailable-info-bg'>Deal available</span>
        </Box>
        )}
        <Row className="viewroundresultbooking">
          <Col xs={4} sm={4} md={5} lg={5} xl={5}>
            <Box className="roundflightimg">
              <Box className="roundimgminifly">
                <div className={'flagflt itwgo-60x35_' + airlines.AirlineCode}></div>
              </Box>
              <Box className="roundmini-tag">
                <Box className="rndflightname">{segSumry.AirlineDetails.AirlineName}</Box>
                <Box className="rndflightnumber">{segSumry.AirlineDetails.AirlineCode + ' ' + segSumry.AirlineDetails.FlightNumber}</Box>
              </Box>
            </Box>
            <Box>
              <ul className="roundservicesBox">
                <li className="">
                  
                  <Box className="round-radio-fieldwith-iconlist pl-0">
                    <li className="listitemsicon"><Tooltip title={`${props.results[fareValues]['SegmentDetails'][0][0].AvailableSeats} seats left at this price`} arrow placement="top"><span className="available-seats">{props.results[fareValues]['SegmentDetails'][0][0].AvailableSeats} seats</span></Tooltip></li>
                  </Box>

                </li>
              </ul>
            </Box>
            {/* <Box className="roundviewbtndetails">
              <Button className="roundbtnview" variant="contained" color="primary" onClick={viewDetailToggle} >View Details +</Button>
            </Box> */}
          </Col>
          <Col xs={4} sm={4} md={7} lg={7} xl={7}>
            <Box className="flexset-rnd-inside">
              <Box className="defineroundtripwidth">
                <Box className="flightmininame">{segSumry.OriginDetails.AirportCode}</Box>
                <Box className="flightminitime">{segSumry.OriginDetails._DateTime}</Box>
                <Box className="flightminidata">{flightDate(segSumry.OriginDetails._Date)}</Box>
              </Box>
              <Box className="roundarrowbox">
                <Box className="define-stop">{stopsCount[segSumry.TotalStops]}</Box>
                {/* <Box className="roundarrow-img"><img src={arrowimg}></img></Box> */}
                <Box className="arrow-img-round"> <span className='arrow-line'></span><img src={arrowFlight}></img></Box>
                <Box className="define-timecalc">{segSumry.TotalDuaration}</Box>
              </Box>
              <Box className="defineroundtripwidth">
                <Box className="flightmininame">{segSumry.DestinationDetails.AirportCode}</Box>
                <Box className="flightminitime">{segSumry.DestinationDetails._DateTime}</Box>
                <Box className="flightminidata">{flightDate(segSumry.DestinationDetails._Date)}</Box>
              </Box>
            </Box>
            {/* <Box className="whatsapp-share-wrapper">
              <Box className="share-cbx-round">
                <div className='share-icon-box'>
                  <span><WhatsAppIcon className='whatsapp-icon' /> </span>
                  <span  className='share-txtbtn'>Share</span>
                </div>
                <Checkbox 
                  className='share-checkbox'
                  checked={props.selectedFlight.length !== 0 ? (props.selectedFlight.indexOf(props.results[JSON.parse(fareValues)].ProvabAuthKey) > -1 ? true : false) : false}
                  onChange={()=> props.onToggleSelectedFlight(props.results[JSON.parse(fareValues)].SegmentSummary[0],
                                                              props.results[JSON.parse(fareValues)].SegmentSummary[0].OriginDetails, 
                                                              segments[props.results[JSON.parse(fareValues)].SegmentSummary[0].TotalStops].DestinationDetails,
                                                              props.results[JSON.parse(fareValues)].Attr, 
                                                              Math.ceil(props.results[JSON.parse(fareValues)].FareDetails.b2b_PriceDetails._CustomerBuying),
                                                              props.results[JSON.parse(fareValues)],
                                                              props.type === 'onward' ? "Onward" : "Return")}
                />
              </Box>
            </Box> */}
          </Col>
          <Col xs={4} sm={4} md={12} lg={12} xl={12}>
            <Box className="roundradioBox">
              
              <Box>
                
                  {/* <FormControlLabel className="rnditemsradio" label="" control={FareidentifierRadio(props.results[0].Attr.Faretype)} /> */}
                  {/* <FormControlLabel className="rnditemsradio" label={value.Attr.Faretype} value={`${index}`} control={FareidentifierRadio(value.Attr.Faretype)} /> */}
                  <Box className="servicesBox" row aria-label="position" name="position">
                      <Row>
                        <Col xs={8} md={8} className="servicesBoxs px-xs-0 d-flex">
                        {props.showWhatsapp && (
                          <Box className="whatsapp-share-wrapper">
                            <Box className="share-cbx-round">
                              <Checkbox 
                                className='share-checkbox'
                                checked={props.selectedFlight.length !== 0 ? (props.selectedFlight.indexOf(props.results[0].ProvabAuthKey) > -1 ? true : false) : false}
                                onChange={()=> props.onToggleSelectedFlight(props.results[0].SegmentSummary[0],
                                                                            props.results[0].SegmentSummary[0].OriginDetails, 
                                                                            segments[props.results[0].SegmentSummary[0].TotalStops].DestinationDetails,
                                                                            props.results[0].Attr, 
                                                                            Math.ceil(props.results[0].FareDetails.b2b_PriceDetails._CustomerBuying),
                                                                            props.results[0],
                                                                            props.type === 'onward' ? "Onward" : "Return")}
                              />
                            </Box>
                          </Box> 
                        )}
                          <Box className=''>
                            <Box className="round-highlight-price"><span>	&#x20B9; </span>{Math.ceil(props.results[0].PassengerFareBreakdown.ADT.BaseFare + ((props.results[0].PassengerFareBreakdown.ADT.Tax) / (props.results[0].PassengerFareBreakdown.ADT.PassengerCount) + (props.results[0].FareDetails.b2b_PriceDetails._Markup) / (paxCount)) * (props.results[0].PassengerFareBreakdown.ADT.PassengerCount))}
                              &nbsp;<Tooltip onClick={()=>viewDetailToggle(props.results[0], "1")} title="Fare Rules" arrow placement="top" ><InfoOutlinedIcon className='fare-rule-icon' /></Tooltip>
                            </Box>
                            
                            {/* <Box className="itemssecttag">{props.results[fareValues] && props.results[fareValues]['Attr'] && props.results[fareValues]['Attr']['Faretype']}</Box> */}
                          </Box>

                          {/* {props.showNetFare && (
                            <Box className='pricewidth'>
                              <HtmlTooltip
                                title={
                                  <Box>
                                    Commision: {props.results[0].FareDetails.b2b_PriceDetails._Commission} <br />
                                    Markup: {props.results[0].FareDetails.b2b_PriceDetails._Markup}
                                  </Box>
                                } arrow placement="right">
                                <Box className="shownetrd"><span>	&#x20B9; </span> {Math.ceil(props.results[0].FareDetails.b2b_PriceDetails._AgentBuying)}</Box>
                              </HtmlTooltip>
                            </Box>
                          )} */}
                        
                          {/* <ul className="roundlisticon">
                            <li className="listitemsicon">
                              <HtmlTooltip title={
                                <React.Fragment>
                                  Check-In Baggage {props.results[0].SegmentDetails[0][0].Baggage} <br />
                                  Cabin Baggage {props.results[0].SegmentDetails[0][0].CabinBaggage}
                                </React.Fragment>
                              } arrow placement="top">
                                <img src={luggage}></img>
                              </HtmlTooltip>
                            </li>

                            {props.results[0].SegmentDetails[0][0].IsRefundable
                              ? (
                                <li className="listitemsicon"><Tooltip title="Refundable" arrow placement="top"><img src={refund}></img></Tooltip></li>
                              )
                              : (
                                <li className="listitemsicon"><Tooltip title="Non-Refundable" arrow placement="top"><img src={nonrefund}></img></Tooltip></li>
                              )}

                            <li className="listitemsicon"><Tooltip title={`Available seats ${props.results[0].SegmentDetails[0][0].AvailableSeats}`} arrow placement="top"><img src={seat}></img></Tooltip></li>

                          </ul> */}
                        </Col>
                        <Col xs={12} sm={12} md={4} className='text-right'>
                          <Button className="mailbtn" variant="contained" color="primary"
                            onClick={() => selectFlight(fareDetails, props.keyValue, Number(0))}
                          >Select</Button>
                        </Col>
                      </Row>
                    </Box>

                {/* <Box className="round-radio-fieldwith-iconlist">
                  <li className="listitemsicon"><Tooltip title={`${value.SegmentDetails[0][0].AvailableSeats} seats left at this price`} arrow placement="top"><span className="available-seats">{value.SegmentDetails[0][0].AvailableSeats} seats available</span></Tooltip></li>
                </Box> */}

              </Box>
            </Box>
          </Col>
        </Row>

        <Row className='viewmore-box'>
        <Col md={2} xs={7}>
          </Col>
          <Col md={6} xs={12} className="text-right">
            {props.results.length > 1 && (
              <Box className="">
                <Box className="more-flights-btn" variant="contained" color="primary" onClick={moreFlightToggle}>More Flights ({ props.results.length - 1 }) {toggleMoreFlight ? <KeyboardArrowDownIcon className='arrow-down-rotate' />  : <KeyboardArrowDownIcon /> } </Box>
              </Box>
            )}
          </Col>
          <Col md={4} xs={6} >
            <Box className="viewdetailwrp">
              <Box className="view-more-btn" variant="contained" color="primary" onClick={()=>viewDetailToggle(props.results[0], "1")}>View Details {toggle ? <KeyboardArrowDownIcon className='arrow-down-rotate' /> : <KeyboardArrowDownIcon />  }</Box>
            </Box>
          </Col>
        </Row>
        
        {/* roundtrip flight details tabs */}
        <Box>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} >

              {toggle && (
                <Box className="roundtabsBox">
                  <Tabs className="flighttabsdetails" value={value} onChange={handleFlighttabsChange} aria-label="simple tabs example">
                    <Tab className="roundflighttabsdetails-link" label="flight details" {...viewDetailsToggle(0)} />
                    <Tab className="roundflighttabsdetails-link" label="fare details" {...viewDetailsToggle(1)} />
                    <Tab className="roundflighttabsdetails-link" label="Fare Rules" {...viewDetailsToggle(2)} />
                    <Tab className="roundflighttabsdetails-link" label="baggage info" {...viewDetailsToggle(3)} />
                  </Tabs>
                  {/* <Box className="tltfaretype-round">Fare Type: 
                    <span className="tltBaggage-sm"> &nbsp;{fareDetails.Attr.Faretype}</span>
                  </Box> */}
                  <TabPanel className="tabpanelsflightpanel" value={value} index={0}>

                    {props.results[0].SegmentDetails[0].map((value, index) => (

                      <Box className="tabcontentsbox">
                        <Box className="roundto-fromtlt">
                          <span className="roundfromname">{value.OriginDetails.CityName}</span>
                          <span className="roundicoarrowfromto"><img src={arrowimg}></img></span>
                          <span className="roundtoname">{value.DestinationDetails.CityName}</span>
                          <span className="rounddateformto">{formatDate(value.OriginDetails.DateTime)}</span>
                        </Box>
                        <Row>
                          <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                            <Box className="roundflightimg">
                              <Box className="roundimgminifly">

                              </Box>
                              <Box className="roundmini-tag">
                                <Box className="rndflightname">{value.AirlineDetails.AirlineName}</Box>
                                <Box className="rndflightnumber">{value.AirlineDetails.AirlineCode}-{value.AirlineDetails.FlightNumber}</Box>
                              </Box>
                            </Box>
                          </Col>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Row>
                              <Col xs={4} sm={4} md={4} lg={4} xl={4} className="zerogap">
                                <Box className="roundflightairportaddress">
                                  <div className="roundtltdate">{formatDate1(value.OriginDetails.DateTime)}</div>
                                  <p className='mb-0'>{value.OriginDetails.AirportName}, {value.OriginDetails.CityName}</p>
                                  {value?.OriginDetails?.Terminal ? <p>Terminal {value.OriginDetails.Terminal}</p> : null }
                                </Box>
                              </Col>
                              <Col xs={4} sm={4} md={4} lg={4} xl={4} className="zerogap">
                                <Box className="roundarrowtabtxt">Non-Stop</Box>
                                <Box className="roundarrowtabinsidebox"><img src={arrowimg}></img></Box>
                                <Box className="define-timeinfo">{value.SegmentDuration}</Box>
                              </Col>
                              <Col xs={4} sm={4} md={4} lg={4} xl={4} className="zerogap">
                                <Box className="roundflightairportaddress">
                                  <div className="roundtltdate">{formatDate1(value.DestinationDetails.DateTime)}</div>
                                  <p className='mb-0'>{value.DestinationDetails.AirportName}, {value.DestinationDetails.CityName}</p>
                                  {value?.DestinationDetails?.Terminal ? <p>Terminal {value.DestinationDetails.Terminal}</p> : null }
                                </Box>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                            <Box className="roundtime-calc">{value.SegmentDuration}</Box>
                            <Box className="roundtype">{fsRoundReqData.v_class}</Box>
                            {/* <Box className="roundseathighighted"><span className="seathightlighted">{value.AvailableSeats} seats left</span> </Box> */}
                          </Col>
                        </Row>
                        <Box className="layoverbox">
                          {
                            index < props.results[0].SegmentDetails[0].length - 1
                              ? <Box className="layover_rd">Layover <span>{value.WaitingTime}</span></Box>
                              : <Box></Box>
                          }
                        </Box>
                      </Box>
                    ))}
                  </TabPanel>

                  <TabPanel value={value} index={1}>

                    <Row className="roundheaderRow">
                      <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                        <Box className="roundtltBaggage">Type</Box>
                      </Col>
                      <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                        <Box className="roundtltBaggage">Fare</Box>
                      </Col>
                      <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                        <Box className="roundtltBaggage">Total</Box>
                      </Col>
                    </Row>

                    {!!fsRoundReqData.adult && (
                      <>
                        <Row>
                          <Col xs={12}>
                            <Box className="roundtltfaredetails mb-2"><span className="note-qt">Fare Details for Adult({fareDetails.SegmentSummary[0].AirlineDetails.FareClass}) ({fareDetails.SegmentSummary[0].AirlineDetails.FareClassCode})</span></Box>
                          </Col>
                          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                            <Box className="roundtltfaredetails">Base Price</Box>
                            <Box className="roundtltfaredetails">Taxes & fee</Box>
                          </Col>
                          <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                            <Box className="roundtltfaredetails"><span>&#x20B9; </span> <span>{(fareDetails.PassengerFareBreakdown.ADT.BaseFare) / (fareDetails.PassengerFareBreakdown.ADT.PassengerCount)} X {fareDetails.PassengerFareBreakdown.ADT.PassengerCount}</span></Box>

                            <Box className="roundtltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((fareDetails.PassengerFareBreakdown.ADT.Tax) / (fareDetails.PassengerFareBreakdown.ADT.PassengerCount) + (fareDetails.FareDetails.b2b_PriceDetails._Markup) / (paxCount))} X {fareDetails.PassengerFareBreakdown.ADT.PassengerCount}</span></Box>
                          </Col>
                          <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                            <Box className="roundtltfaredetails"><span>&#x20B9; </span> <span>{toDigit(fareDetails.PassengerFareBreakdown.ADT.BaseFare)}</span></Box>
                            <Box className="roundtltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((fareDetails.PassengerFareBreakdown.ADT.Tax) / (fareDetails.PassengerFareBreakdown.ADT.PassengerCount) + (fareDetails.FareDetails.b2b_PriceDetails._Markup) / (paxCount)) * (fareDetails.PassengerFareBreakdown.ADT.PassengerCount)}</span></Box>
                          </Col>
                        </Row>
                        <Row className="roundtotalRow">
                          <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                            <Box className="roundtlt-total">Total</Box>
                          </Col>
                          <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                            <Box className="roundtlt-total"><span>&#x20B9; </span> <span>{Math.ceil(fareDetails.PassengerFareBreakdown.ADT.BaseFare + ((fareDetails.PassengerFareBreakdown.ADT.Tax) / (fareDetails.PassengerFareBreakdown.ADT.PassengerCount) + (fareDetails.FareDetails.b2b_PriceDetails._Markup) / (paxCount))) * (fareDetails.PassengerFareBreakdown.ADT.PassengerCount)}</span></Box>
                          </Col>
                        </Row>
                      </>
                    )}

                    {!!fsRoundReqData.child && (
                      <>
                        <Row>
                          <Col xs={12}>
                            <Box className="roundtltfaredetails mb-2"><span className="note-qt">Fare Details for Child({fareDetails.SegmentSummary[0].AirlineDetails.FareClass}) ({fareDetails.SegmentSummary[0].AirlineDetails.FareClassCode})</span></Box>
                          </Col>
                          <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                            <Box className="roundtltfaredetails">Base Price</Box>
                            <Box className="roundtltfaredetails">Taxes & fee</Box>
                          </Col>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Box className="roundtltfaredetails"><span>&#x20B9; </span> <span>{(fareDetails.PassengerFareBreakdown.CHD.BaseFare) / (fareDetails.PassengerFareBreakdown.CHD.PassengerCount)} X {fareDetails.PassengerFareBreakdown.CHD.PassengerCount}</span></Box>

                            <Box className="roundtltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((fareDetails.PassengerFareBreakdown.CHD.Tax) / (fareDetails.PassengerFareBreakdown.CHD.PassengerCount) + (fareDetails.FareDetails.b2b_PriceDetails._Markup) / (paxCount))} X {fareDetails.PassengerFareBreakdown.CHD.PassengerCount}</span></Box>
                          </Col>
                          <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                            <Box className="roundtltfaredetails"><span>&#x20B9; </span> <span>{toDigit(fareDetails.PassengerFareBreakdown.CHD.BaseFare)}</span></Box>
                            <Box className="roundtltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((fareDetails.PassengerFareBreakdown.CHD.Tax) / (fareDetails.PassengerFareBreakdown.CHD.PassengerCount) + (fareDetails.FareDetails.b2b_PriceDetails._Markup) / (paxCount)) * (fareDetails.PassengerFareBreakdown.CHD.PassengerCount)}</span></Box>
                          </Col>
                        </Row>
                        <Row className="roundtotalRow">
                          <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                            <Box className="roundtlt-total">Total</Box>
                          </Col>
                          <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                            <Box className="roundtlt-total"><span>&#x20B9; </span> <span>{Math.ceil(fareDetails.PassengerFareBreakdown.CHD.BaseFare + ((fareDetails.PassengerFareBreakdown.CHD.Tax) / (fareDetails.PassengerFareBreakdown.CHD.PassengerCount) + (fareDetails.FareDetails.b2b_PriceDetails._Markup) / (paxCount))) * (fareDetails.PassengerFareBreakdown.CHD.PassengerCount)}</span></Box>
                          </Col>
                        </Row>
                      </>
                    )}

                    {!!fsRoundReqData.infant && (
                      <>
                        <Row>
                          <Col xs={12}>
                            <Box className="roundtltfaredetails mb-2"><span className="note-qt">Fare Details for Infant({fareDetails.SegmentSummary[0].AirlineDetails.FareClass}) ({fareDetails.SegmentSummary[0].AirlineDetails.FareClassCode})</span></Box>
                          </Col>
                          <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                            <Box className="roundtltfaredetails">Base Price</Box>
                            <Box className="roundtltfaredetails">Taxes & fee</Box>
                          </Col>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Box className="roundtltfaredetails"><span>&#x20B9; </span> <span>{(fareDetails.PassengerFareBreakdown.INF.BaseFare) / (fareDetails.PassengerFareBreakdown.INF.PassengerCount)} X {fareDetails.PassengerFareBreakdown.INF.PassengerCount}</span></Box>

                            <Box className="roundtltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((fareDetails.PassengerFareBreakdown.INF.Tax) / (fareDetails.PassengerFareBreakdown.INF.PassengerCount))} X {fareDetails.PassengerFareBreakdown.INF.PassengerCount}</span></Box>
                          </Col>
                          <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                            <Box className="roundtltfaredetails"><span>&#x20B9; </span> <span>{toDigit(fareDetails.PassengerFareBreakdown.INF.BaseFare)}</span></Box>
                            <Box className="roundtltfaredetails"><span>&#x20B9; </span> <span>{(Math.ceil(fareDetails.PassengerFareBreakdown.INF.Tax))}</span></Box>
                          </Col>
                        </Row>
                        <Row className="roundtotalRow">
                          <Col xs={9} sm={9} md={9} lg={9} xl={9}>
                            <Box className="roundtlt-total">Total</Box>
                          </Col>
                          <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                            <Box className="roundtlt-total"><span>&#x20B9; </span> <span>{Math.ceil(fareDetails.PassengerFareBreakdown.INF.BaseFare + fareDetails.PassengerFareBreakdown.INF.Tax)}</span></Box>
                          </Col>
                        </Row>
                      </>
                    )}

                  </TabPanel>
                  <TabPanel value={value} index={2}>

                    {fareLoader
                      ? (
                        <span>Please wait...</span>
                      )
                      : (
                        <>
                          {/* <p className="p-font14-rd">{ReactHtmlParser(fareRules)}</p> */}
                        </>
                      )}

                  </TabPanel>

                  <TabPanel value={value} index={3}>
                    <Row className="roundheaderRow">
                      <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                        <Box className="roundtltBaggage">Sector</Box>
                      </Col>
                      <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Box className="roundtltBaggage">Check-in</Box>
                      </Col>
                      <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                        <Box className="roundtltBaggage">Cabin</Box>
                      </Col>
                    </Row>
                    {fareDetails.SegmentDetails[0].map((value, index) => (
                      <>
                        <Row key={index}>
                          <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                            <Box className="roundtltBaggage-desc">{value.OriginDetails.AirportCode} - {value.DestinationDetails.AirportCode}</Box>
                          </Col>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Box className="roundtltBaggage-desc">{value.Baggage}</Box>
                          </Col>
                          <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                            <Box className="roundtltBaggage-desc">{value.CabinBaggage}</Box>
                          </Col>
                        </Row>
                      </>
                    ))}

                  </TabPanel>

                  <Box className="closebox" onClick={viewDetailToggle}><CloseIcon /></Box>
                </Box>

              )}

            </Col>
          </Row>
        </Box>

        <Box className='more-flight-container'>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              {toggleMoreFlight && (
                <Box>
                  <ul className="servicesBox">
                  {props.results.slice(1, props.results.length).map((value, index) => (
                    <li key={index}>
                      <Row className="viewroundresultbooking">
                        <Col xs={9} sm={9}>
                          <Box className="roundradioBox">
                            <Box row aria-label="position" name="position"
                              defaultValue="top" orientation="vertical"
                            >
                              <ul className="roundservicesBox">

                                  <li key={index}>
                                    <Box className="round-radio-fieldwith-iconlist">
                                      {/* <FormControlLabel className="rnditemsradio" label="" value={`${index}`} control={FareidentifierRadio(value.Attr.Faretype)} /> */}
                                      {/* <FormControlLabel className="rnditemsradio" label={value.Attr.Faretype} value={`${index}`} control={FareidentifierRadio(value.Attr.Faretype)} /> */}
                                     
                                      <Box className="roundflex-servicesbox">
                                       
                                          <Box className=''>
                                            <Box className="round-highlight-price"><span>	&#x20B9; </span>{Math.ceil(props.results.slice(1)[index].PassengerFareBreakdown.ADT.BaseFare + ((props.results.slice(1)[index].PassengerFareBreakdown.ADT.Tax) / (props.results.slice(1)[index].PassengerFareBreakdown.ADT.PassengerCount) + (props.results.slice(1)[index].FareDetails.b2b_PriceDetails._Markup) / (paxCount)) * (props.results.slice(1)[index].PassengerFareBreakdown.ADT.PassengerCount))}
                                            &nbsp;<Tooltip onClick={()=>viewDetailToggle(props.results.slice(1)[index], "2")} title="Fare Rules" arrow placement="top" ><InfoOutlinedIcon className='fare-rule-icon' /></Tooltip>
                                            </Box>
                                            <Box className="itemssecttag">{value.Attr.Faretype}</Box>
                                            <Box className="listitemsicon"><Tooltip title={`${props.results[fareValues]['SegmentDetails'][0][0].AvailableSeats} seats left at this price`} arrow placement="top"><span className="available-seats">{props.results[fareValues]['SegmentDetails'].AvailableSeats} seats</span></Tooltip></Box>

                                          </Box>
                                              
                                          {/* {props.showNetFare && (
                                            <Box className='pricewidth'>
                                              <HtmlTooltip
                                                title={
                                                  <Box>
                                                    Commision: {value.FareDetails.b2b_PriceDetails._Commission} <br />
                                                    Markup: {value.FareDetails.b2b_PriceDetails._Markup}
                                                  </Box>
                                                } arrow placement="right">
                                                <Box className="shownetrd"><span>	&#x20B9; </span> {Math.ceil(value.FareDetails.b2b_PriceDetails._AgentBuying)}</Box>
                                              </HtmlTooltip>
                                            </Box>
                                          )} */}
                                          
                                            {/* <ul className="roundlisticon">
                                              <li className="listitemsicon">
                                                <HtmlTooltip title={
                                                  <React.Fragment>
                                                    Check-In Baggage {value.SegmentDetails[0][0].Baggage} <br />
                                                    Cabin Baggage {value.SegmentDetails[0][0].CabinBaggage}
                                                  </React.Fragment>
                                                } arrow placement="top">
                                                  <img src={luggage}></img>
                                                </HtmlTooltip>
                                              </li>

                                              {value.SegmentDetails[0][0].IsRefundable
                                                ? (
                                                  <li className="listitemsicon"><Tooltip title="Refundable" arrow placement="top"><img src={refund}></img></Tooltip></li>
                                                )
                                                : (
                                                  <li className="listitemsicon"><Tooltip title="Non-Refundable" arrow placement="top"><img src={nonrefund}></img></Tooltip></li>
                                                )}

                                              <li className="listitemsicon"><Tooltip title={`Available seats ${value.SegmentDetails[0][0].AvailableSeats}`} arrow placement="top"><img src={seat}></img></Tooltip></li>

                                            </ul> */}
                                          </Box>
                                        </Box>

                                    {/* <Box className="round-radio-fieldwith-iconlist">
                                      <li className="listitemsicon"><Tooltip title={`${value.SegmentDetails[0][0].AvailableSeats} seats left at this price`} arrow placement="top"><span className="available-seats">{value.SegmentDetails[0][0].AvailableSeats} seats available</span></Tooltip></li>
                                    </Box> */}
                                  </li>
                              </ul>
                            </Box>
                          </Box>
                        </Col>
                        <Col md={3} lg={3} xl={3} className="m-auto">
                          <Button className="selectbtnOutline" variant="contained" color="primary"
                            onClick={() => selectFlight(fareDetails, props.keyValue, Number(index+1))}
                          >Select</Button>
                        </Col>
                      </Row>
                    </li>
                  ))}

                </ul>
                </Box>


              )}

            </Col>
          </Row>
        </Box>
        
        {/* end roundtrip flight details tabs */}
      </Box>

      {/* <RoundTripBook /> */}

      <Box className={[getSectionSelected() ? 'roundflightdetailscard activerndbg' : 'roundflightdetailscard', "roundtripresult-mob"]}>
        
        <Row className="viewroundresultbooking">
          <Col xs={3} sm={3}>
            <Box className="roundflightimg">
              <Box className="roundimgminifly">
                <div className={'flagflt itwgo-60x35_' + airlines.AirlineCode}></div>
              </Box>
              <Box className="roundmini-tag">
                <Box className="rndflightname">{segSumry.AirlineDetails.AirlineName}</Box>
                <Box className="rndflightnumber">{segSumry.AirlineDetails.AirlineCode + ' ' + segSumry.AirlineDetails.FlightNumber}</Box>
              </Box>
            </Box>
            <Box>
              <ul className="roundservicesBox">
                <li className="">
                  
                  <Box className="round-radio-fieldwith-iconlist pl-0">
                    <li className="listitemsicon"><Tooltip title={`${props.results[fareValues]['SegmentDetails'][0][0].AvailableSeats} seats left at this price`} arrow placement="top"><span className="available-seats">{props.results[fareValues]['SegmentDetails'][0][0].AvailableSeats} seats</span></Tooltip></li>
                  </Box>

                </li>
              </ul>
            </Box>

          </Col>
          <Col xs={9} sm={9}>
            <Box className="flexset-rnd-inside">
              <Box className="defineroundtripwidth">
                <Box className="flightmininame">{segSumry.OriginDetails.AirportCode}</Box>
                <Box className="flightminitime">{segSumry.OriginDetails._DateTime}</Box>
                <Box className="flightminidata">{flightDate(segSumry.OriginDetails._Date)}</Box>
              </Box>
              <Box className="roundarrowbox">
                <Box className="define-stop">{stopsCount[segSumry.TotalStops]}</Box>
                {/* <Box className="roundarrow-img"><img src={arrowimg}></img></Box> */}
                <Box className="arrow-img-round"> <span className='arrow-line'></span><img src={arrowFlight}></img></Box>
                <Box className="define-timecalc">{segSumry.TotalDuaration}</Box>
              </Box>
              <Box className="defineroundtripwidth">
                <Box className="flightmininame">{segSumry.DestinationDetails.AirportCode}</Box>
                <Box className="flightminitime">{segSumry.DestinationDetails._DateTime}</Box>
                <Box className="flightminidata">{flightDate(segSumry.DestinationDetails._Date)}</Box>
              </Box>
            </Box>
            {/* <Box className="whatsapp-share-wrapper">
              <Box className="share-cbx-round">
                <div className='share-icon-box'>
                  <span><WhatsAppIcon className='whatsapp-icon' /> </span>
                  <span  className='share-txtbtn'>Share</span>
                </div>
                <Checkbox 
                  className='share-checkbox'
                  checked={props.selectedFlight.length !== 0 ? (props.selectedFlight.indexOf(props.results[JSON.parse(fareValues)].ProvabAuthKey) > -1 ? true : false) : false}
                  onChange={()=> props.onToggleSelectedFlight(props.results[JSON.parse(fareValues)].SegmentSummary[0],
                                                              props.results[JSON.parse(fareValues)].SegmentSummary[0].OriginDetails, 
                                                              segments[props.results[JSON.parse(fareValues)].SegmentSummary[0].TotalStops].DestinationDetails,
                                                              props.results[JSON.parse(fareValues)].Attr, 
                                                              Math.ceil(props.results[JSON.parse(fareValues)].FareDetails.b2b_PriceDetails._CustomerBuying),
                                                              props.results[JSON.parse(fareValues)],
                                                              props.type === 'onward' ? "Onward" : "Return")}
                />
              </Box>
            </Box> */}
          </Col>
          <Col xs={12} sm={12}>
            <Box className="roundradioBox">
              
              <Box>
                
                  {/* <FormControlLabel className="rnditemsradio" label="" control={FareidentifierRadio(props.results[0].Attr.Faretype)} /> */}
                  {/* <FormControlLabel className="rnditemsradio" label={value.Attr.Faretype} value={`${index}`} control={FareidentifierRadio(value.Attr.Faretype)} /> */}
                  <Box className="servicesBox" row aria-label="position" name="position">
                    <Row>
                      <Col xs={8} className="servicesBoxs px-xs-0  d-flex">
                      {props.showWhatsapp && (
                        <Box className="whatsapp-share-wrapper">
                          <Box className="share-cbx-round">
                            <Checkbox 
                              className='share-checkbox'
                              checked={props.selectedFlight.length !== 0 ? (props.selectedFlight.indexOf(props.results[0].ProvabAuthKey) > -1 ? true : false) : false}
                              onChange={()=> props.onToggleSelectedFlight(props.results[0].SegmentSummary[0],
                                                                          props.results[0].SegmentSummary[0].OriginDetails, 
                                                                          segments[props.results[0].SegmentSummary[0].TotalStops].DestinationDetails,
                                                                          props.results[0].Attr, 
                                                                          Math.ceil(props.results[0].FareDetails.b2b_PriceDetails._CustomerBuying),
                                                                          props.results[0],
                                                                          props.type === 'onward' ? "Onward" : "Return")}
                            />
                          </Box>
                        </Box> 
                      )}
                        <Box className=''>
                          <Box className="round-highlight-price"><span>	&#x20B9; </span>{Math.ceil(props.results[0].PassengerFareBreakdown.ADT.BaseFare + ((props.results[0].PassengerFareBreakdown.ADT.Tax) / (props.results[0].PassengerFareBreakdown.ADT.PassengerCount) + (props.results[0].FareDetails.b2b_PriceDetails._Markup) / (paxCount)) * (props.results[0].PassengerFareBreakdown.ADT.PassengerCount))}
                            &nbsp;<Tooltip onClick={()=>viewDetailToggle(props.results[0], "1")} title="Fare Rules" arrow placement="top" ><InfoOutlinedIcon className='fare-rule-icon' /></Tooltip>
                          </Box>
                          
                          {/* <Box className="itemssecttag">{props.results[fareValues] && props.results[fareValues]['Attr'] && props.results[fareValues]['Attr']['Faretype']}</Box> */}
                        </Box>

                        {/* {props.showNetFare && (
                          <Box className='pricewidth'>
                            <HtmlTooltip
                              title={
                                <Box>
                                  Commision: {props.results[0].FareDetails.b2b_PriceDetails._Commission} <br />
                                  Markup: {props.results[0].FareDetails.b2b_PriceDetails._Markup}
                                </Box>
                              } arrow placement="right">
                              <Box className="shownetrd"><span>	&#x20B9; </span> {Math.ceil(props.results[0].FareDetails.b2b_PriceDetails._AgentBuying)}</Box>
                            </HtmlTooltip>
                          </Box>
                        )}
                      
                        <ul className="roundlisticon">
                          <li className="listitemsicon">
                            <HtmlTooltip title={
                              <React.Fragment>
                                Check-In Baggage {props.results[0].SegmentDetails[0][0].Baggage} <br />
                                Cabin Baggage {props.results[0].SegmentDetails[0][0].CabinBaggage}
                              </React.Fragment>
                            } arrow placement="top">
                              <img src={luggage}></img>
                            </HtmlTooltip>
                          </li>

                          {props.results[0].SegmentDetails[0][0].IsRefundable
                            ? (
                              <li className="listitemsicon"><Tooltip title="Refundable" arrow placement="top"><img src={refund}></img></Tooltip></li>
                            )
                            : (
                              <li className="listitemsicon"><Tooltip title="Non-Refundable" arrow placement="top"><img src={nonrefund}></img></Tooltip></li>
                            )}

                          <li className="listitemsicon"><Tooltip title={`Available seats ${props.results[0].SegmentDetails[0][0].AvailableSeats}`} arrow placement="top"><img src={seat}></img></Tooltip></li>

                        </ul> */}
                      </Col>
                      <Col xs={4} className="m-auto zerogap" >
                        <Button className="mailbtn" variant="contained" color="primary"
                          onClick={() => selectFlight(fareDetails, props.keyValue, Number(0))}
                        >Select</Button>
                      </Col>
                    </Row>
                  </Box>

                {/* <Box className="round-radio-fieldwith-iconlist">
                  <li className="listitemsicon"><Tooltip title={`${value.SegmentDetails[0][0].AvailableSeats} seats left at this price`} arrow placement="top"><span className="available-seats">{value.SegmentDetails[0][0].AvailableSeats} seats available</span></Tooltip></li>
                </Box> */}

              </Box>
            </Box>
          </Col>
        </Row>

            <Row className='viewmore-box'>
                <Col xs={7} className="text-right">
                  {props.results.length > 1 && (
                    <Box className="">
                      <Box className="more-flights-btn" variant="contained" color="primary" onClick={moreFlightToggle}>More Flights ({ props.results.length - 1 }) {toggleMoreFlight ? <KeyboardArrowDownIcon className='arrow-down-rotate' />  : <KeyboardArrowDownIcon /> } </Box>
                    </Box>
                  )}
                </Col>
                <Col xs={5} >
                  <Box className="viewdetailwrp">
                    <Box className="view-more-btn" variant="contained" color="primary" onClick={()=>viewDetailToggle(props.results[0], "1")}>View Details {toggle ? <KeyboardArrowDownIcon className='arrow-down-rotate' /> : <KeyboardArrowDownIcon />  }</Box>
                  </Box>
                </Col>
              </Row>
              
            {/* roundtrip flight details tabs */}
            <Box className={Roundclass.root}>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="zerogap">

                  {toggle && (
                    <Box className="roundtabsBox">
                      <Tabs className="flighttabsdetails" value={value} onChange={handleFlighttabsChange} aria-label="simple tabs example">
                        <Tab className="roundflighttabsdetails-link" label="flight details" {...viewDetailsToggle(0)} />
                        <Tab className="roundflighttabsdetails-link" label="fare details" {...viewDetailsToggle(1)} />
                        <Tab className="roundflighttabsdetails-link" label="Fare Rules" {...viewDetailsToggle(2)} />
                        <Tab className="roundflighttabsdetails-link" label="baggage info" {...viewDetailsToggle(3)} />
                      </Tabs>

                      <TabPanel className="tabpanelsflightpanel" value={value} index={0}>

                        {props.results[0].SegmentDetails[0].map((value, index) => (

                          <Box className="tabcontentsbox">
                            <Box className="roundto-fromtlt">
                              <span className="roundfromname">{value.OriginDetails.CityName}</span>
                              <span className="roundicoarrowfromto"><img src={arrowimg}></img></span>
                              <span className="roundtoname">{value.DestinationDetails.CityName}</span>
                              <span className="rounddateformto">{formatDate(value.OriginDetails.DateTime)}</span>
                            </Box>
                            <Row>
                              <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                                <Box className="roundflightimg">
                                  <Box className="roundimgminifly">

                                  </Box>
                                  <Box className="roundmini-tag">
                                    <Box className="rndflightname">{value.AirlineDetails.AirlineName}</Box>
                                    <Box className="rndflightnumber">{value.AirlineDetails.AirlineCode}-{value.AirlineDetails.FlightNumber}</Box>
                                  </Box>
                                </Box>
                              </Col>
                              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Row>
                                  <Col xs={4} sm={4} md={4} lg={4} xl={4} className="zerogap">
                                    <Box className="roundflightairportaddress">
                                      <div className="roundtltdate">{formatDate1(value.OriginDetails.DateTime)}</div>
                                      <p className='mb-0'>{value.OriginDetails.AirportName}, {value.OriginDetails.CityName}</p>
                                      {value?.OriginDetails?.Terminal ? <p>Terminal {value.OriginDetails.Terminal}</p> : null }
                                    </Box>
                                  </Col>
                                  <Col xs={4} sm={4} md={4} lg={4} xl={4} className="zerogap">
                                    <Box className="roundarrowtabtxt">Non-Stop</Box>
                                    <Box className="roundarrowtabinsidebox"><img src={arrowimg}></img></Box>
                                    <Box className="define-timeinfo">{value.SegmentDuration}</Box>
                                  </Col>
                                  <Col xs={4} sm={4} md={4} lg={4} xl={4} className="zerogap">
                                    <Box className="roundflightairportaddress">
                                      <div className="roundtltdate">{formatDate1(value.DestinationDetails.DateTime)}</div>
                                      <p className='mb-0'>{value.DestinationDetails.AirportName}, {value.DestinationDetails.CityName}</p>
                                      {value?.DestinationDetails?.Terminal ? <p>Terminal {value.DestinationDetails.Terminal}</p> : null }
                                    </Box>
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                                <Box className="roundtime-calc">{value.SegmentDuration}</Box>
                                <Box className="roundtype">{fsRoundReqData.v_class}</Box>
                                {/* <Box className="roundseathighighted"><span className="seathightlighted">{value.AvailableSeats} seats left</span> </Box> */}
                              </Col>
                            </Row>
                            <Box className="layoverbox">
                              {
                                index < props.results[0].SegmentDetails[0].length - 1
                                  ? <Box className="layover_rd">Layover <span>{value.WaitingTime}</span></Box>
                                  : <Box></Box>
                              }
                            </Box>
                          </Box>
                        ))}
                      </TabPanel>

                      <TabPanel value={value} index={1}>

                        <Row className="roundheaderRow">
                          <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                            <Box className="roundtltBaggage">Type</Box>
                          </Col>
                          <Col xs={4} sm={4} md={4} lg={4} xl={4} className="px-0">
                            <Box className="roundtltBaggage">Fare</Box>
                          </Col>
                          <Col xs={3} sm={3} md={3} lg={3} xl={3} className="px-0">
                            <Box className="roundtltBaggage">Total</Box>
                          </Col>
                        </Row>

                        {!!fsRoundReqData.adult && (
                          <>
                            <Row>
                              <Col xs={5} sm={5} md={3} lg={3} xl={3}>
                                <Box className="roundtltfaredetails"><span className="note-qt">Fare Details for Adult({fareDetails.SegmentSummary[0].AirlineDetails.FareClass}) ({fareDetails.SegmentSummary[0].AirlineDetails.FareClassCode})</span></Box>
                                <Box className="roundtltfaredetails">Base Price</Box>
                                <Box className="roundtltfaredetails">Taxes & fee</Box>
                              </Col>
                              <Col xs={4} sm={4} md={6} lg={6} xl={6} className="px-0">
                                <Box className="roundtltfaredetails">&nbsp;</Box>
                                <Box className="roundtltfaredetails"><span>&#x20B9; </span> <span>{(fareDetails.PassengerFareBreakdown.ADT.BaseFare) / (fareDetails.PassengerFareBreakdown.ADT.PassengerCount)} X {fareDetails.PassengerFareBreakdown.ADT.PassengerCount}</span></Box>

                                <Box className="roundtltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((fareDetails.PassengerFareBreakdown.ADT.Tax) / (fareDetails.PassengerFareBreakdown.ADT.PassengerCount) + (fareDetails.FareDetails.b2b_PriceDetails._Markup) / (paxCount))} X {fareDetails.PassengerFareBreakdown.ADT.PassengerCount}</span></Box>
                              </Col>
                              <Col xs={3} sm={3} md={3} lg={3} xl={3} className="px-0">
                                <Box className="roundtltfaredetails">&nbsp;</Box>
                                <Box className="roundtltfaredetails"><span>&#x20B9; </span> <span>{toDigit(fareDetails.PassengerFareBreakdown.ADT.BaseFare)}</span></Box>
                                <Box className="roundtltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((fareDetails.PassengerFareBreakdown.ADT.Tax) / (fareDetails.PassengerFareBreakdown.ADT.PassengerCount) + (fareDetails.FareDetails.b2b_PriceDetails._Markup) / (paxCount)) * (fareDetails.PassengerFareBreakdown.ADT.PassengerCount)}</span></Box>
                              </Col>
                            </Row>
                            <Row className="roundtotalRow">
                              <Col xs={6} sm={9} md={9} lg={9} xl={9}>
                                <Box className="roundtlt-total">Total</Box>
                              </Col>
                              <Col xs={6} sm={3} md={3} lg={3} xl={3}>
                                <Box className="roundtlt-total roundtlt-total-right"><span>&#x20B9; </span> <span>{Math.ceil(fareDetails.PassengerFareBreakdown.ADT.BaseFare + ((fareDetails.PassengerFareBreakdown.ADT.Tax) / (fareDetails.PassengerFareBreakdown.ADT.PassengerCount) + (fareDetails.FareDetails.b2b_PriceDetails._Markup) / (paxCount))) * (fareDetails.PassengerFareBreakdown.ADT.PassengerCount)}</span></Box>
                              </Col>
                            </Row>
                          </>
                        )}

                        {!!fsRoundReqData.child && (
                          <>
                            <Row>
                              <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                <Box className="roundtltfaredetails"><span className="note-qt">Fare Details for Child({fareDetails.SegmentSummary[0].AirlineDetails.FareClass}) ({fareDetails.SegmentSummary[0].AirlineDetails.FareClassCode})</span></Box>
                                <Box className="roundtltfaredetails">Base Price</Box>
                                <Box className="roundtltfaredetails">Taxes & fee</Box>
                              </Col>
                              <Col xs={6} sm={6} md={6} lg={6} xl={6} className="px-0">
                                <Box className="roundtltfaredetails">&nbsp;</Box>
                                <Box className="roundtltfaredetails"><span>&#x20B9; </span> <span>{(fareDetails.PassengerFareBreakdown.CHD.BaseFare) / (fareDetails.PassengerFareBreakdown.CHD.PassengerCount)} X {fareDetails.PassengerFareBreakdown.CHD.PassengerCount}</span></Box>

                                <Box className="roundtltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((fareDetails.PassengerFareBreakdown.CHD.Tax) / (fareDetails.PassengerFareBreakdown.CHD.PassengerCount) + (fareDetails.FareDetails.b2b_PriceDetails._Markup) / (paxCount))} X {fareDetails.PassengerFareBreakdown.CHD.PassengerCount}</span></Box>
                              </Col>
                              <Col xs={3} sm={3} md={3} lg={3} xl={3} className="px-0">
                                <Box className="roundtltfaredetails">&nbsp;</Box>
                                <Box className="roundtltfaredetails"><span>&#x20B9; </span> <span>{toDigit(fareDetails.PassengerFareBreakdown.CHD.BaseFare)}</span></Box>
                                <Box className="roundtltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((fareDetails.PassengerFareBreakdown.CHD.Tax) / (fareDetails.PassengerFareBreakdown.CHD.PassengerCount) + (fareDetails.FareDetails.b2b_PriceDetails._Markup) / (paxCount)) * (fareDetails.PassengerFareBreakdown.CHD.PassengerCount)}</span></Box>
                              </Col>
                            </Row>
                            <Row className="roundtotalRow">
                              <Col xs={6} sm={9} md={9} lg={9} xl={9}>
                                <Box className="roundtlt-total">Total</Box>
                              </Col>
                              <Col xs={6} sm={3} md={3} lg={3} xl={3}>
                                <Box className="roundtlt-total roundtlt-total-right"><span>&#x20B9; </span> <span>{Math.ceil(fareDetails.PassengerFareBreakdown.CHD.BaseFare + ((fareDetails.PassengerFareBreakdown.CHD.Tax) / (fareDetails.PassengerFareBreakdown.CHD.PassengerCount) + (fareDetails.FareDetails.b2b_PriceDetails._Markup) / (paxCount))) * (fareDetails.PassengerFareBreakdown.CHD.PassengerCount)}</span></Box>
                              </Col>
                            </Row>
                          </>
                        )}

                        {!!fsRoundReqData.infant && (
                          <>
                            <Row>
                              <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                <Box className="roundtltfaredetails"><span className="note-qt">Fare Details for Infant({fareDetails.SegmentSummary[0].AirlineDetails.FareClass}) ({fareDetails.SegmentSummary[0].AirlineDetails.FareClassCode})</span></Box>
                                <Box className="roundtltfaredetails">Base Price</Box>
                                <Box className="roundtltfaredetails">Taxes & fee</Box>
                              </Col>
                              <Col xs={6} sm={6} md={6} lg={6} xl={6} className="px-0">
                                <Box className="roundtltfaredetails">&nbsp;</Box>
                                <Box className="roundtltfaredetails"><span>&#x20B9; </span> <span>{(fareDetails.PassengerFareBreakdown.INF.BaseFare) / (fareDetails.PassengerFareBreakdown.INF.PassengerCount)} X {fareDetails.PassengerFareBreakdown.INF.PassengerCount}</span></Box>

                                <Box className="roundtltfaredetails"><span>&#x20B9; </span> <span>{Math.ceil((fareDetails.PassengerFareBreakdown.INF.Tax) / (fareDetails.PassengerFareBreakdown.INF.PassengerCount))} X {fareDetails.PassengerFareBreakdown.INF.PassengerCount}</span></Box>
                              </Col>
                              <Col xs={3} sm={3} md={3} lg={3} xl={3} className="px-0">
                                <Box className="roundtltfaredetails">&nbsp;</Box>
                                <Box className="roundtltfaredetails"><span>&#x20B9; </span> <span>{toDigit(fareDetails.PassengerFareBreakdown.INF.BaseFare)}</span></Box>
                                <Box className="roundtltfaredetails"><span>&#x20B9; </span> <span>{(Math.ceil(fareDetails.PassengerFareBreakdown.INF.Tax))}</span></Box>
                              </Col>
                            </Row>
                            <Row className="roundtotalRow">
                              <Col xs={6} sm={9} md={9} lg={9} xl={9}>
                                <Box className="roundtlt-total">Total</Box>
                              </Col>
                              <Col xs={6} sm={3} md={3} lg={3} xl={3}>
                                <Box className="roundtlt-total roundtlt-total-right"><span>&#x20B9; </span> <span>{Math.ceil(fareDetails.PassengerFareBreakdown.INF.BaseFare + fareDetails.PassengerFareBreakdown.INF.Tax)}</span></Box>
                              </Col>
                            </Row>
                          </>
                        )}

                      </TabPanel>
                      <TabPanel value={value} index={2}>

                        {fareLoader
                          ? (
                            <span>Please wait...</span>
                          )
                          : (
                            <>
                              {/* <p className="p-font14-rd">{ReactHtmlParser(fareRules)}</p> */}
                            </>
                          )}

                      </TabPanel>

                      <TabPanel value={value} index={3}>
                        <Row className="roundheaderRow">
                          <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                            <Box className="roundtltBaggage">Sector</Box>
                          </Col>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Box className="roundtltBaggage">Check-in</Box>
                          </Col>
                          <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                            <Box className="roundtltBaggage">Cabin</Box>
                          </Col>
                        </Row>
                        {fareDetails.SegmentDetails[0].map((value, index) => (
                          <>
                            <Row key={index}>
                              <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                <Box className="roundtltBaggage-desc">{value.OriginDetails.AirportCode} - {value.DestinationDetails.AirportCode}</Box>
                              </Col>
                              <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Box className="roundtltBaggage-desc">{value.Baggage}</Box>
                              </Col>
                              <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                <Box className="roundtltBaggage-desc">{value.CabinBaggage}</Box>
                              </Col>
                            </Row>
                          </>
                        ))}

                      </TabPanel>
                      <Box className="closebox" onClick={viewDetailToggle}><CloseIcon /></Box>
                    </Box>

                  )}

                </Col>
              </Row>

              <Box className='more-flight-container'>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    {toggleMoreFlight && (
                      <Box>
                        <ul className="servicesBox">
                        {props.results.slice(1, props.results.length).map((value, index) => (
                          <li key={index}>
                            <Row className="viewroundresultbooking">
                              <Col xs={8} md={8}>
                                <Box className="roundradioBox">
                                  <Box row aria-label="position" name="position"
                                    defaultValue="top" orientation="vertical"
                                  >
                                    <ul className="roundservicesBox">

                                        <li key={index}>
                                          <Box className="round-radio-fieldwith-iconlist">
                                            {/* <FormControlLabel className="rnditemsradio" label="" value={`${index}`} control={FareidentifierRadio(value.Attr.Faretype)} /> */}
                                            {/* <FormControlLabel className="rnditemsradio" label={value.Attr.Faretype} value={`${index}`} control={FareidentifierRadio(value.Attr.Faretype)} /> */}
                                          
                                            <Box className="roundflex-servicesbox">
                                              {props.showWhatsapp && (
                                                <Box className="whatsapp-share-wrapper">
                                                  <Box className="share-cbx-round">
                                                    <Checkbox 
                                                      className='share-checkbox'
                                                      checked={props.selectedFlight.length !== 0 ? (props.selectedFlight.indexOf(props.results.slice(1)[index].ProvabAuthKey) > -1 ? true : false) : false}
                                                      onChange={()=> props.onToggleSelectedFlight(props.results.slice(1)[index].SegmentSummary[0],
                                                                                                  props.results.slice(1)[index].SegmentSummary[0].OriginDetails, 
                                                                                                  segments[props.results.slice(1)[index].SegmentSummary[0].TotalStops].DestinationDetails,
                                                                                                  props.results.slice(1)[index].Attr, 
                                                                                                  Math.ceil(props.results.slice(1)[index].FareDetails.b2b_PriceDetails._CustomerBuying),
                                                                                                  props.results.slice(1)[index],
                                                                                                  props.type === 'onward' ? "Onward" : "Return")}
                                                    />
                                                  </Box>
                                                </Box> 
                                              )}
                                                <Box className=''>
                                                  <Box className="round-highlight-price"><span>	&#x20B9; </span>{Math.ceil(props.results.slice(1)[index].PassengerFareBreakdown.ADT.BaseFare + ((props.results.slice(1)[index].PassengerFareBreakdown.ADT.Tax) / (props.results.slice(1)[index].PassengerFareBreakdown.ADT.PassengerCount) + (props.results.slice(1)[index].FareDetails.b2b_PriceDetails._Markup) / (paxCount)) * (props.results.slice(1)[index].PassengerFareBreakdown.ADT.PassengerCount))}
                                                  &nbsp;<Tooltip onClick={()=>viewDetailToggle(props.results.slice(1)[index], "2")} title="Fare Rules" arrow placement="top" ><InfoOutlinedIcon className='fare-rule-icon' /></Tooltip>
                                                  </Box>
                                                  <Box className="itemssecttag">{value.Attr.Faretype}</Box>
                                                  <Box className="listitemsicon"><Tooltip title={`${props.results[fareValues]['SegmentDetails'][0][0].AvailableSeats} seats left at this price`} arrow placement="top"><span className="available-seats">{props.results[fareValues]['SegmentDetails'].AvailableSeats} seats</span></Tooltip></Box>

                                                </Box>
                                                    
                                                {/* {props.showNetFare && (
                                                  <Box className='pricewidth'>
                                                    <HtmlTooltip
                                                      title={
                                                        <Box>
                                                          Commision: {value.FareDetails.b2b_PriceDetails._Commission} <br />
                                                          Markup: {value.FareDetails.b2b_PriceDetails._Markup}
                                                        </Box>
                                                      } arrow placement="right">
                                                      <Box className="shownetrd"><span>	&#x20B9; </span> {Math.ceil(value.FareDetails.b2b_PriceDetails._AgentBuying)}</Box>
                                                    </HtmlTooltip>
                                                  </Box>
                                                )}
                                                
                                                  <ul className="roundlisticon">
                                                    <li className="listitemsicon">
                                                      <HtmlTooltip title={
                                                        <React.Fragment>
                                                          Check-In Baggage {value.SegmentDetails[0][0].Baggage} <br />
                                                          Cabin Baggage {value.SegmentDetails[0][0].CabinBaggage}
                                                        </React.Fragment>
                                                      } arrow placement="top">
                                                        <img src={luggage}></img>
                                                      </HtmlTooltip>
                                                    </li>

                                                    {value.SegmentDetails[0][0].IsRefundable
                                                      ? (
                                                        <li className="listitemsicon"><Tooltip title="Refundable" arrow placement="top"><img src={refund}></img></Tooltip></li>
                                                      )
                                                      : (
                                                        <li className="listitemsicon"><Tooltip title="Non-Refundable" arrow placement="top"><img src={nonrefund}></img></Tooltip></li>
                                                      )}

                                                    <li className="listitemsicon"><Tooltip title={`Available seats ${value.SegmentDetails[0][0].AvailableSeats}`} arrow placement="top"><img src={seat}></img></Tooltip></li>

                                                  </ul> */}
                                                </Box>
                                              </Box>

                                          {/* <Box className="round-radio-fieldwith-iconlist">
                                            <li className="listitemsicon"><Tooltip title={`${value.SegmentDetails[0][0].AvailableSeats} seats left at this price`} arrow placement="top"><span className="available-seats">{value.SegmentDetails[0][0].AvailableSeats} seats available</span></Tooltip></li>
                                          </Box> */}
                                        </li>
                                    </ul>
                                  </Box>
                                </Box>
                              </Col>
                              <Col xs={4} md={4} className="m-auto zerogap">
                                <Button className="selectbtnOutline" variant="contained" color="primary"
                                  onClick={() => selectFlight(fareDetails, props.keyValue, Number(index+1))}
                                >Select</Button>
                              </Col>
                            </Row>
                          </li>
                        ))}

                      </ul>
                      </Box>


                    )}

                  </Col>
                </Row>
              </Box>
            </Box>
            {/* end roundtrip flight details tabs */}
          </Box>
    </>
  )
}

export default RoundTripResults
