import { getAuth } from '../../../utility/apiConfig'
import { API_BASE_URL } from '../../../utility/apiConstants'

export const USER_DETAILS = 'USER_DETAILS'
export const LOADERS = 'LOADERS'
export const ERRORS = 'ERROR'

export const loaders = () => {
  return {
    type: LOADERS
  }
}

export const errorReq = (error) => {
  return {
    type: ERRORS,
    payload: error
  }
}

export const userDetails = (data) => {
  return {
    type: USER_DETAILS,
    payload: data
  }
}

export const getUserAccountDetails = () => {
  return (dispatch) => {
    try {
      dispatch(loaders())
      getAuth(API_BASE_URL + 'user/account', null)
        .then((response) => {
          if (response.data.status) {
            dispatch(userDetails(response.data.data))
          } else {
            dispatch(errorReq(null))
          }
        }).catch((error) => {
          dispatch(errorReq(error.response))
        })
    } catch (error) {
      dispatch(errorReq(error))
    }
  }
}
