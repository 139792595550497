import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import './login.css'
import { emailValidator, fnameVali, lnameVali, midNameVali, phVali } from './validation';
import { FormHelperText } from '@mui/material';
import { countrylist } from '../../services/countryList';
import { getReq, post } from '../../utility/apiConfig';
import { API_BASE_URL } from '../../utility/apiConstants';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import Autocomplete from '@mui/material/Autocomplete';

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Registration() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [userDetails, setUserDetails] = useState({
        title: "", firstName: "", middleName: "", lastName: "",
        email: "", countryCode: "", state: "", phone: "",
        designation: "", city: "", country: "", countryOrigin: "", numOfPax: ""
    });
    const [userDetailsErr, setUserDetailsErr] = useState({
        title: null, firstName: null, middleName: null, lastName: null,
        email: null, countryCode: null, phone: null,
        designation: null, city: null, country: null, state: null,
        numOfPax: null
    });

    useEffect(() => {
        getStateList();
    }, [])

    useEffect(() => {
        let scripts = 'https://unpkg.com/libphonenumber-js/bundle/libphonenumber-js.min.js'
        var script = document.createElement("script");
        script.src = scripts
        script.async = true;
        document.body.appendChild(script);
    }, [])

    const handleTitleChange = (e) => {
        setUserDetails({ ...userDetails, title: e.target.value });
        setUserDetailsErr({ ...userDetailsErr, title: null });
    };

    const getStateList = () => {
        getReq(API_BASE_URL + 'data/india_state', null)
            .then((response) => {
                setStates(response.data.data.data);
                let st = response.data.data.data;
                st.find((state) => {
                    //  //console.log'selectedValue====>',state);
                    if (state.origin === userDetails.state) {
                        setUserDetails({ ...userDetails, state: state });
                        setUserDetailsErr({ ...userDetailsErr, state: null });
                    }
                })
            }).catch((error) => {
                setStates([])
            })
    }

    const handleChange = (e) => {
        //setAge(event.target.value);
        setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
        setUserDetailsErr({ ...userDetailsErr, [e.target.name]: null });
    }

    const handleCountryChange = (e) => {
        // console.log(e.target.value.split("+"))
        let country = e.target.value.split("+")[0];
        let country_code = "+" + e.target.value.split("+")[1];
        let country_origin = e.target.value.split("+")[2];
        // console.log(country + " " + country_code);
        setUserDetails({ ...userDetails, country: e.target.value, countryCode: country_code, countryOrigin: country_origin });
        setUserDetailsErr({ ...userDetailsErr, country: null, countryCode: null });
        getCityList(e.target.value.split("+")[1]);
    }

    const getCityList = (countryId) => {
        try {
            getReq(API_BASE_URL + 'data/get_cities/' + countryId, null)
                .then((response) => {
                    setCities(response.data.data.data);
                    // //console.logresponse.data.data.data);
                    // let ci = response.data.data.data;
                    // ci.find((city) => {
                    //     //  //console.log'selectedValue====>',city);
                    //     if (city.origin === registers.city) {
                    //         setCityValue(city);
                    //     }
                    // })

                }).catch((error) => {
                    setCities([])
                })
        } catch (error) {

        }
    }

    const handlePhoneChange = (e) => {
        setUserDetailsErr({ ...userDetailsErr, phone: null });
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        if (onlyNums.length < 10) {
            setUserDetails({ ...userDetails, phone: onlyNums })
        } else if (onlyNums.length === 10) {
            const number = onlyNums;
            setUserDetails({ ...userDetails, phone: number })
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get('email'),
            password: data.get('password'),
        });
    };

    function isValidNumber(number) {
        return new window.libphonenumber.parsePhoneNumber(number).isValid()
    }

    const checkValidation = () => {
        const { title, firstName, middleName, lastName, email, countryCode, phone, designation, city, state, country, numOfPax } = userDetails
        var number = phone;
        var country_code = countryCode;
        var phoneNumber = country_code + " " + number;

        let titleErr = title === "" ? "Please select title" : null;
        let firstNameErr = fnameVali(firstName);
        let midNameErr = middleName === "" ? null : midNameVali(middleName);
        let lastNameErr = lnameVali(lastName);
        let emailErr = emailValidator(email);
        let phoneErr = phone === "" ? "Please enter phone number" : phoneNumber.length <= 6 ? "Too short" : isValidNumber(phoneNumber) ? null : "Please enter a valid phone number.";;
        let designationErr = designation === "" ? "Please enter designation" : null;
        let cityErr = city === "" ? "Please select city" : null;
        let countryErr = country === "" ? "Please select country" : null;
        let stateErr = (country === "India" && state === "") ? "Please select state" : null;
        let numOfPaxErr = numOfPax === "" ? "Please enter number of passengers" : null;

        if (titleErr || firstNameErr || midNameErr || lastNameErr || emailErr || designationErr || cityErr || stateErr || countryErr || numOfPaxErr) {
            setUserDetailsErr({
                ...userDetailsErr,
                title: titleErr,
                firstName: firstNameErr,
                middleName: midNameErr,
                lastName: lastNameErr,
                email: emailErr,
                phone: phoneErr,
                designation: designationErr,
                city: cityErr,
                state: stateErr,
                country: countryErr,
                numOfPax: numOfPaxErr
            })
            return;
        }
        else {
            // console.log("userDetails", userDetails)
            handleSignUp()
        }
    }

    const handleSignUp = () => {
        setLoading(true);
        const { title, firstName, middleName, lastName, email, countryCode, state, phone, designation, city, country, countryOrigin, numOfPax } = userDetails
        let dataToSend = {
            title: title,
            first_name: firstName,
            middle_name: middleName,
            last_name: lastName,
            email: email,
            state: state,
            phone: phone,
            designation: designation,
            city: city,
            country: countryOrigin,
            no_of_traveler: numOfPax
        }
       // console.log(dataToSend)

        try {
            post(API_BASE_URL + 'login/agentRegister', dataToSend)
                .then((response) => {
                    //  //console.log"response=======>",response.data);
                    if (response.data.status) {
                        alert(response.data.message)
                        setLoading(false);
                        navigate("/login")
                    } else {
                        alert(response.data.message)
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    alert('Somthing went wrong please try again')
                    setLoading(false);
                })
        } catch (error) {

        }
    }

    return (
        <>
            <Header />
            <ThemeProvider theme={defaultTheme}>
                <div className='user-container reg-container'>
                    <Container maxWidth="sm">
                        <CssBaseline />
                        <Grid container>
                            <Grid item xs={12} md={6} className='m-auto'>
                                <Box
                                    className="login-outer-box"
                                    sx={{
                                        marginTop: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                        <LockOutlinedIcon />
                                    </Avatar>
                                    <Typography component="h1" variant="h5">
                                        Sign up
                                    </Typography>
                                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={2}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Title</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={userDetails.title}
                                                        label="Title *"
                                                        onChange={(handleTitleChange)}
                                                        error={userDetailsErr.title}
                                                    >
                                                        <MenuItem key={1} value={'mr'}>Mr</MenuItem>
                                                        <MenuItem key={2} value={'ms'}>Ms</MenuItem>
                                                        <MenuItem key={3} value={'mrs'}>Mrs</MenuItem>

                                                    </Select>
                                                    {userDetailsErr.title !== null && (<FormHelperText style={{ color: "#d32f2f" }}>{userDetailsErr.title}</FormHelperText>)}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <TextField
                                                    autoComplete="given-name"
                                                    name="firstName"
                                                    required
                                                    fullWidth
                                                    id="firstName"
                                                    label="First Name"
                                                    autoFocus
                                                    value={userDetails.firstName}
                                                    onChange={handleChange}
                                                    error={userDetailsErr.firstName}
                                                    helperText={userDetailsErr.firstName}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <TextField
                                                    // required
                                                    fullWidth
                                                    id="middleName"
                                                    label="Middle Name"
                                                    name="middleName"
                                                    autoComplete="family-name"
                                                    value={userDetails.middleName}
                                                    onChange={handleChange}
                                                    error={userDetailsErr.middleName}
                                                    helperText={userDetailsErr.middleName}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="lastName"
                                                    label="Last Name"
                                                    name="lastName"
                                                    autoComplete="lastname"
                                                    value={userDetails.lastName}
                                                    onChange={handleChange}
                                                    error={userDetailsErr.lastName}
                                                    helperText={userDetailsErr.lastName}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="email"
                                                    label="Email Address"
                                                    name="email"
                                                    autoComplete="email"
                                                    value={userDetails.email}
                                                    onChange={handleChange}
                                                    error={userDetailsErr.email}
                                                    helperText={userDetailsErr.email}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={countrylist}
                                                        getOptionLabel={(option) => option.name}
                                                        value={countrylist.filter(x => x.name === userDetails.country)[0]}
                                                        renderInput={(params) => <TextField  placeholder='Select Country *' {...params} error={userDetailsErr.country} />}
                                                        onChange={(event, newValue) => {
                                                            if (newValue !== null) {
                                                                setUserDetails({ ...userDetails, country: newValue.name, countryCode: newValue.country_code, countryOrigin: newValue.origin });
                                                                setUserDetailsErr({ ...userDetailsErr, country: null, countryCode: null });
                                                                getCityList(newValue.origin);
                                                            }
                                                        }}
                                                    />
                                                    {userDetailsErr.country !== null && (<FormHelperText style={{ color: "#d32f2f" }}>{userDetailsErr.country}</FormHelperText>)}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="countryCode"
                                                    label="Code"
                                                    name="countryCode"
                                                    autoComplete="countryCode"
                                                    value={userDetails.countryCode}
                                                    //onChange={handlePhoneChange}
                                                    // error={userDetailsErr.phone}
                                                    // helperText={userDetailsErr.phone}
                                                    inputProps={{ readOnly: true }}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="conatct"
                                                    label="Contact Number"
                                                    name="phone"
                                                    autoComplete="contactNumber"
                                                    value={userDetails.phone}
                                                    onChange={handlePhoneChange}
                                                    error={userDetailsErr.phone}
                                                    helperText={userDetailsErr.phone}
                                                />
                                            </Grid>


                                            {userDetails.country === "India" && (
                                                <Grid item xs={6}>
                                                    <FormControl fullWidth>
                                                        <Autocomplete
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            options={states}
                                                            getOptionLabel={(option) => option.en_name}
                                                            value={states?.filter(x => x.origin === userDetails.state)[0]}
                                                            renderInput={(params) => <TextField  placeholder='Select State *' {...params} error={userDetailsErr.state} />}
                                                            onChange={(event, newValue) => {
                                                                if (newValue !== null) {
                                                                    setUserDetails({ ...userDetails, state: newValue.origin });
                                                                    setUserDetailsErr({ ...userDetailsErr, state: null });
                                                                }
                                                            }}
                                                        />
                                                        {userDetailsErr.state !== null && (<FormHelperText style={{ color: "#d32f2f" }}>{userDetailsErr.state}</FormHelperText>)}
                                                    </FormControl>
                                                </Grid>
                                            )}
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={cities}
                                                        getOptionLabel={(option) => option.destination}
                                                        value={cities.filter(x => x.origin === userDetails.city)[0]}
                                                        renderInput={(params) => <TextField  placeholder='Select City *' {...params} error={userDetailsErr.city} />}
                                                        onChange={(event, newValue) => {
                                                            if (newValue !== null) {
                                                                setUserDetails({ ...userDetails, city: newValue.origin });
                                                                setUserDetailsErr({ ...userDetailsErr, city: null });
                                                            }
                                                        }}
                                                    />
                                                    {userDetailsErr.city !== null && (<FormHelperText style={{ color: "#d32f2f" }}>{userDetailsErr.city}</FormHelperText>)}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    name="designation"
                                                    label="Designation"
                                                    id="designation"
                                                    autoComplete="designation"
                                                    value={userDetails.designation}
                                                    onChange={handleChange}
                                                    error={userDetailsErr.designation}
                                                    helperText={userDetailsErr.designation}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    type='number'
                                                    id="noTraveller"
                                                    label="Number of Traveller"
                                                    name="numOfPax"
                                                    autoComplete="noTraveller"
                                                    value={userDetails.numOfPax}
                                                    onChange={handleChange}
                                                    error={userDetailsErr.numOfPax}
                                                    helperText={userDetailsErr.numOfPax}
                                                />
                                            </Grid>

                                            <Grid item xs={12} className='text-right'>
                                                <Button
                                                    fullWidth
                                                    type="submit"
                                                    variant="contained"
                                                    className='btnSignUp'
                                                    // color='success'
                                                    sx={{ mb: 2 }}
                                                    onClick={() => checkValidation()}
                                                >
                                                    {loading ? <CircularProgress /> : 'Sign Up'}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid container className='text-center'>
                                            <Grid item className='signin-footer'>
                                                <Link href="#" variant="body2" >
                                                    Already have an account?<span className='text-danger' onClick={() => navigate("/login")}> Sign in</span>
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </ThemeProvider>
            <Footer />
        </>
    );
}