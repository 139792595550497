import { FORGOT_LOADER, FORGOT_SUCCESS, FORGOT_ERROR, FORGOT_STATUS } from '../actions/forgotAction'

const initialForgotState = {
  isLoading: false,
  successData: null,
  error: '',
  status: false
}

const forgotReducers = (state = initialForgotState, { type, payload }) => {
  
  switch (type) {
    case FORGOT_LOADER:
      return {
        ...state,
        isLoading: true
      }
    case FORGOT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        status: false,
        successData: payload
      }
    case FORGOT_ERROR:
      return {
        ...state,
        isLoading: false,
        status: true,
        error: payload

      }
    case FORGOT_STATUS:
      return {
        ...state,
        isLoading: false,
        status: payload
      }

    default:
      return state
  }
}

export default forgotReducers
