import { Box, Button, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const Fareidentifier = (props) => {
  const { handleChange, isSelected, filterType, filterTitle } = props

  //const fareIdentifire = useSelector(state => state.filters.fareIdentifire)

  const result = useSelector(state => state.filters)

  const [fareIdentifire, setFareIdentifire] = useState("")
  
  const onChangeIdentifire = (data, isChecked) => {
    handleChange(data, isChecked)
  }

  useEffect(() => {
    if(filterType === 'fareIdentifier'){
      setFareIdentifire(result.fareIdentifire)
    }else if( filterType === 'bookingSources')
    {
      setFareIdentifire(result.bookingSourc)
    }
  }, [props])

  return (
        <div className="fare-identifier">
            <Box className="tlt-heading">{filterTitle}</Box>
            <Box className="colorBox">

                {!!fareIdentifire && fareIdentifire.length > 0 && (
                  fareIdentifire.map((v, i) => (
                        <>
                            <Tooltip title={`${v.name}`} arrow placement="top" key={i}>
                                <Button className={`fare-color ${v.color} ${isSelected && isSelected.length > 0 && isSelected.includes(v.name) ? 'activecolor' : ''} `} onClick={() => onChangeIdentifire(`${v.name}`, `${v.isChecked}`)}>
                                    <div className="tlt-num">{v.name}</div>
                                </Button>
                            </Tooltip>
                        </>
                  ))
                )}
            </Box>
        </div>
  )
}

export default Fareidentifier
