import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import React, { useEffect, useState, useRef } from 'react'
import { Box, CircularProgress } from '@mui/material'
import { useLocation } from 'react-router-dom'
import withStyles from '@mui/styles/withStyles';
import Tooltip from '@mui/material/Tooltip'
// import "../calendar/css-sprites1.css";
import { authPost } from '../../utility/apiConfig'
import { API_BASE_URL } from '../../utility/apiConstants'
import '../../assets/airlines/airlineLogoSprites.css'

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}))(Tooltip)

const Calendar = () => {
  const location = useLocation()
  const [fareCalendar, setFareCalendar] = useState(null)
  const [reqObj, setReqObj] = useState(null)
  const calendarRef = useRef()
  const [disableDateClick, setDisableDateClick] = useState(false)
  const [currentMonth, setCurrentMonth] = useState('')
  const [loading, setloading] = useState(true)

  const getFareCalendarReq = (data) => {
    try {
      authPost(API_BASE_URL + 'ajax/fare_list/PTBSID0000000002', data)
        .then((response) => {
          if (response.data.status) {
            setloading(false)
            setFareCalendar(response.data)
            const calendarApi = calendarRef.current.getApi()
            calendarApi.gotoDate(response.data.data.data.departure)
          }else{
            setloading(false)
          }
        })
        .catch((error) => {
        })
    } catch (error) {

    }
  }

  useEffect(() => {
    const date = new Date()
    setCurrentMonth(date.getMonth() + 1)
    getFareCalendarReq(location.state)
    setReqObj(location.state)
  }, [])

  const handleCalendarChange = (type) => {
    const calendarApi = calendarRef.current.getApi()
    const date = calendarApi.getDate()
    const obj = { ...reqObj }

    if (type === 'next') {
      const result = formatDate(date, 'next')
      obj.depature = result
      getFareCalendarReq(obj)
      calendarApi.next()
    } else if (type === 'prev') {
      const result = formatDate(date, 'prev')
      obj.depature = result
      getFareCalendarReq(obj)
      calendarApi.prev()
    } else if (type === 'today') {
      const result = formatDate(new Date(), 'today')
      obj.depature = result
      getFareCalendarReq(obj)
      calendarApi.today()
    }
  }

  const formatDate = (date, type) => {
    let day, month, year, result
    day = date.getDate()
    if (type === 'next') {
      if ((date.getMonth() + 1) + 1 > 12) {
        month = '01'
        year = date.getFullYear() + 1
      } else {
        month = (date.getMonth() + 1) + 1
        year = date.getFullYear()
      }
    } else if (type === 'prev') {
      if ((date.getMonth() + 1) - 1 <= 0) {
        month = '12'
        year = date.getFullYear() - 1
      } else {
        month = (date.getMonth() + 1) - 1
        year = date.getFullYear()
      }
    } else if (type === 'today') {
      month = (date.getMonth() + 1)
      year = date.getFullYear()
    }
    setDisableDateClick(currentMonth === month)
    if (month.toString().length < 2) {
      month = '0' + month
    }
    if (day.toString().length < 2) {
      day = '0' + day
    }
    return result = day + '-' + month + '-' + year
  }

  const renderEvents = ({ event, el }) => {
    let time
    time = new Date(event._def.extendedProps.event_date)
    let hours = time.getHours()
    hours = ('0' + hours).slice(-2)
    let minutes = time.getMinutes()
    minutes = ('0' + minutes).slice(-2)

    return (
      <>
        <HtmlTooltip
          title={
            <Box className="tooltiprow">
              <Box className="tooltipdtlsim">
                <label> Fare :{event._def.title}</label>
              </Box>
              <Box className="tooltipdtls">
                <label>Departing time : {hours + ':' + minutes}</label>
              </Box>
            </Box>
          }
        >
          <Box className="daterate">
            <Box
              className={'tableico itwgo-60x35_' + event._def.extendedProps.data_id}
            ></Box>
            <label className>{event._def.title}</label>
          </Box>
        </HtmlTooltip>
      </>
    )
  }

  return (
    <div>
        <Box className="calendarBox">
        <FullCalendar
          customButtons={{
            prevButton: {
              text: 'Prev',
              click: () => !disableDateClick ? handleCalendarChange('prev') : null
            },
            nextButton: {
              text: 'Next',
              click: () => handleCalendarChange('next')
            },
            todayButton: {
              text: 'Today',
              click: () => handleCalendarChange('today')
            }
          }}
          headerToolbar={{
            left: 'title',
            right: 'todayButton prevButton nextButton'
          }}
          id="calendar"
          ref={calendarRef}
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          events={!!fareCalendar && fareCalendar.data.data.day_fare_list}
          eventContent={renderEvents}
        />
      </Box>
        {/* <Box className="calendarBoxProcess d-flex justify-content-center align-items-center">
            <span className="calendar-loading">Loading calendar... Please wait...</span><CircularProgress size={30} color="inherit" />
        </Box>  */}
    </div>
  )
}

export default Calendar
