export const PRICE_RANGE = 'PRICE_RANGE'
export const SHOW_NET = 'SHOW_NET'
export const STOPS = 'STOPS'
export const DEPATURE = 'DEPATURE'
export const ARRIVAL = 'ARRIVAL'
export const FARE_IDENTIFIRE = 'FARE_IDENTIFIRE'
export const AIRLINE = 'AIRLINE'
export const FLIGHT_DETA = 'FLIGHT_DETA'
export const AIRLINE_RESET = 'AIRLINE_RESET'
export const BOOKING_SOURCE = 'BOOKING_SOURCE'

export const priceRange = (range) => {
  return {
    type: PRICE_RANGE,
    payload: range,
    flightData: null
  }
}

export const showNet = (data) => {
  return {
    type: SHOW_NET,
    payload: data
    // flightData:data
  }
}

export const stops = (stops) => {
  return {
    type: STOPS,
    payload: stops
    // flightData:data
  }
}

export const depatureTime = (time) => {
  return {
    type: DEPATURE,
    payload: time
    // flightData:data
  }
}

export const arrivalTime = (time) => {
  return {
    type: ARRIVAL,
    payload: time
    // flightData:data
  }
}

export const fareIndentifire = (fare) => {
  return {
    type: FARE_IDENTIFIRE,
    payload: fare
    // flightData:data
  }
}

export const bookingSourcesMap = (bs) => {
  return {
    type: BOOKING_SOURCE,
    payload: bs
    // flightData:data
  }
}

export const airlineCheck = (airline) => {
  return {
    type: AIRLINE,
    payload: airline
    // flightData:data
  }
}

export const flightDataOriginal = (data) => {
  return {
    type: FLIGHT_DETA,
    payload: null,
    flightData: data
  }
}

export const airlineReset = () => {
  return {
    type: AIRLINE_RESET
  }
}
