
import { USER_DETAILS, ERRORS, LOADERS } from '../actions/userAcountActions'

const initialUserState = {
  isLoading: false,
  userDetails: null,
  error: ''
}

const userAccReducer = (state = initialUserState, { type, payload }) => {

  switch (type) {
    case LOADERS:
      return {
        ...state,
        isLoading: true
      }

    case USER_DETAILS:
      return {
        ...state,
        isLoading: false,
        userDetails: payload
      }
    case ERRORS:
      return {
        ...state,
        isLoading: false,
        error: payload
      }
    default:
      return state
  }
}

export default userAccReducer
