import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { Box, Container, TextField, Stack, MenuItem, InputLabel, FormControl,Select, Button, Typography, Divider, FormHelperText, Autocomplete } from '@mui/material'
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import SideNav from '../../SideNav'
import MainFooter from '../footer/MainFooter'
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { authPost, getAuth } from '../../utility/apiConfig';
import { API_BASE_URL } from '../../utility/apiConstants';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AddFlight from './flight/AddFlight';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import GamesIcon from '@mui/icons-material/Games';

const steps = ['Match', 'Hotel', 'Flight', 'Transfers']

const TravelerItinerary = () => {
    const currentURL = window.location.href;
    var itinerary_id = currentURL.split("?")[1]
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const [userList, setUserList] = useState([]);
    const [selectedTravelers, setSelectedTravelers] = useState([]);
    const [selectedTravelersErr, setSelectedTravelersErr] = useState(null);
    const [matchList, setMatchList] = useState([]);
    const [hotelList, setHotelList] = useState([]);
    const [itineraryId, setItineraryId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [selectedMatch, setSelectedMatch] = useState(null);
    const [selectedHotel, setSelectedHotel] = useState(null);
    const [checkIndate, setCheckIndate] = useState(null);
    const [checkOutdate, setCheckOutdate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [onewayFlight, setOnewayFlight] = useState(null);
    const [onwardFlight, setOnwardFlight] = useState(null);
    const [returnFlight, setReturnFlight] = useState(null);
    const [selectedRoomTravelers, setSelectedRoomTravelers] = useState([]);
    const [roomTravelerDetails, setRoomTravelerDetails] = useState([]);
    const [limit, setLimit] = useState(0);
    const [includeTransfer, setIncludeTransfer] = useState("Not included");
    
    useEffect(() => {
        getUserList();
        getMatchList();
        getHotelList();
        if(itinerary_id !== undefined){
            getItineraryDetails();
            setItineraryId(itinerary_id);
        }
    }, [])
//console.log("onwardFlight", onwardFlight)
    const getItineraryDetails = () => {
        setIsLoading(true);
        try {
            getAuth(API_BASE_URL + `itinerary/fetch_itinerary?user_id=&activity_id=&itinerary_id=${itinerary_id}`)
                .then((response) => {
                    if (response.data && response.data.data && response.status) {
                      //  setItineraryList(response.data.data.data);
                      setSelectedTravelers(response.data.data.data[0]?.itinerary_passenger_details);
                      if(response.data.data.data[0]?.itinerary_activity_details?.length > 0){
                        const newCompleted = completed;
                        newCompleted[0] = true;
                        setCompleted(newCompleted);
                      }
                      setSelectedMatch(response.data.data.data[0]?.itinerary_activity_details?.length > 0 ? response.data.data.data[0]?.itinerary_activity_details[0] : null);
                      setSelectedHotel(response.data.data.data[0]?.itinerary_hotel_details?.length > 0 ? response.data.data.data[0]?.itinerary_hotel_details[0] : null);
                      setCheckIndate(response.data.data.data[0]?.itinerary_hotel_details?.length > 0 ? response.data.data.data[0]?.itinerary_hotel_details[0].checkin_date === "0000-00-00 00:00:00" ? null : dayjs(response.data.data.data[0]?.itinerary_hotel_details[0].checkin_date) : null);
                      setCheckOutdate(response.data.data.data[0]?.itinerary_hotel_details?.length > 0 ? response.data.data.data[0]?.itinerary_hotel_details[0].checkout_date === "0000-00-00 00:00:00" ? null : dayjs(response.data.data.data[0]?.itinerary_hotel_details[0].checkout_date) : null); 
                      setIsLoading(false);
                      if(response.data.data.data[0]?.itinerary_flight_details?.length > 1){
                        setOnwardFlight(response.data.data.data[0]?.itinerary_flight_details[0][0]);
                        setReturnFlight(response.data.data.data[0]?.itinerary_flight_details[1][0]);
                        setOnewayFlight(null);
                        const newCompleted = completed;
                        newCompleted[2] = true;
                        setCompleted(newCompleted);
                      }
                      if(response.data.data.data[0]?.itinerary_flight_details?.length === 1){
                        setOnewayFlight(response.data.data.data[0]?.itinerary_flight_details[0][0]);
                        setOnwardFlight(null);
                        setReturnFlight(null);
                        const newCompleted = completed;
                        newCompleted[2] = true;
                        setCompleted(newCompleted);
                      }
                      let prevHotel = response.data.data.data[0]?.itinerary_hotel_details?.length > 0 ? response.data.data.data[0]?.itinerary_hotel_details[0] : null;
                      let prevTravelers = response.data.data.data[0]?.itinerary_passenger_details;
                      if(prevHotel !== null){
                      let arr = [...Array(Math.ceil(prevTravelers?.length / Number(prevHotel.occupancy === "triple" ? 3 :
                                        prevHotel.occupancy === "double" ? 2 : 1)))].map((x, i) => {
                                                return ({"id": i, travelers: prevTravelers, selectedTravelers: []})
                                  });
                        setLimit(prevHotel.occupancy === "triple" ? 3 : prevHotel.occupancy === "double" ? 2 : prevHotel.occupancy === "single" ? 1 : 0);
                        setRoomTravelerDetails(arr);
                        const newCompleted = completed;
                        newCompleted[1] = true;
                        setCompleted(newCompleted);
                        }
                        let transferDetails = response.data.data.data[0]?.itinerary_transfers_details;
                        setIncludeTransfer(transferDetails?.length > 0 ? transferDetails[0]?.included === "0" ? "Not included" : "Included" : "Not included");
                        if(transferDetails?.length > 0 && transferDetails[0]?.included === "1"){
                            const newCompleted = completed;
                            newCompleted[3] = true;
                            setCompleted(newCompleted); 
                        }
                    }
                    else {
                        setIsLoading(false);
                      //  setItineraryList([]);
                    }
                }).catch((error) => {
                    alert(error.message);
                    setIsLoading(false);
                  //  setItineraryList([]);
                })
        } catch (error) {
            setIsLoading(false);
        }
    }

    const getUserList = (pageNo) => {
        setIsLoading(true);
        try {
            getAuth(API_BASE_URL + `user/list_of_users/0/0?first_name=&email=&phone=&country_code=&city=&designation=&status=&last_name=&user_id=`)
                .then((response) => {
                    if (response.data && response.data.data && response.status) {
                        setUserList(response.data.data.data);
                        setIsLoading(false);
                    }
                    else {
                        setIsLoading(false);
                        setUserList([]);
                    }
                }).catch((error) => {
                    alert(error.message);
                    setIsLoading(false);
                    setUserList([]);
                })
        } catch (error) {
            setIsLoading(false);
        }
    }

    const getMatchList = (pageNo) => {
        setIsLoading(true);
        try {
            getAuth(API_BASE_URL + `data/get_match_list`)
                .then((response) => {
                    if (response.data && response.data.data && response.status) {
                        setMatchList(response.data.data);
                        setIsLoading(false);
                    }
                    else {
                        setIsLoading(false);
                        setMatchList([]);
                    }
                }).catch((error) => {
                    alert(error.message);
                    setIsLoading(false);
                    setMatchList([]);
                })
        } catch (error) {
            setIsLoading(false);
        }
    }

    const getHotelList = () => {
        setIsLoading(true);
        try {
            getAuth(API_BASE_URL + `hotel/hotel_inventory/0/0?detailed=1&name=&country=&city=&origin=`)
                .then((response) => {
                    if (response.data && response.data.data && response.status) {
                        setHotelList(response.data.data.data);
                        setIsLoading(false);
                    }
                    else{
                        setIsLoading(false); 
                        setHotelList([]);
                    }
                }).catch((error) => {
                    alert(error.message);
                    setIsLoading(false);
                    setHotelList([]);
                })
        } catch (error) {
            setIsLoading(false);
        }
    }

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
    return Object.keys(completed).length;
    };

    const isLastStep = () => {
    return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        if (selectedTravelers.length === 0) {
            setSelectedTravelersErr("Please select travellers");
            return
        }
        else {
            setSelectedTravelersErr(null);
            const newActiveStep =
                isLastStep() && !allStepsCompleted()
                    ? // It's the last step, but not all steps have been completed,
                    // find the first step that has been completed
                    steps.findIndex((step, i) => !(i in completed))
                    : activeStep + 1;
            setActiveStep(newActiveStep);
        }
    };

    const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
    setActiveStep(step);
    };

    const handleComplete = () => {
        if (selectedTravelers.length === 0) {
            setSelectedTravelersErr("Please select travellers");
            return
        }
        else if(activeStep === 0 && selectedMatch === null && selectedHotel === null){
            alert("Itinerary can not be empty");
            return
        }
        else if(activeStep === 1 && selectedMatch === null && selectedHotel === null){
            alert("Itinerary can not be empty");
            return
        }
        else if(checkOutdate !== null && checkIndate === null){
            alert("Please select checkin date");
            return
        }
        else if(checkOutdate < checkIndate){
            alert("checkout date can not be lesser than chekin date");
            return
        }
        else {
            setSelectedTravelersErr(null);
            const newCompleted = completed;
            newCompleted[activeStep] = true;
            setCompleted(newCompleted);
            handleNext();
            handleSubmit();
        }
    };

    const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
    };

    const handleSubmit = () => {
        let dataToSend = {
            pax_origin: selectedTravelers.map(x=> x?.user_id),
            match_origin: selectedMatch?.origin,
            hotel_details: {origin: selectedHotel?.origin !== undefined ? selectedHotel?.origin : "",
                            inventory_id: selectedHotel?.inventory_id !== undefined ? selectedHotel?.inventory_id : "",
                            checkin_date: checkIndate !== null ? formatDate(checkIndate) : "",
                            checkout_date: checkOutdate !== null ? formatDate(checkOutdate) : "",
                            room_details: roomTravelerDetails?.map(x=> x?.selectedTravelers.map(y=> y?.user_id))
            },
            flight_details: [],
            transfers: {},
            itinerary_id: itineraryId
        }
        if(onewayFlight !== null){
            dataToSend.flight_details.push(onewayFlight)
        }
        else if(onewayFlight === null && onwardFlight !== null && returnFlight !== null){
            dataToSend.flight_details.push(onwardFlight)
            dataToSend.flight_details.push(returnFlight)
        }
       // console.log("Data to send", dataToSend);
        try {
            authPost(API_BASE_URL + 'itinerary/create_itinerary', dataToSend)
                .then((response) => {
                    if (response.data.status) {
                        alert(itinerary_id !== undefined ? "Itinerary updated successfully" : response.data.data.message);
                        setItineraryId(response.data.data.itinerary_id);
                        setLoading(false);
                    } else {
                        alert(response.data.data.message)
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    alert('Somthing went wrong please try again')
                    setLoading(false);
                })
        } catch (error) {

        }
    }

    const handleRoomTravelers = (val, index, option, selection) => {
        let ar1 = [...selectedRoomTravelers];
        if(val?.length > limit){
            alert(`You can select upto ${limit} guest(s) in one room`)
        }
        else{
        if (ar1.filter(x => x?.id === option.id).length > 0 && selection !== "selectOption") {
            //console.log("Step 1")
            var idx = ar1.findIndex(function (o) {
                return o.user_id === option.user_id;
            })
            ar1.splice(idx, 1)
        }
        else if (ar1.filter(x => x?.user_id === option.user_id).length > 0 && selection === "selectOption") {
            //console.log("Step 2")
            ar1.push(option);
        }
        else {
            // console.log("Step 3")
            ar1.push(option)
        }
        setSelectedRoomTravelers(ar1);
        const newArray = [...roomTravelerDetails];
        var uniqueArr = selectedTravelers.filter(function (obj) { return val.indexOf(obj) == -1; });
        newArray[index] = {
            ...newArray[index],
            selectedTravelers: val,
            travelers: newArray[index].travelers
        }
        let arr = newArray.map((y, i) => { return ({ ...y, travelers: i === index ? y.travelers : uniqueArr }) })

        // Call setData to update.
        setRoomTravelerDetails(arr);
    }
    }

    function formatDate(date) {
        let d = new Date(date);
        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        let newDate = `${da}-${mo}-${ye}`
        return newDate;
    }
   // console.log("roomTravelerDetails", roomTravelerDetails)
    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <SideNav header={"Traveler Itinerary"} />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <Container>
                        <Card className='p-3 mt-3 '>
                            <Row className=' mb-3'>
                                <h6>Traveler Itinerary</h6>
                                <Divider />
                            </Row>
                            <Box sx={{ width: '100%' }}>
                                <Stepper nonLinear activeStep={activeStep}>
                                    {steps.map((label, index) => (
                                    <Step key={label} completed={completed[index]}>
                                        <StepButton color="inherit" onClick={handleStep(index)}>
                                        {label}
                                        </StepButton>
                                    </Step>
                                    ))}
                                </Stepper>
                                <div>
                                    {/* {allStepsCompleted() ? (
                                    <React.Fragment>
                                        <Card className='p-3 mb-0'>
                                            <Typography sx={{ mt: 4, mb: 1 }} className='text-success text-center'>
                                                Travel Itinerary has been assigned successfully!
                                            </Typography>
                                        </Card>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        <Button onClick={handleReset}>Reset</Button>
                                        </Box>
                                    </React.Fragment>
                                    ) : ( */}
                                    <React.Fragment>
                                        {activeStep == 0 &&
                                        <Card className='p-5 pt-4 mb-0 setBox'>
                                            <Row className='mb-2'>
                                                <Col xs={12} md={12} className='mb-2'><h6>Match Details</h6><Divider /></Col>
                                            </Row>
                                            <Row className='mb-2'>
                                                <Col xs={12} md={6} className='mb-2'>
                                                    <label className="tlthedae">Select Traveler <span className='text-danger'>*</span></label>
                                                    <Stack>
                                                        <Autocomplete
                                                            multiple
                                                            readOnly
                                                            id="tags-standard"
                                                            options={userList}
                                                            popupIcon={null}
                                                            getOptionLabel={(option) => option.first_name + " " + option.middle_name + " " + option.last_name}
                                                            getOptionSelected={(option, value) => option.id === value.id}
                                                            //defaultValue={[travelerList[0]]}
                                                            className={`${selectedTravelersErr ? 'error-border' : ''}`}
                                                            value={selectedTravelers}
                                                            renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                placeholder="Select Travelers"
                                                            />
                                                            )}
                                                            onChange={(event,newValue)=> {
                                                                if(newValue !== null){
                                                                setSelectedTravelers(newValue);
                                                                setSelectedTravelersErr(null);
                                                                }
                                                            }}
                                                        />
                                                        {selectedTravelersErr !== null && (<FormHelperText style={{ color: "#d32f2f" }}>{selectedTravelersErr}</FormHelperText>)}
                                                        </Stack>
                                                </Col>
                                            </Row>
                                            <Row className='mb-2'>
                                                <Col xs={12} md={6}>
                                                    <label className="tlthedae">Match <span className='text-danger'>*</span></label>
                                                    <Autocomplete
                                                        readOnly
                                                        disablePortal
                                                        id="country-select-demo"
                                                        options={matchList}
                                                        getOptionLabel={(option) => option.teams + ", " + option.venue + " (" + formatDate(option.match_date) + ")"}
                                                        value={selectedMatch}
                                                        popupIcon={null}
                                                        // renderOption={(props, option) => (
                                                        //     <Box component="li"  {...props}>
                                                        //         {option.teams}, {option.venue} ({formatDate(option.match_date)} )
                                                        //     </Box>
                                                        // )}
                                                        renderInput={(params) => (
                                                            <TextField
                                                            required
                                                            {...params}
                                                            placeholder="Choose a match"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                                            }}
                                                            />
                                                        )}
                                                        onChange={(option,item)=> {
                                                            if(item !== null){
                                                            setSelectedMatch(item);
                                                            }
                                                        }}
                                                        />
                                                </Col>
                                            </Row>
                                            <Row>
                                               <Col xs={12} md={6}>
                                                    <label className="td-label">Team <span className='text-danger'>*</span></label>
                                                    <TextField
                                                        readOnly
                                                        autoComplete="given-name"
                                                        name="team"
                                                        required
                                                        fullWidth
                                                        variant='outlined'
                                                        id="team"
                                                        placeholder="Team"
                                                        inputProps={{ readOnly: true }}
                                                        value={selectedMatch?.teams}
                                                    />
                                               </Col>
                                               
                                               <Col xs={12} md={3}>
                                                    <label className="td-label">Match Date <span className='text-danger'>*</span></label>
                                                    <TextField
                                                        readOnly
                                                        autoComplete="given-name"
                                                        name="matchdate"
                                                        required
                                                        fullWidth
                                                        variant='outlined'
                                                        id="autoComplete"
                                                        placeholder="Match date"
                                                        inputProps={{ readOnly: true }}
                                                        value={selectedMatch?.match_date ? formatDate(selectedMatch?.match_date) : ""}
                                                    />
                                               </Col>
                                               <Col xs={12} md={3}>
                                                    <label className="tlthedae">Match City <span className='text-danger'>*</span></label>
                                                    <TextField
                                                        readOnly
                                                        autoComplete="given-name"
                                                        name="matchcity"
                                                        required
                                                        fullWidth
                                                        variant='outlined'
                                                        id="autoComplete"
                                                        placeholder="Match City"
                                                        inputProps={{ readOnly: true }}
                                                        value={selectedMatch?.venue}
                                                    />
                                               </Col>
                                            </Row>
                                        </Card>
                                          
                                        }

                                        {activeStep == 1 &&
                                        <Card className='p-5 pt-4 mb-0 setBox'>
                                            <Card className='p-4 mb-4'>
                                                <Row>
                                                    {selectedTravelers?.length > 0 && (
                                                    <Col xs={12} md={6}>
                                                        <h6 className="mb-2"> Selected Traveler</h6>
                                                        {/* <Divider /> */}
                                                        <p className="traveler-name mb-0"><PermIdentityIcon /> {selectedTravelers?.map(option=> option.first_name + " " + option.middle_name + " " + option.last_name).join(", ")}</p>
                                                    </Col>
                                                    )}
                                                    {selectedMatch !== null && (
                                                    <Col xs={12} md={6}>
                                                         <h6 className="mb-2"> Selected Match</h6>
                                                         {/* <Divider /> */}
                                                         <p className="traveler-name mb-0"><GamesIcon /> {selectedMatch.teams + ", " +selectedMatch.venue + ", " + " (" + formatDate(selectedMatch.match_date) + ")"}</p>
                                                     
                                                     </Col>
                                                    )}
                                                </Row>
                                            </Card>
                                            <Row className='mb-2'>
                                                <Col xs={12} md={12} className='mb-2'><h6>Hotel Details</h6><Divider /></Col>
                                                <Col xs={12} md={6}>
                                                    <label className="tlthedae">Hotel <span className='text-danger'>*</span></label>
                                                    <Autocomplete
                                                        readOnly
                                                        id="country-select-demo"
                                                        options={hotelList}
                                                        getOptionLabel={(option) => option.hotel_name + ", " + option.destination + ", " + option.room_category + ", " + option.occupancy}
                                                        value={selectedHotel}
                                                        popupIcon={null}
                                                        // renderOption={(props, option) => (
                                                        //     <Box component="li"  {...props}>
                                                        //         {option.hotel_name}, {option.destination}, {option.destination}, {option.occupancy} 
                                                        //     </Box>
                                                        // )}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                required
                                                                {...params}
                                                                placeholder="Choose a hotel"
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                                                }}
                                                            />
                                                        )}
                                                        onChange={(option, item) => {
                                                            if (item !== null) {
                                                                setSelectedHotel(item);
                                                                setLimit(item.occupancy === "triple" ? 3 : item.occupancy === "double" ? 2 : 1);
                                                                let arr = [...Array(Math.ceil(selectedTravelers.length / Number(item.occupancy === "triple" ? 3 :
                                                                    item.occupancy === "double" ? 2 : 1)))].map((x, i) => {
                                                                        return ({
                                                                            "id": i, travelers: selectedTravelers, selectedTravelers: []
                                                                        })
                                                                    });
                                                                    setSelectedRoomTravelers([]);
                                                                setRoomTravelerDetails(arr)
                                                            }
                                                        }}
                                                    />
                                               </Col>
                                               <Col xs={12} md={3}>
                                                    <label className="tlthedae">City <span className='text-danger'>*</span></label>
                                                    <TextField
                                                        readOnly
                                                        autoComplete="given-name"
                                                        name="matchcity"
                                                        required
                                                        fullWidth
                                                        variant='outlined'
                                                        id="autoComplete"
                                                        placeholder="City"
                                                        autoFocus
                                                        inputProps={{ readOnly: true }}
                                                        value={selectedHotel?.destination}
                                                    />
                                               </Col>                                              
                                            </Row>
                                            <Row className='mb-2'>
                                                <Col xs={12} md={3}>
                                                    <label className="tlthedae">Room Category <span className='text-danger'>*</span></label>
                                                    <TextField
                                                        readOnly
                                                        autoComplete="given-name"
                                                        name="roomcategory"
                                                        required
                                                        fullWidth
                                                        variant='outlined'
                                                        id="autoComplete"
                                                        placeholder="Room Category"
                                                        autoFocus
                                                        inputProps={{ readOnly: true }}
                                                        value={selectedHotel?.room_category}
                                                    />
                                               </Col>
                                               <Col xs={12} md={3}>
                                                    <label className="tlthedae">Occupancy <span className='text-danger'>*</span></label>
                                                    <TextField
                                                        readOnly
                                                        required
                                                        autoFocus
                                                        autoComplete="given-name"
                                                        name="occupancy"
                                                        fullWidth
                                                        variant='outlined'
                                                        id="autoComplete"
                                                        placeholder="Occupancy"
                                                        inputProps={{ readOnly: true }}
                                                        value={selectedHotel?.occupancy}
                                                    />
                                               </Col>
                                               <Col xs={12} md={3}>
                                                    <label className="tlthedae">Check in</label>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                                                            <DatePicker
                                                            readOnly
                                                            // className={`${passportDetailsErr.passportIssue ? 'error-border': ''}`}
                                                            format='DD-MM-YYYY'
                                                            placeholder="DD-MM-YYYY"
                                                            minDate={dayjs()}
                                                            value={checkIndate !== null ? dayjs(checkIndate) : null}
                                                            onChange={(date) => setCheckIndate(date)}
                                                            />
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                               </Col>
                                               <Col xs={12} md={3}>
                                                    <label className="tlthedae">Check out</label>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                                                            <DatePicker
                                                            readOnly
                                                            // className={`${passportDetailsErr.passportIssue ? 'error-border': ''}`}
                                                            format='DD-MM-YYYY'
                                                            placeholder="DD-MM-YYYY"
                                                            minDate={checkIndate}
                                                            value={checkOutdate !== null ? dayjs(checkOutdate) : null}
                                                            onChange={(date) => setCheckOutdate(date)}
                                                            />
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                               </Col>
                                               
                                            </Row>
                                            {(selectedHotel !== null && roomTravelerDetails?.length > 0) && (
                                            roomTravelerDetails?.map((x,i)=>
                                            <Row >
                                                <Col xs={12} md={6} >
                                                    <label className="tlthedae">Room {i+1}: </label>
                                                    <Stack>
                                                        <Autocomplete
                                                            readOnly
                                                            multiple
                                                            id="tags-standard"
                                                            options={x.travelers.filter(function (obj) { return selectedRoomTravelers.indexOf(obj) == -1; })}
                                                            getOptionLabel={(option) => option.first_name + " " + option.middle_name + " " + option.last_name}
                                                            getOptionSelected={(option, value) => option.id === value.id}
                                                            popupIcon={null}
                                                            //defaultValue={[travelerList[0]]}
                                                           // className={`${selectedTravelersErr ? 'error-border' : ''}`}
                                                            value={x.selectedTravelers}
                                                            renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                placeholder="Select Travelers"
                                                            />
                                                            )}
                                                            onChange={(event,newValue, select, option)=> {
                                                                if(newValue !== null){
                                                              //  console.log(option.option)
                                                              //  setSelectedHotelTravelers(newValue);
                                                              //  setSelectedTravelersErr(null);
                                                              handleRoomTravelers(newValue,i, option.option, select);
                                                                }
                                                            }}
                                                        />
                                                        {/* {selectedTravelersErr !== null && (<FormHelperText style={{ color: "#d32f2f" }}>{selectedTravelersErr}</FormHelperText>)} */}
                                                        </Stack>
                                                </Col>
                                            </Row>
                                            ))}
                                        </Card>
                                        }

                                        {activeStep == 2 &&
                                            <Card className='p-5 pb-0 pt-4 mb-0 setBox'>
                                                <Row >
                                                    <Col xs={12} md={12} ><h6>Flight Details</h6><Divider />
                                                        <AddFlight 
                                                        setOnewayFlight={setOnewayFlight}
                                                        setOnwardFlight={setOnwardFlight}
                                                        setReturnFlight={setReturnFlight}
                                                        onewayFlight={onewayFlight}
                                                        onwardFlight={onwardFlight}
                                                        returnFlight={returnFlight}
                                                        selectedTravelers={selectedTravelers}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Card>
                                        }

                                        {activeStep == 3 &&
                                            <Card className='p-5 pt-4 mb-0 setBox'>
                                                <Row >
                                                    <Col xs={12} md={12} >
                                                        <h6>Transfer Details</h6>
                                                        <Divider />
                                                    </Col>
                                                    <Col xs={12} md={3} className='mt-3'>
                                                        <Box>
                                                            <label className='tlthedae'>Airport transfers : <b className='text-sm'>{includeTransfer}</b></label>
                                                        </Box>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        }       
                                       {activeStep == 2 && (onewayFlight !== null || onwardFlight !== null || returnFlight !== null) &&
                                        <Box className="text-note">
                                            <p ><b>Note :</b> All airfares are subject to availability at time of booking and are not guaranteed until purchased and the e-tickets have been issued.</p>
                                        </Box>
                                        }
                                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Button
                                            
                                            color="primary"
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            sx={{ mr: 1 }}
                                        >
                                            Back
                                        </Button>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        <Button color='primary' variant='contained' onClick={handleNext} sx={{ mr: 1 }}>
                                            Next
                                        </Button>
                                        {/* {activeStep !== steps.length &&
                                            (completed[activeStep] ? (
                                            <Typography className='text-success mt-1 px-2' variant="caption" sx={{ display: 'inline-block' }}>
                                                <b>Already Submitted</b>
                                            </Typography>
                                            ) : ( */}
                                            <Button variant='contained' color='success' size="large" onClick={handleComplete}>
                                                {completedSteps() === totalSteps() - 1
                                                ? 'Finish'
                                                : itinerary_id !== undefined ? 'Update' : 'Submit'}
                                            </Button>
                                            {/* ))} */}
                                        </Box>
                                    </React.Fragment>
                                </div>
                            </Box>
                            
                        </Card>
                    </Container>
                </Box>
            </Box>
            <MainFooter />
        </>
    )
}

export default TravelerItinerary
