import { SSR_LOADING, SSR_ERROR, SSR_REQUEST, SSR_SUCCESS, SSR_ROUND_LOADING, SSR_ROUND_REQUEST, SSR_ROUND_SUCCESS, SSR_ROUND_ERROR, SSR_MULTI_LOADING, SSR_MULTI_REQUEST, SSR_MULTI_SUCCESS, SSR_MULTI_ERROR } from '../actions/flightBookingSSRActions'

const initialState = {

  isLoading: false,
  request: null,
  data: null,
  error: '',

  isRoundLoading: false,
  roundRequest: null,
  roundData: null,
  roundRrror: '',

  isMultiLoading: false,
  multiRequest: null,
  multiData: null,
  multiError: ''

}

const flightBookingSSRReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case SSR_LOADING:
      return {
        ...state,
        isLoading: true
      }

    case SSR_REQUEST:
      return {
        ...state,
        isLoading: false,
        request: payload,
        data: null
      }

    case SSR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload
      }

    case SSR_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload
      }

    case SSR_ROUND_LOADING:
      return {
        ...state,
        isRoundLoading: true
      }

    case SSR_ROUND_REQUEST:
      return {
        ...state,
        isRoundLoading: false,
        roundRequest: payload
      }

    case SSR_ROUND_SUCCESS:
      return {
        ...state,
        isRoundLoading: false,
        roundData: payload
      }

    case SSR_ROUND_ERROR:
      return {
        ...state,
        isRoundLoading: false,
        roundRrror: payload
      }

    case SSR_MULTI_LOADING:
      return {
        ...state,
        isMultiLoading: true
      }

    case SSR_MULTI_REQUEST:
      return {
        ...state,
        isMultiLoading: false,
        multiRequest: payload
      }

    case SSR_MULTI_SUCCESS:
      return {
        ...state,
        isMultiLoading: false,
        multiData: payload
      }

    case SSR_MULTI_ERROR:
      return {
        ...state,
        isMultiLoading: false,
        multiError: payload
      }

    default:
      return state
  }
}

export default flightBookingSSRReducers
