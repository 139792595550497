import React, { useState, useEffect, useCallback } from 'react'
import Grid from '@mui/material/Grid'
import {
  FormControlLabel,
  MenuItem,
  Select
} from '@mui/material'
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Checkbox from '@mui/material/Checkbox'
import './flights.css'
import { getAuth } from '../../../utility/apiConfig'
import { API_BASE_URL } from '../../../utility/apiConstants'
import { stringToDate } from '../../../core/helper'
// import { depVali, fromVali, retVali, toVali } from './flightSearchValidation'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { airports } from '../../../services/airportList'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import dayjs from 'dayjs';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100% !important",
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
}))

const fareTypeList = [
  { title: 'Lite', value: 'Lite' },
  { title: 'Regular', value: 'Regular' },
  { title: 'Corporate', value: 'Corporate' },
  { title: 'SME', value: 'SME' },
  { title: 'Friend and Family', value: 'Friend_Family' },
  { title: 'Sale', value: 'Sale' },
  { title: 'Promo', value: 'Promo' },
  { title: 'Flexi', value: 'Flexi' },
]


const RoundTrip = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useNavigate()

  const [showMore, setShowMore] = useState(false)
  const [depDate, setDepDate] = useState()
  const [retDate, setRetDate] = useState(null)
  const [classType, setClassType] = useState('Economy')
  const [open, setOpen] = useState(false)

  const [fromAirport, setFromAirport] = useState([])
  const [fromValue, setFromValue] = useState()
  const [fromInValue, setFromInValue] = useState('')

  const [toAirport, setToAirport] = useState([])
  const [toValue, setToValue] = useState()
  const [toInValue, setToInValue] = useState('')

  const [prefAirline, setprefAirline] = useState([])
  const [prefAirValue, setPrefAirValue] = useState(null)
  const [prefAirInValue, setPrefAirInValue] = useState('')

  const [isOpenDepart, setIsOpenDepart] = useState('')
  const [isOpenReturn, setIsOpenReturn] = useState('')

  const [roundTrip, setRoundTrip] = useState({
    trip_type: 'circle',
    from: '',
    from_loc_id: '',
    to: '',
    to_loc_id: '',
    depature: '',
    return: '',
    adult: 1,
    child: 0,
    infant: 0,
    nonStop: false,
    studentFare: false,
    search_flight: 'search',
    v_class: 'Economy',
    carrier: [],
    fromCountry: null,
    toCountry: null,
    specialFare: false,
    // booking_source: 'PTBSID0000000008',
    fareType: []

  })

  const [showPassClass, setShowPassClass] = useState(false)
  const [showTravellerCount, setShowTravellerCount] = useState(false)
  const [showPassClassBtn, setShowPassClassBtn] = useState(true)

  const togglePassClass = () => {
    setShowPassClass(!showPassClass)
  }

  const getTavellerCount = () => {
    setShowPassClassBtn(false)
    setShowTravellerCount(true)
    setShowPassClass(false)

  }

  const [roundTripError, setRoundTripError] = useState({
    fromError: '',
    toError: '',
    depatureError: '',
    returnError: ''
  })

  function formatDate(date) {
    let d = new Date(date);
    let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
    let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    let newDate = `${da}-${mo}-${ye}`
    return newDate;
  }

  const handledDepDate = (date) => {
    setDepDate(formatDate(date))
    props.setDepDate(formatDate(date))
    props.setPropsDepDate(formatDate(date))
    setRoundTrip({
      ...roundTrip,
      depature: formatDate(date)
    })
    props.setTrip({
      ...props.trip,
      depature: formatDate(date)
    })
  }

  const handledRetDate = (date) => {
    setRetDate(formatDate(date))
    props.setRetDate(formatDate(date))
    props.setPropsRetDate(formatDate(date))
    props.setReturnDate(formatDate(date))
    setRoundTrip({
      ...roundTrip,
      return: formatDate(date)
    })
    props.setTrip({
      ...props.trip,
      return: formatDate(date)
    })
  }

  const onClickAdult = (type) => {
    if (type === 'increment') {
      if (roundTrip.adult < 9) {
        setRoundTrip({
          ...roundTrip,
          adult: roundTrip.adult + 1,
          child: 0,
          infant: 0
        })
      }
    } else {
      if (roundTrip.adult > 1) {
        setRoundTrip({
          ...roundTrip,
          adult: roundTrip.adult - 1,
          child: 0,
          infant: 0
        })
      }
    }
  }

  const onClickChild = (type) => {
    if (type === 'increment') {
      if (roundTrip.child < (9 - roundTrip.adult)) {
        setRoundTrip({
          ...roundTrip,
          child: roundTrip.child + 1
        })
      }
    } else {
      if (roundTrip.child > 0) {
        setRoundTrip({
          ...roundTrip,
          child: roundTrip.child - 1
        })
      }
    }
  }

  const onClickInfants = (type) => {
    if (type === 'increment') {
      if (roundTrip.infant < roundTrip.adult) {
        setRoundTrip({
          ...roundTrip,
          infant: roundTrip.infant + 1
        })
      }
    } else {
      if (roundTrip.infant > 0) {
        setRoundTrip({
          ...roundTrip,
          infant: roundTrip.infant - 1
        })
      }
    }
  }

  const onClickNonStop = () => {
    setRoundTrip({
      ...roundTrip,
      nonStop: !roundTrip.nonStop
    })
  }

  const onClickStudentFare = () => {
    setRoundTrip({
      ...roundTrip,
      studentFare: !roundTrip.studentFare
    })
  }

  const showMoreToggle = () => {
    setShowMore(!showMore)
  }

  const handleClassChange = (event) => {
    setClassType(event.target.value)
    setRoundTrip({
      ...roundTrip,
      v_class: event.target.value
    })
  }

  const handleClassClose = () => {
    setOpen(false)
  }

  const handleClassOpen = () => {
    setOpen(true)
  }

  // const getAirportList = (search) => {
  //   try {
  //     getAuth(API_BASE_URL + 'data/get_airport_code_list/' + search, null)
  //       .then((response) => {

  //         if (response.data.status) {
  //           setFromAirport(response.data.data)
  //           setToAirport(response.data.data)
  //         } else {
  //           setFromAirport([])
  //           setToAirport([])
  //         }
  //       }).catch((error) => {
  //       })
  //   } catch (error) {
  //   }
  // }

  const getAirline = useCallback(
    (searching) => {
      try {
        getAuth(API_BASE_URL + 'data/get_airline/' + searching, null)
          .then((response) => {

            if (response.data.status) {
              setprefAirline(response.data.data)
            } else {
              setprefAirline([])
            }
          }).catch((error) => {
          })
      } catch (error) {
      }
    }
  )

  // const getFromAirport = useCallback(
  //   (search) => {
  //     getAuth(API_BASE_URL + 'data/get_airport_code_list/' + search, null)
  //       .then((response) => {
  //         let newOptions = []
  //         if (response.data.status) {
  //           newOptions = [...newOptions, ...(response.data.data)]
  //         }
  //         setFromAirport(newOptions)
  //       }).catch((error) => {
  //       })
  //   }
  // )

  // const getToairport = useCallback(
  //   (search) => {
  //     getAuth(API_BASE_URL + 'data/get_airport_code_list/' + search, null)
  //       .then((response) => {
  //         let newOptions = []
  //         if (response.data.status) {
  //           newOptions = [...newOptions, ...(response.data.data)]
  //         }
  //         setToAirport(newOptions)
  //       }).catch((error) => {
  //       })
  //   }
  // )

  // const airportSwitch = () =>{
  //   let fvalue = fromValue;
  //   let rValue = toValue;

  //   setFromValue(rValue);
  //   setToValue(fvalue)

  // }

  const airportSwitch = () => {
    setToValue(fromValue)
    setFromValue(toValue)

    setRoundTrip({
      ...roundTrip,
      to: roundTrip.from,
      to_loc_id: roundTrip.from_loc_id,
      from: roundTrip.to,
      from_loc_id: roundTrip.to_loc_id
    })

    setRoundTripError({
      ...roundTripError,
      fromError: '',
      toError: ''
    })
  }



  const filterOptions = (options, state) => {
    const { inputValue } = state

    if (!inputValue) {
      return options.slice(0, 10)
    }
    const arr = airports.filter((item) => {
      return item.airport_code.toLowerCase() === inputValue.toLowerCase()
    })

    if (arr && arr.length > 0) {
      return arr
    } else {
      const cityArr = airports.filter((item) => {
        return item.airport_city.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      })
      return cityArr && cityArr.length > 0 ? cityArr.slice(0, 20) : []
    }
  }
  console.log("RoundTripSearchReq", props.searchObj)
  const roundTripSearch = () => {
    // const fromEr = fromVali(roundTrip.from, roundTrip.to)
    // const toEr = toVali(roundTrip.to, roundTrip.from)
    // const depEr = depVali(roundTrip.depature)
    // const retEr = retVali(roundTrip.return)

    // if (fromEr || toEr || depEr || retEr) {
    //   setRoundTripError({
    //     ...roundTripError,
    //     fromError: fromEr,
    //     toError: toEr,
    //     depatureError: depEr,
    //     returnError: retEr
    //   })
    //   return
    // }
    console.log("RoundTripSearchReq", roundTrip)
    props.setSearchObj(roundTrip);
    props.setShowModify(false);
    // history.push({
    //   pathname: '/flight/search',
    //   state: roundTrip
    // })
  }
  const onClickSpecialFare = () => {
    setRoundTrip({
      ...roundTrip,
      specialFare: !roundTrip.specialFare
    })
  }

  // useEffect(() => {
  //   // getAirportList('')
  //   setRoundTrip({
  //     ...roundTrip, 
  //       from: props.trip.from, 
  //       from_loc_id: props.trip.from_loc_id,
  //       to: props.trip.to, 
  //       to_loc_id: props.trip.to_loc_id,
  //       depature: props.trip.depature, 
  //       return: props.trip.return,
  //       fromCountry: props.fromCountry, 
  //       toCountry: props.toCountry,
  //       adult: props.searchObj.adult ? props.searchObj.adult : 1,
  //       child: props.searchObj.child ? props.searchObj.child : 0
  //   });
  //   setRoundTripError(props.tripError);
  //   setFromAirport([...fromAirport, props.fromAirport]);
  //   setFromValue(props.fromValue);
  //   setToAirport([...toAirport, props.toAirport]);
  //   setToValue(props.toValue);
  //   setIsOpenDepart(props.isOpenDepart);
  //   setDepDate(props.depDate);
  //   setRetDate(props.retDate);
  //   setIsOpenReturn(props.isOpenReturn);
  // }, [])

  return (
    <div>
      <Box>
        <form noValidate autoComplete="off">
          <Box className="search-wpr">
            <Grid container spacing={0} className="cntrowswp">
              <Grid item md={6} xs={12} className="prstColl px-0 mob-input-border-r ">
                <Box className='d-flex'>
                  <Box textAlign="left" className="box-tlt box-input-w">

                    <span>From</span>
                    <Autocomplete
                      className="inputFieldLocation"
                      size="small"
                      id="free-solo-demo"
                      freeSolo
                      autoComplete
                      includeInputInList
                      filterSelectedOptions
                      options={airports}
                      filterOptions={filterOptions}
                      getOptionLabel={(option) => option.airport_city + " (" + option.airport_code + ")"}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Box className="searchsuggestionbox">
                            <Box className="flightdetails-code">
                              <Box className="cityfly">{option.airport_city}, {option.country}</Box>
                              <Box className="airportcode">{option.airport_code}</Box>
                            </Box>
                            <Box className="airport-name">{option.airport_name}</Box>
                          </Box>
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="normal"
                          variant="outlined"
                          placeholder="Type atleast 3 letters"
                          className="inputFieldLocation"
                          error={!!roundTripError.fromError}
                          helperText={roundTripError.fromError}
                        />
                      )}

                      // filterOptions={(x) => x}
                      value={fromValue || props.fromValue}
                      onChange={(event, newValue) => {
                        setFromAirport(newValue ? [newValue, ...fromAirport] : fromAirport)
                        setFromValue(newValue)
                        props.setFromAirport(newValue ? [newValue, ...fromAirport] : fromAirport)
                        props.setFromValue(newValue)
                        props.setPropsFromAirport(newValue ? [newValue, ...fromAirport] : fromAirport)
                        props.setPropsFromValue(newValue)
                        if (newValue) {
                          setRoundTrip({
                            ...roundTrip,
                            from: newValue.airport_city + ' (' + newValue.airport_code + ')',
                            from_loc_id: newValue.id,
                            fromCountry: newValue.country
                          })
                          props.setTrip({
                            ...props.trip,
                            from: newValue.airport_city + ' (' + newValue.airport_code + ')',
                            from_loc_id: newValue.id
                          })
                          props.setFromCountry(newValue.country)
                          props.setPropsFromCountry(newValue.country)
                        }
                      }}

                    />
                  </Box>
                  {/* </Grid>
              <Grid item md={1} xs={12} className="prstColl px-0 mt-4"> */}
                  <Box className="box-swpicon-w swpicon-w-round m-auto">
                    <Box className="icoswipe swipebox" onClick={airportSwitch}><SwapHorizIcon /></Box>
                  </Box>
                  {/* </Grid>

              <Grid item md={3} xs={12} className="prstColl px-0 mob-input-border-r"> */}
                  <Box textAlign="left" className="box-tlt box-input-w">
                    <span>To</span>
                    <Autocomplete
                      className="inputFieldLocation"
                      size="small"
                      id="free-solo-demo"
                      freeSolo
                      autoComplete
                      includeInputInList
                      filterSelectedOptions
                      options={airports}
                      filterOptions={filterOptions}
                      getOptionLabel={(option) => option.airport_city + " (" + option.airport_code + ")"}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Box className="searchsuggestionbox">
                            <Box className="flightdetails-code">
                              <Box className="cityfly">{option.airport_city}, {option.country}</Box>
                              <Box className="airportcode">{option.airport_code}</Box>
                            </Box>
                            <Box className="airport-name">{option.airport_name}</Box>
                          </Box>
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="normal"
                          variant="outlined"
                          placeholder="Type atleast 3 letters"
                          className="inputFieldLocation"
                          error={!!roundTripError.toError}
                          helperText={roundTripError.toError}
                        />
                      )}

                      // filterOptions={(x) => x}
                      value={toValue || props.toValue}
                      onChange={(event, newValue) => {
                        setToAirport(newValue ? [newValue, ...toAirport] : toAirport)
                        setToValue(newValue)
                        props.setToAirport(newValue ? [newValue, ...toAirport] : toAirport)
                        props.setToValue(newValue)
                        props.setPropsToAirport(newValue ? [newValue, ...toAirport] : toAirport)
                        props.setPropsToValue(newValue)
                        if (newValue) {
                          setRoundTrip({
                            ...roundTrip,
                            to: newValue.airport_city + ' (' + newValue.airport_code + ')',
                            to_loc_id: newValue.id,
                            toCountry: newValue.country
                          })
                          props.setTrip({
                            ...props.trip,
                            to: newValue.airport_city + ' (' + newValue.airport_code + ')',
                            to_loc_id: newValue.id,
                          })
                          props.setToCountry(newValue.country)
                          props.setPropsToCountry(newValue.country)
                        }
                      }}
                    // onInputChange={(event, newInputValue) => {
                    //   if (newInputValue) {
                    //     setToInValue(newInputValue)
                    //     getToairport(newInputValue)
                    //   }
                    // }}

                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item md={5} xs={12} className="prstColl d-flex px-0 mob-input-border-r">
                <span className='border-right div-border-right'></span>
                <Box textAlign="left" className="box-tlt box-input-calender">
                  <span>departure date</span>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                      <DatePicker
                        className="inputDate"
                        size="small"
                        disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        format="DD-MMM-YYYY"
                        margin="normal"
                        id="date-picker-inline"
                        disablePast
                        value={dayjs(depDate) || dayjs(props.depDate)}
                        onChange={handledDepDate}
                        autoOk
                       InputProps={{ readOnly: true }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                        placeholder="Depature Date"

                        onChange={newDate => {
                          handledDepDate(newDate)
                          setIsOpenDepart(false)
                          props.setIsOpenDepart(false)
                        }}
                        KeyboardButtonProps={{
                          onFocus: e => {
                            setIsOpenDepart(true)
                            props.setIsOpenDepart(true)
                          }
                        }}
                        PopoverProps={{
                          disableRestoreFocus: true,
                          onClose: () => {
                            setIsOpenDepart(false)
                            props.setIsOpenDepart(false)
                          }
                        }}
                        InputProps={{
                          onFocus: () => {
                            setIsOpenDepart(true)
                            props.setIsOpenDepart(true)
                          }
                        }}
                       // open={isOpenDepart || props.isOpenDepart}

                        error={!!roundTripError.depatureError}
                        helperText={roundTripError.depatureError}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>

                <span className='border-right div-border-right'></span>
                <Box textAlign="left" className="box-tlt box-input-calender">
                  <span>Return date</span>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                      <DatePicker
                        className="inputDate"
                        size="small"
                        disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        format="DD-MMM-YYYY"
                        margin="normal"
                        id="date-picker-inline"
                        disablePast
                        value={dayjs(retDate) || dayjs(props.retDate)}
                        minDate={dayjs(depDate) || dayjs(props.depDate)}
                        onChange={handledRetDate}
                        autoOk
                       InputProps={{ readOnly: true }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                        placeholder="Return Date"

                        onChange={newDate => {
                          handledRetDate(newDate)
                          setIsOpenReturn(false)
                          props.setIsOpenReturn(false)
                        }}
                        KeyboardButtonProps={{
                          onFocus: e => {
                            setIsOpenReturn(true)
                            props.setIsOpenReturn(true)
                          }
                        }}
                        PopoverProps={{
                          disableRestoreFocus: true,
                          onClose: () => {
                            setIsOpenReturn(false)
                            props.setIsOpenReturn(false)
                          }
                        }}
                        InputProps={{
                         onFocus: () => {
                            setIsOpenReturn(true)
                            props.setIsOpenReturn(true)
                          }
                        }}
                       // open={isOpenReturn || props.isOpenReturn}

                        error={!!roundTripError.returnError}
                        helperText={roundTripError.returnError}
                      />
                    </DemoContainer>
                  </LocalizationProvider>

                </Box>
              </Grid>
              <Grid item md={1} xs={12} className="prstColl d-flex px-0 mob-input-border-r">
                <span className='border-right div-border-right'></span>
                <Box textAlign="left" className="box-tlt pt-2">
                  <span>Passengers & Class</span>
                  <h6 onClick={togglePassClass} className='trav-txt'><span className='trav-count'>{roundTrip.adult + roundTrip.child + roundTrip.infant}</span> Travellers <p className='class-txt'>{roundTrip.v_class}</p></h6>
                  {showPassClass ? (
                    <Box className='passclass-box'>
                      <Grid container spacing={1} className="rowGap btnGroup">
                        <Grid item md={12} xs={12}>
                          <Box>
                            <Box textAlign="left" className="box-tlt">
                              adult (12 Yrs +)
                            </Box>
                            <ButtonGroup
                              size="small"
                              aria-label="small outlined button group"
                              className="btnGroup"
                            >
                              <Button className="btnGroup" onClick={() => onClickAdult('decrement')}>-</Button>
                              <Button className="btnGroup">
                                {roundTrip.adult}
                              </Button>
                              <Button className="btnGroup" onClick={() => onClickAdult('increment')}>+</Button>
                            </ButtonGroup>
                          </Box>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Box>
                            <Box textAlign="left" className="box-tlt">
                              child (2 yrs - 12 Yrs)
                            </Box>
                            <ButtonGroup
                              size="small"
                              aria-label="small outlined button group"
                              className="btnGroup"
                            >
                              <Button className="btnGroup" onClick={() => onClickChild('decrement')}>-</Button>
                              <Button className="btnGroup">
                                {roundTrip.child}
                              </Button>
                              <Button className="btnGroup" onClick={() => onClickChild('increment')}>+</Button>
                            </ButtonGroup>
                          </Box>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Box>
                            <Box textAlign="left" className="box-tlt">
                              infant (Below 2 Yrs)
                            </Box>
                            <ButtonGroup
                              size="small"
                              aria-label="small outlined button group"
                              className="btnGroup"
                            >
                              <Button className="btnGroup" onClick={() => onClickInfants('decrement')}>-</Button>
                              <Button className="btnGroup">
                                {roundTrip.infant}
                              </Button>
                              <Button className="btnGroup" onClick={() => onClickInfants('increment')}>+</Button>
                            </ButtonGroup>
                          </Box>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Box className="divSet">
                            <Box textAlign="left" className="box-tlt">
                              Class
                            </Box>
                            <Select
                              labelId="demo-controlled-open-select-label"
                              id="demo-controlled-open-select"
                              open={open}
                              onClose={handleClassClose}
                              onOpen={handleClassOpen}
                              value={classType}
                              onChange={handleClassChange}
                              variant="outlined"
                              className="ClassSelector"
                            >
                              <MenuItem className="items-list" selected={true} value="Economy">
                                Economy
                              </MenuItem>
                              <MenuItem className="items-list" value="Premium Economy">
                                Premium Economy
                              </MenuItem>
                              <MenuItem className="items-list" value="Business">
                                Business
                              </MenuItem>
                            </Select>
                          </Box>
                        </Grid>
                        <Grid item xs={1} ></Grid>
                        <Grid item md={6} xs={6} className="px-2 pt-2">
                          <Button
                            variant="contained"
                            color="primary"
                            className=""
                            onClick={getTavellerCount}
                          >
                            Apply
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : ""}
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Grid container spacing={0}>
            <Grid item md={10} xs={6}>
              <FormControlLabel
                className="nonstop-tlt"
                control={
                  <Checkbox
                    checked={roundTrip.nonStop}
                    onChange={onClickNonStop}
                    name="nonStop"
                  />
                }
                label="Non-Stop"
                color="secondary"
              />

            </Grid>

            <Grid item md={2} xs={6} className="text-right mt-1">
              <Button
                variant="contained"
                color="primary"
                className="btnSend my-2"
                onClick={roundTripSearch}
              >
                Search Flight
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>

    </div>
  )
}

export default RoundTrip
