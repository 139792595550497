import { PROFILE_ERROR, PROFILE_LOADER, PROFILE_SUCCESS } from '../actions/profileAction'

const initialProfileState = {
  isLoading: false,
  successData: null,
  error: ''
}

const profileReducers = (state = initialProfileState, { type, payload }) => {
  
  switch (type) {
    case PROFILE_LOADER:
      return {
        ...state,
        isLoading: true
      }
    case PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        successData: payload
      }
    case PROFILE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload
      }
    default:
      return state
  }
}

export default profileReducers
