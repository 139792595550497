import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { Box, Container, TextField, Select, MenuItem, FormControl, Button, LinearProgress, Pagination, Stack, Tooltip } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { API_BASE_URL, PROFILE_IMAGE } from '../../utility/apiConstants'
import { profileSuccess } from '../../store/user/actions/profileAction'
// import profileImg from "../../../assets/images/images/myprofile.png";
import SideNav from '../../SideNav'
import MainFooter from '../footer/MainFooter'
import { countrylist } from '../../services/countryList';
import { authPost, getAuth, getReq } from '../../utility/apiConfig'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link, useNavigate } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const TravelerItineraryList = () => {
    const resultPerPage = 20;
    const navigate = useNavigate();
    const [toggle, setToggle] = useState(false);
    const [userList, setUserList] = useState([]);
    const [matchList, setMatchList] = useState([]);
    const [itineraryList, setItineraryList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isUserLoading, setIsUserLoading] = useState(false);
    const [isMatchLoading, setIsMatchLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedTravelers, setSelectedTravelers] = useState(null);
    const [selectedMatch, setSelectedMatch] = useState(null);
    const [selectedItineraryId, setSelectedItineraryId] = useState(null);
    const [userDetails, setUserDetails] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event, itinerary_id) => {
        setAnchorEl(event.currentTarget);
        setSelectedItineraryId(itinerary_id);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [searchDetails, setSearchDetails] = useState({
        pax_name: "", match: "", itineraryId: ""
    });
    const [searchDetailsErr, setSearchDetailsErr] = useState({
        pax_name: null, match: null, status: null, itineraryId: null
    });

    useEffect(() => {
        let scripts = 'https://unpkg.com/libphonenumber-js/bundle/libphonenumber-js.min.js'
        var script = document.createElement("script");
        script.src = scripts
        script.async = true;
        document.body.appendChild(script);
    }, [])

    useEffect(() => {
        getCurrentUserDetails();
        getUserList();
        getMatchList();
        getItineraryList();
    }, [])

    const getCurrentUserDetails = () => {
        getAuth(API_BASE_URL + 'user/account', null)
            .then((response) => {
                let user_data = response.data.data.form_data;
                setUserDetails({
                    ...userDetails,
                    title: user_data.title,
                    firstName: user_data.first_name,
                    middleName: user_data.middle_name,
                    lastName: user_data.last_name
                })
            }).catch((error) => {
              alert(error)
            })
      }

    const getUserList = (pageNo) => {
        setIsUserLoading(true);
        try {
            getAuth(API_BASE_URL + `user/list_of_users/0/0?first_name=&email=&phone=&country_code=&city=&designation=&status=&last_name=&user_id=`)
                .then((response) => {
                    if (response.data && response.data.data && response.status) {
                        setUserList(response.data.data.data);
                        setIsUserLoading(false);
                    }
                    else {
                        setIsUserLoading(false);
                        setUserList([]);
                    }
                }).catch((error) => {
                    alert(error.message);
                    setIsUserLoading(false);
                    setUserList([]);
                })
        } catch (error) {
            setIsUserLoading(false);
        }
    }

    const getMatchList = (pageNo) => {
        setIsMatchLoading(true);
        try {
            getAuth(API_BASE_URL + `data/get_match_list`)
                .then((response) => {
                    if (response.data && response.data.data && response.status) {
                        setMatchList(response.data.data);
                        setIsMatchLoading(false);
                    }
                    else {
                        setIsMatchLoading(false);
                        setMatchList([]);
                    }
                }).catch((error) => {
                    alert(error.message);
                    setIsMatchLoading(false);
                    setMatchList([]);
                })
        } catch (error) {
            setIsMatchLoading(false);
        }
    }

    const getItineraryList = () => {
        setIsLoading(true);
        let userId = selectedTravelers !== null ? selectedTravelers.user_id : "";
        let activityId = selectedMatch !== null ? selectedMatch.origin : "";
        let itineraryId = searchDetails.itineraryId
        try {
            getAuth(API_BASE_URL + `itinerary/fetch_itinerary?user_id=${userId}&activity_id=${activityId}&itinerary_id=${itineraryId}`)
                .then((response) => {
                    if (response.data && response.data.data && response.status) {
                        setItineraryList(response.data.data.data);
                        setIsLoading(false);
                    }
                    else {
                        setIsLoading(false);
                        setItineraryList([]);
                    }
                }).catch((error) => {
                    alert(error.message);
                    setIsLoading(false);
                    setItineraryList([]);
                })
        } catch (error) {
            setIsLoading(false);
        }
    }

    const handleChange = (e) => {
        setSearchDetails({ ...searchDetails, [e.target.name]: e.target.value });
        setSearchDetailsErr({ ...searchDetailsErr, [e.target.name]: null });
    }

    const handleClear = () => {
        setSearchDetails({ pax_name: "", match: "", inventoryId: "" })
    }

    function formatDate(date) {
        let d = new Date(date);
        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        let newDate = `${da}-${mo}-${ye}`
        return newDate;
    }

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <SideNav header={"My Itinerary"} />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <Container>
                        <Card className='px-4 py-2 mt-3'>
                            <Row className='mt-3'>
                                <Col xs={12} sm={12} md={8} className='mb-1'>
                                    <h6 className='heading-1'>Itinerary List</h6>
                                </Col>
                                <Col xs={12} sm={12} md={4} className='mb-1 d-flex justify-content-end'>
                                    <Button variant='contained' color='primary' size='small' onClick={()=> {setToggle(!toggle)}}><SearchIcon style={{fontSize: 16}} />&nbsp;Search</Button>
                                </Col>
                            </Row>
                            {toggle &&
                            <Card className='p-3 mt-3'>
                                <Row>
                                    <Col xs={12} sm={12} md={3} >
                                        <Box className="setBox ">
                                            <div className="tlthedae">Traveler Name</div>
                                            <Stack>
                                               <Autocomplete
                                                            id="tags-standard"
                                                            options={userList}
                                                            getOptionLabel={(option) => option.first_name + " " + option.middle_name + " " + option.last_name}
                                                            getOptionSelected={(option, value) => option.id === value.id}
                                                            value={selectedTravelers}
                                                            renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                placeholder="Select Travelers"
                                                            />
                                                            )}
                                                            onChange={(event,newValue)=> {
                                                                if(newValue !== null){
                                                                setSelectedTravelers(newValue);
                                                                setSearchDetails({...searchDetails, pax_name: newValue.user_id});
                                                                }
                                                            }}
                                                        />
                                            </Stack>
                                        </Box>
                                    </Col>
                                    <Col xs={12} sm={12} md={3} >
                                        <Box className="setBox ">
                                            <div className="tlthedae">Match</div>
                                            <Autocomplete
                                                        disablePortal
                                                        id="country-select-demo"
                                                        options={matchList}
                                                        getOptionLabel={(option) => option.teams + ", " + option.venue + " (" + formatDate(option.match_date) + ")"}
                                                        value={selectedMatch}
                                                        renderInput={(params) => (
                                                            <TextField
                                                            required
                                                            {...params}
                                                            placeholder="Choose a match"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                                            }}
                                                            />
                                                        )}
                                                        onChange={(option,item)=> {
                                                            if(item !== null){
                                                            setSelectedMatch(item);
                                                            setSearchDetails({...searchDetails, match: item.origin
                                                            })}}}
                                                        />
                                        </Box>
                                    </Col>
                                    <Col xs={12} sm={12} md={3}>
                                        <Box className="setBox">
                                            <div className="tlthedae">Itinerary Id</div>
                                            <FormControl fullWidth>
                                                <TextField
                                                    autoComplete="given-name"
                                                    name="itineraryId"
                                                    required
                                                    fullWidth
                                                    id="itineraryId"
                                                    placeholder="Itinerary Id"
                                                    value={searchDetails.itineraryId}
                                                    onChange={handleChange}
                                                    error={searchDetailsErr.itineraryId}
                                                    helperText={searchDetailsErr.itineraryId}
                                                />
                                            </FormControl>
                                        </Box>
                                    </Col>
                                    <Col xs={12} sm={12} md={3} className='mt-1'>
                                        <Button variant='contained' size="small" color='success' className='mb-3 mt-3' onClick={()=> getItineraryList()}>Search</Button>
                                        <Button variant='contained' size="small" color='warning' className='mb-3 mt-3 mx-2' onClick={()=> handleClear()}>Clear</Button>
                                    </Col>
                                </Row>
                            </Card>
                            }
                            <Row className='my-3'>
                                {/* <Col xs={12} sm={12} md={12} className='mb-2'>
                                    <h6 className='heading-1'>Hotel Inventory List</h6>
                                </Col> */}
                                <Col xs={12} md={12}>
                                    <TableContainer >
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                            <TableRow>
                                                <TableCell align="left">Itinerary Id</TableCell>
                                                <TableCell align="left">Traveler Name</TableCell>
                                                <TableCell align="left">Match</TableCell>
                                                <TableCell align="left">Hotel</TableCell>
                                                <TableCell align="right">Action</TableCell> 
                                            </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {(itineraryList.length && !isLoading) > 0 ? (
                                                <>
                                            {itineraryList?.map((item,index) => (
                                                <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                     <TableCell align="left" className='text-capitalize'>{item.itinerary_details[0]?.itinerary_id}</TableCell>
                                                     <Tooltip title={item.itinerary_passenger_details?.sort(function(x,y){ return (x?.first_name === userDetails?.firstName && x?.last_name === userDetails?.lastName) ? -1 : (y?.first_name === userDetails?.firstName && y?.last_name === userDetails?.lastName) ? 1 : 0; }).map(x=> <div>{x?.first_name + " " + x?.last_name}</div>)}>
                                                        <TableCell align="left" className='text-capitalize'>{item.itinerary_passenger_details[0]?.first_name + " " + item.itinerary_passenger_details[0]?.last_name} {item?.itinerary_passenger_details?.length > 1 ? "+ " + Number(item?.itinerary_passenger_details?.length - 1) : null}</TableCell>
                                                    </Tooltip>
                                                <TableCell align="left" className='text-capitalize'>{(item.itinerary_activity_details && item.itinerary_activity_details[0]?.teams) ? item.itinerary_activity_details[0]?.teams + ", " + item.itinerary_activity_details[0]?.venue : "-"}</TableCell>
                                                <TableCell align="left" className='text-capitalize'>{item.itinerary_hotel_details ? item.itinerary_hotel_details[0]?.hotel_name : "-"}</TableCell>
                                                <TableCell align="right" >
                                                    <Link to={"/traveler-itinerary?" + item.itinerary_details[0]?.itinerary_id} className=' btn btn-success'><b>View</b></Link>
                                                    
                                                </TableCell>
                                               
                                                </TableRow>
                                            ))}
                                            </>    
                                                ) : (isLoading && itineraryList.length === 0) ? (
                                                    <TableRow>
                                                        <TableCell align="left"  colspan="6"> 
                                                            <LinearProgress color="success" />
                                                        </TableCell>
                                                    </TableRow>
                                                    ) : (!isLoading && itineraryList.length === 0) ? (
                                                    <TableRow>
                                                    <TableCell align="center"  colspan="6"> <p><b>No data found.</b></p></TableCell>
                                                    </TableRow>
                                                    ): (
                                                        <TableRow>
                                                        <TableCell align="left"  colspan="6"> 
                                                            <LinearProgress color="success" />
                                                        </TableCell>
                                                    </TableRow>
                                                    )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    {totalRecords > 1 && (
                                        <Box className="my-2 px-2 float-right">
                                            <Stack spacing={2}>
                                                <Pagination page={currentPage} count={totalRecords} onChange={(e, index) => getUserList(index)} variant="outlined" color="primary" />
                                            </Stack>
                                        </Box>
                                        )}
                                </Col>
                            </Row>
                        </Card>
                    </Container>
                </Box>
            </Box>
            <MainFooter />
        </>
    )
}

export default TravelerItineraryList
