import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import thunk from 'redux-thunk'
import rootReducers from '../store/rootReducers'

const store = createStore(
  rootReducers,
  composeWithDevTools(applyMiddleware(thunk))
)

export default store
