import React, {useEffect, useState} from 'react';
import { createTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import './dashboard.css'
import SideNav from '../../SideNav';
import { Box } from '@mui/material';
import MainFooter from '../footer/MainFooter';
import { getAuth } from '../../utility/apiConfig';
import { API_BASE_URL } from '../../utility/apiConstants';
import Calendar from './Calender';
import Alert from '@mui/material/Alert';
import { Link } from 'react-router-dom';
// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Dashboard() {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getUserAccountDetails();
    }, []);

    const getUserAccountDetails = () => {
        setLoading(true);
        try {
            getAuth(API_BASE_URL + 'user/account')
                .then((response) => {
                    // console.log("response=======>",response.data);
                    if (response.data.status) {
                        // alert(response.data.data.message)
                        console.log("response=======>", response.data.data?.form_data);
                        let user_data = response.data.data?.form_data
                        localStorage.setItem('user_data', JSON.stringify(user_data));
                        setLoading(false);
                    } else {
                        alert(response.data.message)
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.log("Error", error)
                    alert('Somthing went wrong please try again')
                    setLoading(false);
                })
        } catch (error) {
        }
    }

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <SideNav header={"Dashboard"} />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <Container maxWidth="lg" sx={{ mt: 3, }}>
                        <Grid container spacing={2}>
                            {/* <Grid  item xs={12} md={12}>
                                <Link to="/profile"><Alert severity="warning">Please complete your profile - 90% done</Alert></Link>
                            </Grid> */}
                            <Grid item xs={12} md={12}>
                                <Calendar />
                            </Grid>
                            {/* <Grid item xs={12} md={3}>
                                <div class="card bg-c-blue order-card">
                                    <div class="card-block">
                                        <h6 class="m-b-20">Request Received</h6>
                                        <h2 class=""><span>100</span></h2>
                                        <p class="mb-0">Total Request</p>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <div class="card bg-c-green order-card">
                                    <div class="card-block">
                                        <h6 class="m-b-20">Request Received</h6>
                                        <h2 class=""><span>30</span></h2>
                                        <p class="mb-0">Completed Request</p>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <div class="card bg-c-yellow order-card">
                                    <div class="card-block">
                                        <h6 class="m-b-20">Request Received</h6>
                                        <h2 class=""><span>40</span></h2>
                                        <p class="mb-0">Pending Request</p>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <div class="card bg-c-pink order-card">
                                    <div class="card-block">
                                        <h6 class="m-b-20">Request Received</h6>
                                        <h2 class=""><span>30</span></h2>
                                        <p class="mb-0">Rejected Request</p>
                                    </div>
                                </div>
                            </Grid> */}
                        </Grid>
                    </Container>
                </Box>
            </Box>
            <MainFooter />
        </>
    );
}