import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { Box, Container, TextField, Select, MenuItem, FormControl, Button, LinearProgress, Pagination, Stack, Tooltip } from '@mui/material'
import { API_BASE_URL } from '../../utility/apiConstants'
import SideNav from '../../SideNav'
import MainFooter from '../footer/MainFooter'
import { getAuth } from '../../utility/apiConfig'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link, useNavigate } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';

const TravelersList = () => {
    const resultPerPage = 20;
    const navigate = useNavigate();
    const [toggle, setToggle] = useState(false);
    const [initialUserList, setInitialUserList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [itineraryList, setItineraryList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedTravelers, setSelectedTravelers] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [userDetails, setUserDetails] = useState(null);
    const [relation, setRelation] = useState('');
    const open = Boolean(anchorEl);

    const handleRelationChange = (event) => {
        setRelation(event.target.value);
    };

    useEffect(() => {
        let scripts = 'https://unpkg.com/libphonenumber-js/bundle/libphonenumber-js.min.js'
        var script = document.createElement("script");
        script.src = scripts
        script.async = true;
        document.body.appendChild(script);
    }, [])

    useEffect(() => {
        getCurrentUserDetails();
    }, [])

    const getCurrentUserDetails = () => {
        getAuth(API_BASE_URL + 'user/account', null)
            .then((response) => {
                let user_data = response.data.data.form_data;
                setUserDetails({
                    ...userDetails,
                    title: user_data.title,
                    firstName: user_data.first_name,
                    middleName: user_data.middle_name,
                    lastName: user_data.last_name,
                    userId: user_data.user_id
                })
                getUserList(user_data.user_id);
            }).catch((error) => {
                alert(error)
            })
    }

    const getUserList = (user_id) => {
        setIsLoading(true);
        try {
            getAuth(API_BASE_URL + `user/list_of_users/0/0?user_id=&primary_user_id=${user_id}&relationship=&c=1`)
                .then((response) => {
                    if (response.data && response.data.data && response.status) {
                        setUserList(response.data.data.data);
                        setInitialUserList(response.data.data.data);
                        setIsLoading(false);
                    }
                    else {
                        setIsLoading(false);
                        setUserList([]);
                    }
                }).catch((error) => {
                    alert(error.message);
                    setIsLoading(false);
                    setUserList([]);
                })
        } catch (error) {
            setIsLoading(false);
        }
    }

    const getSearchedUserList = (user_id, selected_user_id, relation_ship) => {
        let selected_userId = (selected_user_id !== undefined && selected_user_id !== null) ? selected_user_id : "";
        let relationship = relation;
        setIsLoading(true);
        try {
            getAuth(API_BASE_URL + `user/list_of_users/0/0?user_id=${selected_userId}&primary_user_id=${user_id}&relationship=${relationship}&c=1`)
                .then((response) => {
                    if (response.data && response.data.data && response.status) {
                        setUserList(response.data.data.data);
                        setIsLoading(false);
                    }
                    else {
                        setIsLoading(false);
                        setUserList([]);
                    }
                }).catch((error) => {
                    alert(error.message);
                    setIsLoading(false);
                    setUserList([]);
                })
        } catch (error) {
            setIsLoading(false);
        }
    }

    const handleClear = () => {
        setSelectedUserId(null);
        setRelation('');
    }

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <SideNav header={"Co-Travelers List"} />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <Container>
                        <Card className='px-4 py-2 mt-3'>
                            <Row className='mt-3'>
                                <Col xs={12} sm={12} md={8} className='mb-1'>
                                    <h6 className='heading-1'>Co-Travelers List</h6>
                                </Col>
                                <Col xs={12} sm={12} md={4} className='mb-1 d-flex justify-content-end'>
                                    <Button className='mx-2' variant='contained' color='primary' size='small' onClick={() => { setToggle(!toggle) }}><SearchIcon style={{ fontSize: 16 }} />&nbsp;Search</Button>
                                    <Link to="/add-travelers"><Button variant='contained' color='success' size='small' onClick={() => { setToggle(!toggle) }}>Add</Button></Link>
                                </Col>
                            </Row>
                            {toggle &&
                                <Card className='p-3 mt-3'>
                                    <Row>
                                        <Col xs={12} sm={12} md={3} >
                                            <Box className="setBox ">
                                                <div className="tlthedae">Traveler Name</div>
                                                <Stack>
                                                    <Autocomplete
                                                        id="tags-standard"
                                                        options={initialUserList}
                                                        getOptionLabel={(option) => option.first_name + " " + option.middle_name + " " + option.last_name}
                                                        getOptionSelected={(option, value) => option.id === value.id}
                                                        value={selectedTravelers}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                placeholder="Select Travelers"
                                                            />
                                                        )}
                                                        onChange={(event, newValue) => {
                                                            if (newValue !== null) {
                                                                setSelectedTravelers(newValue);
                                                                setSelectedUserId(newValue?.user_id);
                                                            }
                                                        }}
                                                    />
                                                </Stack>
                                            </Box>
                                        </Col>
                                        <Col xs={12} sm={12} md={3} >
                                            <Box className="setBox ">
                                                <div className="tlthedae">Relationship</div>
                                                <FormControl fullWidth>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={relation}
                                                        onChange={handleRelationChange}
                                                    >
                                                        <MenuItem value={'mother'}>Mother</MenuItem>
                                                        <MenuItem value={'father'}>Father</MenuItem>
                                                        <MenuItem value={'spouse'}>Spouse</MenuItem>
                                                        <MenuItem value={'children'}>Children</MenuItem>
                                                        <MenuItem value={'others'}>Others</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Col>
                                        <Col xs={12} sm={12} md={3} className='mt-1'>
                                            <Button variant='contained' size="small" color='success' className='mb-3 mt-3' onClick={() => getSearchedUserList(userDetails?.userId, selectedUserId, relation)}>Search</Button>
                                            <Button variant='contained' size="small" color='warning' className='mb-3 mt-3 mx-2' onClick={() => handleClear()}>Clear</Button>
                                        </Col>
                                    </Row>
                                </Card>
                            }
                            <Row className='my-3'>
                                {/* <Col xs={12} sm={12} md={12} className='mb-2'>
                                    <h6 className='heading-1'>Hotel Inventory List</h6>
                                </Col> */}
                                <Col xs={12} md={12}>
                                    <TableContainer >
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left">Traveler Name</TableCell>
                                                    <TableCell align="left">Relationship</TableCell>
                                                    <TableCell align="right">Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {(userList.length && !isLoading) > 0 ? (
                                                    <>
                                                        {userList?.map((item, index) => (
                                                            <TableRow
                                                                key={index}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell align="left" className='text-capitalize'>{item?.title + " " + item?.first_name + " " + item?.middle_name + " " + item?.last_name}</TableCell>
                                                                <TableCell align="left" className='text-capitalize'>{item?.relationship}</TableCell>
                                                                <TableCell align="right" >
                                                                    <Link to={"/update-traveler?" + item?.user_id} className=' btn btn-success'><b>Edit</b></Link>
                                                                </TableCell>

                                                            </TableRow>
                                                        ))}
                                                    </>
                                                ) : (isLoading && itineraryList.length === 0) ? (
                                                    <TableRow>
                                                        <TableCell align="left" colspan="6">
                                                            <LinearProgress color="success" />
                                                        </TableCell>
                                                    </TableRow>
                                                ) : (!isLoading && itineraryList.length === 0) ? (
                                                    <TableRow>
                                                        <TableCell align="center" colspan="6"> <p><b>No data found.</b></p></TableCell>
                                                    </TableRow>
                                                ) : (
                                                    <TableRow>
                                                        <TableCell align="left" colspan="6">
                                                            <LinearProgress color="success" />
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    {totalRecords > 1 && (
                                        <Box className="my-2 px-2 float-right">
                                            <Stack spacing={2}>
                                                <Pagination page={currentPage} count={totalRecords} onChange={(e, index) => getUserList(index)} variant="outlined" color="primary" />
                                            </Stack>
                                        </Box>
                                    )}
                                </Col>
                            </Row>
                        </Card>
                    </Container>
                </Box>
            </Box>
            <MainFooter />
        </>
    )
}

export default TravelersList
