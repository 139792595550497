import React, { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

const PrivateRoutes = () => {
    let loginData = localStorage.getItem("login_data");
    let auth = JSON.parse(loginData)?.status === true ? true : false;

   // console.log("loginData", loginData)
    return (
        auth ? <Outlet /> : <Navigate to="/login" />
    );
}

export default PrivateRoutes