import React, { useState, useEffect, useCallback } from 'react'
import Grid from '@mui/material/Grid'
import {
  FormControlLabel,
  MenuItem,
  Select
} from '@mui/material'
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Checkbox from '@mui/material/Checkbox'
import './flights.css'
import { getAuth } from '../../../utility/apiConfig'
import { API_BASE_URL } from '../../../utility/apiConstants'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import flightSearchReducers from '../../../store/flights/reducers/flightSearchReducers'
import { airports } from '../../../services/airportList'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: "100% !important",
    backgroundColor: theme.palette.background.paper
  }
}))

const fareTypeList = [
  { title: 'Lite', value: 'Lite' },
  { title: 'Regular', value: 'Regular' },
  { title: 'Corporate', value: 'Corporate' },
  { title: 'SME', value: 'SME' },
  { title: 'Friend and Family', value: 'Friend_Family' },
  { title: 'Sale', value: 'Sale' },
  { title: 'Promo', value: 'Promo' },
  { title: 'Flexi', value: 'Flexi' },
]

const OneWay = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch(flightSearchReducers)
  const history = useNavigate()

  const [showMore, setShowMore] = useState(false)
  const [depDate, setDepDate] = useState('')
  const [classType, setClassType] = useState('Economy')
  const [open, setOpen] = useState(false)

  const [fromAirport, setFromAirport] = useState([])
  const [fromValue, setFromValue] = useState(null)
  const [fromInValue, setFromInValue] = useState('')

  const [toAirport, setToAirport] = useState([])
  const [toValue, setToValue] = useState(null)
  const [toInValue, setToInValue] = useState('')

  const [prefAirline, setprefAirline] = useState([])
  const [prefAirValue, setPrefAirValue] = useState(null)
  const [prefAirInValue, setPrefAirInValue] = useState('')

  const [isOpenDepart, setIsOpenDepart] = useState('')

  const [showPassClass, setShowPassClass] = useState(false)
  const [showTravellerCount, setShowTravellerCount] = useState(false)
  const [showPassClassBtn, setShowPassClassBtn] = useState(true)

  const togglePassClass = () => {
    setShowPassClass(!showPassClass)
  }

  const getTavellerCount = () => {
    setShowPassClassBtn(false)
    setShowTravellerCount(true)
    setShowPassClass(false)

  }
  const [oneWay, setOneWay] = useState({
    trip_type: 'oneway',
    from: '',
    from_loc_id: '',
    to: '',
    to_loc_id: '',
    depature: '',
    adult: 1,
    child: 0,
    infant: 0,
    nonStop: false,
    studentFare: false,
    search_flight: 'search',
    v_class: 'Economy',
    carrier: [],
    specialFare: false,
    rating: [],
    fareType: [],
    fromCountry: null,
    toCountry: null,
  })

  const [oneWayError, setOneWayError] = useState({
    fromError: '',
    toError: '',
    depatureError: ''
  })

  function formatDate(date) {
    let d = new Date(date);
    let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
    let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    let newDate = `${da}-${mo}-${ye}`
    return newDate;
  }

  const handleDateChange = (date) => {
    setOneWayError({ ...oneWayError, depatureError: '' })
    props.setTripError({ ...props.tripError, depatureError: '' })
    setDepDate(formatDate(date))
    props.setDepDate(date)
    props.setPropsDepDate(formatDate(date))
    setOneWay({
      ...oneWay,
      depature: formatDate(date)
    })
    props.setTrip({
      ...props.trip,
      depature: formatDate(date)
    })
  }

  const onClickAdult = (type) => {
    if (type === 'increment') {
      if (oneWay.adult < 9) {
        setOneWay({
          ...oneWay,
          adult: oneWay.adult + 1,
          child: 0,
          infant: 0
        })
      }
    } else {
      if (oneWay.adult > 1) {
        setOneWay({
          ...oneWay,
          adult: oneWay.adult - 1,
          child: 0,
          infant: 0
        })
      }
    }
  }

  const onClickChild = (type) => {
    if (type === 'increment') {
      if (oneWay.child < (9 - oneWay.adult)) {
        setOneWay({
          ...oneWay,
          child: oneWay.child + 1
        })
      }
    } else {
      if (oneWay.child > 0) {
        setOneWay({
          ...oneWay,
          child: oneWay.child - 1
        })
      }
    }
  }

  const onClickInfants = (type) => {
    if (type === 'increment') {
      if (oneWay.infant < oneWay.adult) {
        setOneWay({
          ...oneWay,
          infant: oneWay.infant + 1
        })
      }
    } else {
      if (oneWay.infant > 0) {
        setOneWay({
          ...oneWay,
          infant: oneWay.infant - 1
        })
      }
    }
  }

  const onClickNonStop = () => {
    setOneWay({
      ...oneWay,
      nonStop: !oneWay.nonStop
    })
  }

  const onClickStudentFare = () => {
    setOneWay({
      ...oneWay,
      studentFare: !oneWay.studentFare
    })
  }

  const onClickSpecialFare = () => {
    setOneWay({
      ...oneWay,
      specialFare: !oneWay.specialFare
    })
  }

  const rateSelect = () => {
    const ss = fareTypeList.filter((d, f) => {
      if (oneWay.fareType.includes(d.value)) { return d }
    })

    setOneWay({
      ...oneWay,
      fareType: ss

    })
  }

  const showMoreToggle = () => {
    setShowMore(!showMore)
    rateSelect()
  }

  const handleClassChange = (event) => {
    setClassType(event.target.value)
    setOneWay({
      ...oneWay,
      v_class: event.target.value
    })
  }

  const handleClassClose = () => {
    setOpen(false)
  }

  const handleClassOpen = () => {
    setOpen(true)
  }

  // const getAirportList = (search) => {
  //   try {
  //     getAuth(API_BASE_URL + 'data/get_airport_code_list/' + search, null)
  //       .then((response) => {

  //         if (response.data.status) {
  //           setFromAirport(response.data.data)
  //           setToAirport(response.data.data)
  //         } else {
  //           setFromAirport([])
  //           setToAirport([])
  //         }
  //       }).catch((error) => {
  //       })
  //   } catch (error) {
  //   }
  // }

  const getAirline = useCallback(
    (searching) => {
      try {
        getAuth(API_BASE_URL + 'data/get_airline/' + searching, null)
          .then((response) => {

            if (response.data.status) {
              setprefAirline(response.data.data)
            } else {
              setprefAirline([])
            }
          }).catch((error) => {
          })
      } catch (error) {
      }
    })

  // const getFromAirportList = useCallback(
  //   (search) => {
  //     getAuth(API_BASE_URL + 'data/get_airport_code_list/' + search, null)
  //       .then((response) => {
  //         let newOptions = []
  //         if (response.data.status) {
  //           newOptions = [...newOptions, ...(response.data.data)]
  //         }
  //         setFromAirport(newOptions)
  //       }).catch((error) => {
  //       })
  //   })

  // const getToAirportList = useCallback(
  //   (search) => {
  //     getAuth(API_BASE_URL + 'data/get_airport_code_list/' + search, null)
  //       .then((response) => {
  //         let newOptions = []
  //         if (response.data.status) {
  //           newOptions = [...newOptions, ...(response.data.data)]
  //         }
  //         setToAirport(newOptions)
  //       }).catch((error) => {
  //       })
  //   })

  const airportSwitch = () => {
    setToValue(fromValue)
    setFromValue(toValue)

    setOneWay({
      ...oneWay,
      to: oneWay.from,
      to_loc_id: oneWay.from_loc_id,
      from: oneWay.to,
      from_loc_id: oneWay.to_loc_id
    })

    setOneWayError({
      ...oneWayError,
      fromError: '',
      toError: ''
    })
  }

  const filterOptions = (options, state) => {
    const { inputValue } = state

    if (!inputValue) {
      return options.slice(0, 10)
    }
    const arr = airports.filter((item) => {
      return item.airport_code.toLowerCase() === inputValue.toLowerCase()
    })

    if (arr && arr.length > 0) {
      return arr
    } else {
      const cityArr = airports.filter((item) => {
        return item.airport_city.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      })
      return cityArr && cityArr.length > 0 ? cityArr.slice(0, 20) : []
    }
  }


  const oneWaySearch = () => {

    // const fromEr = fromVali(oneWay.from, oneWay.to)
    // const toEr = toVali(oneWay.to, oneWay.from)
    // const depEr = depVali(oneWay.depature)

    // if (fromEr || toEr || depEr) {
    //   setOneWayError({
    //     ...oneWayError,
    //     fromError: fromEr,
    //     toError: toEr,
    //     depatureError: depEr
    //   })
    //   return
    // }

    // history.push({
    //   pathname: '/flight/search',
    //   state: oneWay
    // })
    console.log("OnewaySearchReq", oneWay)
    props.setSearchObj(oneWay);
    props.setShowModify(false);
  }

  // useEffect(() => {
  //   // getAirportList('')
  //   setOneWay({
  //     ...oneWay, 
  //     from: props.fromAirport[0]?.airport_city + ` (${props.fromAirport[0]?.airport_code})`, 
  //     from_loc_id: props.trip.from_loc_id,
  //     to: props.toAirport[0]?.airport_city + ` (${props.toAirport[0]?.airport_code})`, 
  //     to_loc_id: props.trip.to_loc_id,
  //     depature: props.depDate,
  //     fromCountry: props.fromCountry, 
  //     toCountry: props.toCountry,
  //     adult: props.searchObj.adult ? props.searchObj.adult : 1,
  //     child: props.searchObj.child ? props.searchObj.child : 0
  //   });
  //   setOneWayError(props.tripError);
  //   setFromAirport(props.fromAirport);
  //   setFromValue(props.fromValue);
  //   setToValue(props.toValue);
  //   setToAirport(props.toAirport);
  //   setIsOpenDepart(props.isOpenDepart);
  //   setDepDate(props.depDate);
  // }, [])

  return (
    <div>
      <form noValidate autoComplete="off">
        <Box className="search-wpr">
          <Grid container spacing={0} className="cntrowswp">
            <Grid item md={3} xs={12} className="prstColl mob-input-border">
              <Box textAlign="left" className="box-tlt box-input-w">
                <span>From</span>

                <Autocomplete
                  className="inputFieldLocation"
                  size="small"
                  id="free-solo-demo"
                  freeSolo
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  options={airports}
                  filterOptions={filterOptions}
                  getOptionLabel={(option) => option.airport_city + " (" + option.airport_code + ")"}

                  renderOption={(props, option) => (
                    <li {...props}>
                      <Box className="searchsuggestionbox">
                        <Box className="flightdetails-code">
                          <Box className="cityfly">{option.airport_city}, {option.country}</Box>
                          <Box className="airportcode">{option.airport_code}</Box>
                        </Box>
                        <Box className="airport-name">{option.airport_name}</Box>
                      </Box>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="normal"
                      variant="outlined"
                      placeholder="Type atleast 3 letters"
                      className="inputFieldLocation"
                      error={!!oneWayError.fromError}
                      helperText={oneWayError.fromError}

                    />
                  )}

                  // filterOptions={(x) => x}
                  value={fromValue}
                  onChange={(event, newValue) => {
                    //  setOneWay({...oneWay, from:newValue})
                    setOneWayError({ ...oneWayError, fromError: '' })
                    setFromAirport(newValue ? [newValue, ...fromAirport] : fromAirport)
                    setFromValue(newValue);
                    props.setTripError({ ...props.tripError, fromError: '' })
                    props.setFromAirport(newValue ? [newValue, ...fromAirport] : fromAirport)
                    props.setFromValue(newValue);
                    props.setPropsFromAirport(newValue ? [newValue, ...fromAirport] : fromAirport)
                    props.setPropsFromValue(newValue)
                    // setSwitchB(newValue);
                    if (newValue) {
                      setOneWay({
                        ...oneWay,
                        from: newValue.airport_city + ' (' + newValue.airport_code + ')',
                        from_loc_id: newValue.id,
                        fromCountry: newValue.country
                      })
                      props.setTrip({
                        ...props.trip,
                        from: newValue.airport_city + ' (' + newValue.airport_code + ')',
                        from_loc_id: newValue.id
                      })
                      props.setFromCountry(newValue.country)
                      props.setPropsFromCountry(newValue.country)
                    }
                  }}
                />

              </Box>

            </Grid>

            <Grid item md={1} xs={12} className="prstColl m-auto">
              <Box className="box-swpicon-w">
                <Box className="icoswipe swipebox" onClick={airportSwitch}><SwapHorizIcon /></Box>
              </Box>
            </Grid>

            <Grid item md={3} xs={12} className="prstColl mob-input-border">

              <Box textAlign="left" className="box-tlt box-input-w">
                <span>To</span>

                <Autocomplete
                  className="inputFieldLocation"
                  size="small"
                  id="free-solo-demo"
                  freeSolo
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  options={airports}
                  filterOptions={filterOptions}
                  getOptionLabel={(option) => option.airport_city + " (" + option.airport_code + ")"}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Box className="searchsuggestionbox">
                        <Box className="flightdetails-code">
                          <Box className="cityfly">{option.airport_city}, {option.country}</Box>
                          <Box className="airportcode">{option.airport_code}</Box>
                        </Box>
                        <Box className="airport-name">{option.airport_name}</Box>
                      </Box>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="normal"
                      variant="outlined"
                      placeholder="Type atleast 3 letters"
                      className="inputFieldLocation"
                      error={!!oneWayError.toError}
                      helperText={oneWayError.toError}
                    />
                  )}

                  // filterOptions={(x) => x}
                  value={toValue}
                  onChange={(event, newValue) => {

                    setOneWay({ ...oneWay, to: newValue })
                    setOneWayError({ ...oneWayError, toError: '' })
                    setToAirport(newValue ? [newValue, ...toAirport] : toAirport)
                    setToValue(newValue)
                    setIsOpenDepart(true)
                    // setSwitchA(newValue);
                    props.setTrip({ ...props.trip, to: newValue })
                    props.setTripError({ ...props.tripError, toError: '' })
                    props.setToAirport(newValue ? [newValue, ...toAirport] : toAirport)
                    props.setPropsToAirport(newValue ? [newValue, ...toAirport] : toAirport)
                    props.setPropsToValue(newValue)
                    props.setToValue(newValue)
                    props.setIsOpenDepart(true)

                    if (newValue) {
                      setOneWay({
                        ...oneWay,
                        to: newValue.airport_city + ' (' + newValue.airport_code + ')',
                        to_loc_id: newValue.id,
                        toCountry: newValue.country
                      })
                      props.setTrip({
                        ...props.trip,
                        to: newValue.airport_city + ' (' + newValue.airport_code + ')',
                        to_loc_id: newValue.id
                      })
                      props.setToCountry(newValue.country)
                      props.setPropsToCountry(newValue.country)
                    }
                  }}
                  // onInputChange={(event, newInputValue) => {
                  //   if (newInputValue) {
                  //     setToInValue(newInputValue)
                  //     getToAirportList(newInputValue)
                  //   }
                  // }}
                  clearOnEscape={true}

                />
              </Box>
            </Grid>

            <Grid item md={5} xs={12} className="prstColl d-flex mob-input-border">
              <span className='border-right div-border-right'></span>
              <Box textAlign="left" className="box-tlt box-input-calender">
                <span>Departure</span>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker', 'DatePicker']}>
                    <DatePicker
                      className="inputDate"
                      size="small"
                      disableToolbar
                      variant="inline"
                      inputVariant="outlined"
                      format="DD-MMM-YYYY"
                      margin="normal"
                      id="date-picker-inline"
                      disablePast
                      value={depDate}
                      onChange={handleDateChange}
                      autoOk
                      InputProps={{ readOnly: true }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                      error={!!oneWayError.depatureError}
                      helperText={oneWayError.depatureError}
                      placeholder="Depature Date"

                      //
                      onChange={newDate => {
                        handleDateChange(newDate)
                        setIsOpenDepart(false)
                        props.setIsOpenDepart(false);
                      }}
                      KeyboardButtonProps={{
                        onFocus: e => {
                          setIsOpenDepart(true)
                          props.setIsOpenDepart(true);
                        }
                      }}
                      PopoverProps={{
                        disableRestoreFocus: true,
                        onClose: () => {
                          setIsOpenDepart(false)
                          props.setIsOpenDepart(false);
                        }
                      }}
                      InputProps={{
                        onFocus: () => {
                          setIsOpenDepart(true)
                          props.setIsOpenDepart(true);
                        }
                      }}
                      open={isOpenDepart}
                    //    
                    />
                  </DemoContainer>
                </LocalizationProvider>

              </Box>
              <span className='border-right div-border-right'></span>
              <Box textAlign="left" className="box-tlt box-input-passclass">
                <span>Passengers & Class</span>
                <h6 onClick={togglePassClass} className='trav-txt'><span className='trav-count'>{oneWay.adult + oneWay.child + oneWay.infant}</span> Travellers <p className='class-txt'>{oneWay.v_class}</p></h6>
                {showPassClass ? (
                  <Box className='passclass-box'>
                    <Grid container spacing={0} className="rowGap">
                      <Grid item md={12}>
                        <Box>
                          <Box textAlign="left" className="box-tlt">
                            adult (12 Yrs +)
                          </Box>
                          <ButtonGroup
                            size="small"
                            aria-label="small outlined button group"
                            className="btnGroup"
                          >
                            <Button className="btnGroup" onClick={() => onClickAdult('decrement')}>-</Button>
                            <Button className="btnGroup">
                              {oneWay.adult}
                            </Button>
                            <Button className="btnGroup" onClick={() => onClickAdult('increment')}>+</Button>
                          </ButtonGroup>
                        </Box>
                      </Grid>
                      <Grid item md={12}>
                        <Box>
                          <Box textAlign="left" className="box-tlt">
                            child (2 yrs - 12 Yrs)
                          </Box>
                          <ButtonGroup
                            size="small"
                            aria-label="small outlined button group"
                            className="btnGroup"
                          >
                            <Button className="btnGroup" onClick={() => onClickChild('decrement')}>-</Button>
                            <Button className="btnGroup">
                              {oneWay.child}
                            </Button>
                            <Button className="btnGroup" onClick={() => onClickChild('increment')}>+</Button>
                          </ButtonGroup>
                        </Box>
                      </Grid>
                      <Grid item lg={12}>
                        <Box>
                          <Box textAlign="left" className="box-tlt">
                            infant (Below 2 Yrs)
                          </Box>
                          <ButtonGroup
                            size="small"
                            aria-label="small outlined button group"
                            className="btnGroup"
                          >
                            <Button className="btnGroup" onClick={() => onClickInfants('decrement')}>-</Button>
                            <Button className="btnGroup">
                              {oneWay.infant}
                            </Button>
                            <Button className="btnGroup" onClick={() => onClickInfants('increment')}>+</Button>
                          </ButtonGroup>
                        </Box>
                      </Grid>
                      <Grid item md={12} >
                        <Box className="divSet class-wrp py-2">
                          <Box textAlign="left" className="box-tlt">
                            Class
                          </Box>
                          <Select
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            open={open}
                            onClose={handleClassClose}
                            onOpen={handleClassOpen}
                            value={classType}
                            onChange={handleClassChange}
                            variant="outlined"
                            className="ClassSelector"
                          >
                            <MenuItem className="items-list" selected={true} value="Economy">
                              Economy
                            </MenuItem>
                            <MenuItem className="items-list" value="Premium Economy">
                              Premium Economy
                            </MenuItem>
                            <MenuItem className="items-list" value="Business">
                              Business
                            </MenuItem>
                          </Select>
                        </Box>
                      </Grid>
                      <Grid item md={12} xs={12} className="pt-2">
                        <Button
                          variant="contained"
                          color="primary"
                          className="btnSend"
                          onClick={getTavellerCount}
                        >
                          Apply
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                ) : ""}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Grid container spacing={0} className="mt-2">
          <Grid item sm={10} xs={6}>
            <FormControlLabel
              className="nonstop-tlt"
              control={
                <Checkbox
                  checked={oneWay.nonStop}
                  onChange={onClickNonStop}
                  name="nonStop"
                />
              }
              label="Non-Stop"
              color="secondary"
            />

          </Grid>

          <Grid item sm={2} xs={6} className="text-right mt-1">
            <Button
              variant="contained"
              color="primary"
              className="btnSend"
              onClick={oneWaySearch}
            >
              Search Flight
            </Button>
          </Grid>
        </Grid>

      </form>

    </div>
  )
}

export default OneWay
