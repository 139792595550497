import { combineReducers } from 'redux'
import flightBookingSSRReducers from './flights/reducers/flightBookingSSRReducers'
import { flightFilterReducers } from './flights/reducers/flightFilterReducers'
import flightSearchReducers, { minMaxReducers } from './flights/reducers/flightSearchReducers'
import changePasReducers from './user/reducers/changePasReducers'
import forgotReducers from './user/reducers/forgotReducers'
import loginReducers from './user/reducers/loginReducers'
import profileReducers from './user/reducers/profileReducers'
import userAccReducer from './user/reducers/userAccReducer'

const rootReducers = combineReducers({
  login: loginReducers,
  forgot: forgotReducers,
  user: userAccReducer,
  flightResult: flightSearchReducers,
  rangeValue: minMaxReducers,
  filters: flightFilterReducers,
  ssrRequest: flightBookingSSRReducers,
  profile: profileReducers,
  changePas: changePasReducers
})

export default rootReducers
