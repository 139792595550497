import { Box, Checkbox, FormControlLabel } from '@mui/material'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

const Airlinesfilter = (props) => {
  const { handleChange, airlineClear, isSelected } = props
  const allAirline = useSelector(state => state.rangeValue.airlines)

  const onCheckAirline = (e) => {
    handleChange(e.target.name, e.target.checked)
  }

  const clear = () => {
    airlineClear()
  }

  useEffect(() => {
  }, [props])

  return (
        <div className="airlines-lists">
            <div className="tlt-airline-tag">
                <Box className="tlt-heading">Airlines</Box>
                <Box className="tlt-clear" onClick={clear}>Clear</Box>
            </div>
            {!!allAirline && (
                <Box className="checkboxforairlines">
                    <ul className="airlines-ul">
                        {allAirline.map((value, key) => (
                            <li key={key}>
                                <Box className="items-list-airlines">
                                    <Box className="checkbox-itemsset">
                                        <FormControlLabel
                                            value={`₹ ${value.price}`}
                                            control={<Checkbox color="primary" checked={!!(isSelected && isSelected.length > 0 && isSelected.includes(value.name))} />}
                                            label={value.name}
                                            labelPlacement="end"
                                            onChange={onCheckAirline}
                                            name={value.name}
                                        />
                                    </Box>
                                    <Box className="tlt-items-tag">{`₹ ${Math.round(value.price)}`}</Box>
                                </Box>
                            </li>
                        ))}
                    </ul>
                </Box>
            )}
        </div>
  )
}

export default Airlinesfilter
