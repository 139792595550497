import { CHANGEPASS_LOADER, CHANGEPASS_SUCCESS, CHANGEPASS_ERROR } from '../actions/changePasActions'
const initialChangePasState = {
  isLoading: false,
  changePassMsg: ''
}

const changePasReducers = (state = initialChangePasState, { type, payload }) => {
  switch (type) {
    case CHANGEPASS_LOADER:
      return {
        ...state,
        isLoading: true
      }
    case CHANGEPASS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        changePassMsg: payload
      }
    case CHANGEPASS_ERROR:
      return {
        ...state,
        isLoading: false,
        changePassMsg: payload
      }
    default:
      return state
  }
}

export default changePasReducers
