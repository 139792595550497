import { Box, Button, ButtonGroup } from '@mui/material'
import { Col, Row } from 'react-bootstrap';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { stops } from '../../../../store/flights/actions/flightFilterActions'
import './filterstyle.css'

const Stops = (props) => {
  const { handleChange, isStops } = props

  const filters = useSelector(state => state.filters)
  const dispatch = useDispatch()
  const [stop, setStop] = useState(0)

  const changeStops = (data) => {
    let result = null
    if(data == isStops)
    {
      result =  null
    }else{
      result = data
    }
    handleChange(result)

    dispatch(stops(result))
  }

  return (
        <div className="stops-filter">
            <Box className="tlt-heading">Stops</Box>
            <Row size="small" aria-label="small outlined button" className="stopsgroup">
              <Col md={6} xs={6} className="">
                <Button className={isStops === 0 ? 'stopsgroup-item active-button' : 'stopsgroup-item'} onClick={() => changeStops(0)}>0 Stop</Button>
              </Col>
              <Col md={6} xs={6} className="">
                <Button className={isStops === 1 ? 'stopsgroup-item active-button' : 'stopsgroup-item'} onClick={() => changeStops(1)}>1 Stop</Button>
              </Col>
              <Col md={6} xs={6}  className="">
                <Button className={isStops === 2 ? 'stopsgroup-item active-button' : 'stopsgroup-item'} onClick={() => changeStops(2)}>2 Stop</Button>
              </Col>
              <Col md={6} xs={6} className="">
                <Button className={isStops === 3 ? 'stopsgroup-item active-button' : 'stopsgroup-item'} onClick={() => changeStops(3)}>3+ Stop</Button>
              </Col>
            </Row>
        </div>
  )
}

export default Stops
