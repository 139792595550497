import axios from 'axios'
import { authPost } from '../../../utility/apiConfig'
import { API_BASE_URL, FLIGHT_API_URL } from '../../../utility/apiConstants'
export const FS_LOADING = 'FS_LOADING'
export const FS_ROUND_LOADING = 'FS_ROUND_LOADING'
export const FS_MULTI_LOADING = 'FS_MULTI_LOADING'

export const FS_REQUEST = 'FS_REQUEST'
export const FS_ROUND_REQ = 'FS_ROUND_REQ'
export const FS_MULTI_REQ = 'FS_MULTI_REQ'

export const FS_SUCCESS = 'FS_SUCCESS'
export const FS_INV_SUCCESS = 'FS_INV_SUCCESS'
export const FS_ONEWAY_SUCCESS = 'FS_ONEWAY_SUCCESS'
export const FS_ONEWAY_COUNT = 'FS_ONEWAY_COUNT'

export const FS_ROUND_SUCCESS = 'FS_ROUND_SUCCESS'
export const FS_MULTI_SUCCESS = 'FS_MULTI_SUCCESS'

export const FS_ERROR = 'FS_ERROR'
export const FS_ROUND_ERROR = 'FS_ROUND_ERROR'
export const FS_MULTI_ERROR = 'FS_MULTI_ERROR'

export const FS_NULL = 'FS_NULL'
export const FS_ROUND_NULL = 'FS_ROUND_NULL'
export const FS_MULTI_NULL = 'FS_MULTI_NULL'

export const MIN_MAX = 'MIN_MAX'
export const AIRLINES_UNI = 'AIRLINES_UNI'

export const FS_ROUND_COUNT = 'FS_ROUND_COUNT'
export const FS_ROUND_COMPLETE_DATA = 'FS_ROUND_COMPLETE_DATA'

export const FS_ROUND_INTERNATIONAL_COUNT = 'FS_ROUND_INTERNATIONAL_COUNT'
export const FS_ROUND_INTERNATIONAL_DATA = 'FS_ROUND_INTERNATIONAL_DATA'
export const FS_ROUND_INTERNATIONAL_ERROR = 'FS_ROUND_INTERNATIONAL_ERROR'
export const FS_ROUND_INTERNATIONAL_LOADING = 'FS_ROUND_INTERNATIONAL_LOADING'


// let userId;
// let allowedUserId = [ '2', '3', '5', '6', '13']
// export let bookingSources = []
// let loginData = localStorage.getItem("login_data");

// if(loginData)
// {
//   loginData = JSON.parse(loginData);
//   userId = loginData && loginData.data &&  loginData.data.user_id;
// }

// if(allowedUserId.indexOf(userId) > -1)
// {
//   // bookingSources = ['PTBSID0000000013', 'PTBSID0000000014', 'PTBSID0000000010', 'PTBSID0000000015', 'PTBSID0000000016', 'PTBSID0000000012', 'PTBSID0000000017']
//   bookingSources = ['PTBSID0000000002', 'PTBSID0000000008', 'PTBSID0000000009', 'PTBSID0000000013', 'PTBSID0000000014', 'PTBSID0000000010', 'PTBSID0000000015', 'PTBSID0000000016', 'PTBSID0000000012', 'PTBSID0000000017']
// }else{
//   bookingSources = ['PTBSID0000000002']
// }
export let bookingSources = []
bookingSources = ['PTBSID0000000002', 'PTBSID0000000007', 'PTBSID0000000008', 'PTBSID0000000009', 'PTBSID0000000013', 'PTBSID0000000014', 'PTBSID0000000010', 'PTBSID0000000015', 'PTBSID0000000016', 'PTBSID0000002223', 'PTBSID0000002222', 'PTBSID0000000019', 'PTBSID0000000020', 'PTBSID0000000021', 'PTBSID0000000018', 'PTBSID0000002224']
export let specialBookingSources = []
specialBookingSources = ['PTBSID0000002223', 'PTBSID0000002222', 'PTBSID0000000018', 'PTBSID0000002224']

export const setMinMaxValue = (data) => {
  return {
    type: MIN_MAX,
    payload: data
  }
}

export const setAllAirline = (data) => {
  return {
    type: AIRLINES_UNI,
    payload: data
  }
}

const fsLoading = () => {
  return {
    type: FS_LOADING
  }
}

const fsRoundLoading = () => {
  return {
    type: FS_ROUND_LOADING
  }
}

const fsMultiLoading = () => {
  return {
    type: FS_MULTI_LOADING
  }
}

export const fsDataNull = () => {
  return {
    type: FS_NULL
  }
}

export const fsRoundDataNull = () => {
  return {
    type: FS_ROUND_NULL
  }
}

export const fsMultiDataNull = () => {
  return {
    type: FS_MULTI_NULL
  }
}

export const fsRequest = (request) => {
  return {
    type: FS_REQUEST,
    payload: request
  }
}

export const fsRoundRequest = (request) => {
  return {
    type: FS_ROUND_REQ,
    payload: request
  }
}

export const fsMultiRequest = (request) => {
  return {
    type: FS_MULTI_REQ,
    payload: request
  }
}

const fsSuccess = (data) => {
  return {
    type: FS_SUCCESS,
    payload: data
  }
}

const fsOnwaySuccess = (data, i) => {
  return {
    type: FS_ONEWAY_SUCCESS,
    payload: data,
    index: i
  }
}
const fsOnewayCount = (data) => {
  return {
    type: FS_ONEWAY_COUNT,
    payload: data
  }
}

const fsInvSuccess = (data) => {
  return {
    type: FS_INV_SUCCESS,
    payload: data
  }
}

const fsRoundSuccess = (data) => {
  return {
    type: FS_ROUND_SUCCESS,
    payload: data
  }
}

const fsMultiSuccess = (data) => {
  return {
    type: FS_MULTI_SUCCESS,
    payload: data
  }
}

const fsError = (error) => {
  return {
    type: FS_ERROR,
    payload: error
  }
}

const fsRoundError = (error) => {
  return {
    type: FS_ROUND_ERROR,
    payload: error
  }
}

const fsMultiError = (error) => {
  return {
    type: FS_MULTI_ERROR,
    payload: error
  }
}

const fsRoundCount = (data) => {
  return {
    type: FS_ROUND_COUNT,
    payload: data
  }
}

const fsRoundCompleteData = (data) => {
  return {
    type: FS_ROUND_COMPLETE_DATA,
    payload: data
  }
}
const fsRoundInternationalCount = (data) => {
  return {
    type: FS_ROUND_INTERNATIONAL_COUNT,
    payload: data
  }
}
const fsRoundInternationalData = (data) => {
  return {
    type: FS_ROUND_INTERNATIONAL_DATA,
    payload: data
  }
}
const fsRoundInternationalError = (data) => {
  return {
    type: FS_ROUND_INTERNATIONAL_ERROR,
    payload: data
  }
}
const fsRoundInternationalLoading = () => {
  return {
    type: FS_ROUND_INTERNATIONAL_LOADING
  }
}

export const flightSearchRequest = (request) => {
  return (dispatch) => {
    try {
      if (request.trip_type === 'oneway') {
        dispatch(fsInvSuccess(null))
        dispatch(fsOnewayCount(null))
        dispatch(fsError(null))
        dispatch(fsLoading())
        dispatch(fsDataNull())
        dispatch(fsRequest(request))

        let allFlight = []
        let i = 0
        let errorCount = 0
        if (request.trip_type === 'oneway') {
          request.booking_source = 'PTBSID0000002021'
          authPost(FLIGHT_API_URL + 'general/pre_flight_search_inv', request)
            .then((response) => {
              if (response.data.status) {
                if (response.data?.data?.data?.raw_flight_list?.Flights[0] !== undefined) {
                  // allFlight.push(response.data?.data?.raw_flight_list?.Flights[0])
                  const dd = response.data?.data?.data?.raw_flight_list?.Flights[0]
                  allFlight = [...allFlight, ...dd]
                  dispatch(fsSuccess(allFlight))
                  dispatch(fsInvSuccess(response.data))
                }
              } else {
                ++errorCount
                if (errorCount === bookingSources.length + 1 && !request.specialFare) {
                  dispatch(fsError('Sorry, No flights found for this route.'))
                }
                if (errorCount === 1 && request.specialFare) {
                  dispatch(fsError('Sorry, No flights found for this route.'))
                }
              }
              dispatch(fsOnewayCount(parseInt(++i)))
            }).catch(() => {
              ++errorCount
              if (errorCount === bookingSources.length + 1 && !request.specialFare) {
                dispatch(fsError('Sorry, No flights found for this route.'))
              }
              if (errorCount === 1 && request.specialFare) {
                dispatch(fsError('Sorry, No flights found for this route.'))
              }
              dispatch(fsOnewayCount(parseInt(++i)))
            })
        }

        // let reqArr = []
        // bookingSources.map((source)=> {
        //     request.booking_source = source;
        //     reqArr.push(authPost(FLIGHT_API_URL + 'general/pre_flight_search', request))
        // })

        // axios.all([...reqArr]).then((...res) => {
        //   });

        if (request.specialFare) {
          specialBookingSources.map((source, index) => {
            request.booking_source = source

            authPost(FLIGHT_API_URL + 'general/pre_flight_search', request)
              .then((response) => {
                if (response.data.status) {
                  //  flightData.push(response.data.data)
                  dispatch(fsOnwaySuccess(response.data.data, index))
                  if (response?.data?.data?.data?.raw_flight_list?.Flights[0] !== undefined) {
                    // allFlight.push(response?.data?.data?.data?.raw_flight_list?.Flights[0])
                    const dd = response?.data?.data?.data?.raw_flight_list?.Flights[0]
                    allFlight = [...allFlight, ...dd]
                    dispatch(fsSuccess(allFlight))
                    dispatch(fsInvSuccess(response.data))
                  }
                } else {
                  ++errorCount
                  if (errorCount === bookingSources.length + 1) {
                    dispatch(fsError('Sorry, No flights found for this route.'))
                  }
                }

                dispatch(fsOnewayCount(parseInt(++i)))
              }).catch(() => {
                ++errorCount
                if (errorCount === bookingSources.length + 1) {
                  dispatch(fsError('Sorry, No flights found for this route.'))
                }
                dispatch(fsOnewayCount(parseInt(++i)))
              })
          })
        }
        
        if (!request.specialFare) {
          bookingSources.map((source, index) => {
            request.booking_source = source

            authPost(FLIGHT_API_URL + 'general/pre_flight_search', request)
              .then((response) => {
                if (response.data.status) {
                  //  flightData.push(response.data.data)
                  dispatch(fsOnwaySuccess(response.data.data, index))
                  if (response?.data?.data?.data?.raw_flight_list?.Flights[0] !== undefined) {
                    // allFlight.push(response?.data?.data?.data?.raw_flight_list?.Flights[0])
                    const dd = response?.data?.data?.data?.raw_flight_list?.Flights[0]
                    allFlight = [...allFlight, ...dd]

                    dispatch(fsSuccess(allFlight))
                  }
                } else {
                  ++errorCount
                  if (errorCount === bookingSources.length + 1) {
                    dispatch(fsError('Sorry, No flights found for this route.'))
                  }
                }

                dispatch(fsOnewayCount(parseInt(++i)))
              }).catch(() => {
                ++errorCount
                if (errorCount === bookingSources.length + 1) {
                  dispatch(fsError('Sorry, No flights found for this route.'))
                }
                dispatch(fsOnewayCount(parseInt(++i)))
              })
          })
        }
      }

      if (request.trip_type === 'circle') {
        if (request.fromCountry === 'India' && request.toCountry === 'India') {
          dispatch(fsRoundError(null))
          dispatch(fsRoundDataNull())
          dispatch(fsRoundLoading())
          dispatch(fsRoundRequest(request))
          let allFlight = []
          let count = 0
          let errorCount = 0
          request.booking_source = 'PTBSID0000002021'
          authPost(FLIGHT_API_URL + 'general/pre_flight_search_inv', request)
            .then((response) => {
              if (response.data.status) {
                if (response?.data?.data?.data?.raw_flight_list?.Flights.length > 0) {
                  
                  dispatch(fsRoundCompleteData(response.data.data))

                  if (allFlight.length > 0) {
                    const onWard = response?.data?.data?.data?.raw_flight_list?.Flights[0]
                    const outWard = response?.data?.data?.data?.raw_flight_list?.Flights[1]
                    allFlight[0] = [...allFlight[0], ...onWard]
                    allFlight[1] = [...allFlight[1], ...outWard]
                  } else {
                    const dd = response?.data?.data?.data?.raw_flight_list?.Flights
                    allFlight = [...allFlight, ...dd]
                  }
                  dispatch(fsRoundSuccess(allFlight))
                  dispatch(fsRoundCount(++count))
                  dispatch(fsRoundError(null))
                } else {
                  dispatch(fsRoundError('Sorry, No flights found for this route.'))
                }
              } else {
                
                dispatch(fsRoundCount(++count))
                ++errorCount
                // if (errorCount === bookingSources.length) {
                //   dispatch(fsRoundError('Sorry, No flights found for this route.'))
                // }

                ++errorCount
                if (errorCount === bookingSources.length + 1 && !request.specialFare) {
                  dispatch(fsError('Sorry, No flights found for this route.'))
                }
                if (errorCount === 1 && request.specialFare) {
                  dispatch(fsError('Sorry, No flights found for this route.'))
                }
              }
            }).catch(() => {
              dispatch(fsRoundCount(++count))
              // ++errorCount
              // if (errorCount === bookingSources.length) {
              //   dispatch(fsRoundError('Sorry, No flights found for this route.'))
              // }
              ++errorCount
                if (errorCount === bookingSources.length + 1 && !request.specialFare) {
                  dispatch(fsError('Sorry, No flights found for this route.'))
                }
                if (errorCount === 1 && request.specialFare) {
                  dispatch(fsError('Sorry, No flights found for this route.'))
                }
            })

            if (request.specialFare) {
              specialBookingSources.map((source) => {
                request.booking_source = source
                authPost(FLIGHT_API_URL + 'general/pre_flight_search', request)
                  .then((response) => {
                    if (response.data.status) {
                      if (response?.data?.data?.data?.raw_flight_list?.Flights.length > 0) {
                        dispatch(fsRoundCompleteData(response.data.data))
    
                        if (allFlight.length > 0) {
                          const onWard = response?.data?.data?.data?.raw_flight_list?.Flights[0]
                          const outWard = response?.data?.data?.data?.raw_flight_list?.Flights[1]
                          allFlight[0] = [...allFlight[0], ...onWard]
                          allFlight[1] = [...allFlight[1], ...outWard]
                        } else {
                          const dd = response?.data?.data?.data?.raw_flight_list?.Flights
                          allFlight = [...allFlight, ...dd]
                        }
                        dispatch(fsRoundSuccess(allFlight))
                        dispatch(fsRoundCount(++count))
                        dispatch(fsRoundError(null))
                      } else {
                        dispatch(fsRoundError('Sorry, No flights found for this route.'))
                      }
                    } else {
                      dispatch(fsRoundCount(++count))
                      ++errorCount
                      // if (errorCount === bookingSources.length) {
                      //   dispatch(fsRoundError('Sorry, No flights found for this route.'))
                      // }
    
                      if (errorCount === bookingSources.length + 1) {
                        dispatch(fsError('Sorry, No flights found for this route.'))
                      }
                    }
                  }).catch(() => {
                    dispatch(fsRoundCount(++count))
                    ++errorCount
                    // if (errorCount === bookingSources.length) {
                    //   dispatch(fsRoundError('Sorry, No flights found for this route.'))
                    // }
    
                    if (errorCount === bookingSources.length + 1) {
                      dispatch(fsError('Sorry, No flights found for this route.'))
                    }
                  })
              })
            }

            else if (!request.specialFare) {
          bookingSources.map((source) => {
            request.booking_source = source
            authPost(FLIGHT_API_URL + 'general/pre_flight_search', request)
              .then((response) => {
                if (response.data.status) {
                  if (response?.data?.data?.data?.raw_flight_list?.Flights.length > 0) {
                    dispatch(fsRoundCompleteData(response.data.data))

                    if (allFlight.length > 0) {
                      const onWard = response?.data?.data?.data?.raw_flight_list?.Flights[0]
                      const outWard = response?.data?.data?.data?.raw_flight_list?.Flights[1]
                      allFlight[0] = [...allFlight[0], ...onWard]
                      allFlight[1] = [...allFlight[1], ...outWard]
                    } else {
                      const dd = response?.data?.data?.data?.raw_flight_list?.Flights
                      allFlight = [...allFlight, ...dd]
                    }
                    dispatch(fsRoundSuccess(allFlight))
                    dispatch(fsRoundCount(++count))
                    dispatch(fsRoundError(null))
                  } else {
                    dispatch(fsRoundError('Sorry, No flights found for this route.'))
                  }
                } else {
                  dispatch(fsRoundCount(++count))
                  ++errorCount
                  // if (errorCount === bookingSources.length) {
                  //   dispatch(fsRoundError('Sorry, No flights found for this route.'))
                  // }

                  if (errorCount === bookingSources.length + 1) {
                    dispatch(fsError('Sorry, No flights found for this route.'))
                  }
                }
              }).catch(() => {
                dispatch(fsRoundCount(++count))
                ++errorCount
                // if (errorCount === bookingSources.length) {
                //   dispatch(fsRoundError('Sorry, No flights found for this route.'))
                // }

                if (errorCount === bookingSources.length + 1) {
                  dispatch(fsError('Sorry, No flights found for this route.'))
                }
              })
          })
        }
        } else {
          dispatch(fsRoundInternationalError(null))
          dispatch(fsRoundInternationalData())
          dispatch(fsRoundInternationalLoading())
          dispatch(fsRoundInternationalCount(null))
          dispatch(fsRoundRequest(request))
          let Flights = null
          let errorCount = 0
          let count = 0
          let Flightdata
          bookingSources.map((source) => {
            request.booking_source = source
            authPost(FLIGHT_API_URL + 'general/pre_flight_search', request)
              .then((response) => {
                if (response.data.status) {
                  if (response?.data?.data?.data?.raw_flight_list?.Flights[0] !== undefined) {
                    const onWard = response?.data?.data?.data?.raw_flight_list?.Flights[0]
                    const outWard = response?.data?.data?.data?.raw_flight_list?.Flights[1]
                    if (Flights) {
                      Flightdata = JSON.parse(JSON.stringify(Flights))
                      Flightdata.data.raw_flight_list.Flights[0] = [...Flightdata.data.raw_flight_list.Flights[0], ...onWard]
                      Flightdata.data.raw_flight_list.Flights[1] = [...Flightdata.data.raw_flight_list.Flights[0], ...outWard]
                      dispatch(fsRoundInternationalData(Flightdata))
                    } else {
                      Flights = JSON.parse(JSON.stringify(response.data.data))
                      dispatch(fsRoundInternationalData(Flights))
                    }
                  }
                } else {
                  ++errorCount
                  if (errorCount === bookingSources.length) {
                    dispatch(fsRoundInternationalError('Sorry, No flights found for this route.'))
                  }
                }

                dispatch(fsRoundInternationalCount(parseInt(++count)))
              }).catch(() => {
                ++errorCount
                if (errorCount === bookingSources.length) {
                  dispatch(fsRoundInternationalError('Sorry, No flights found for this route.'))
                }
                dispatch(fsRoundInternationalCount(parseInt(++count)))
              })
          })
        }
      }
      if (request.trip_type === 'multicity') {
        request.booking_source = 'PTBSID0000000002'
        dispatch(fsMultiError(null))
        dispatch(fsMultiDataNull())
        dispatch(fsMultiLoading())
        dispatch(fsMultiRequest(request))

        bookingSources.map((source) => {
          request.booking_source = source
          authPost(FLIGHT_API_URL + 'general/pre_flight_search', request)
            .then((response) => {
              if (response.data.status) {
                dispatch(fsMultiSuccess(response.data.data))
              } else {
                dispatch(fsMultiError('Sorry, No flights found for this route.'))
              }

             // dispatch(fsRoundInternationalCount(parseInt(++count)))
            }).catch(() => {
              dispatch(fsMultiError('Sorry, No flights found for this route.'))
            })
        })

        // authPost(API_BASE_URL + 'general/pre_flight_search', request)
        //   .then((response) => {
        //     if (response.data.status) {
        //       dispatch(fsMultiSuccess(response.data.data))
        //     } else {
        //       dispatch(fsMultiError('Sorry, No flights found for this route.'))
        //     }
        //   }).catch(() => {
        //     dispatch(fsMultiError('Sorry, No flights found for this route.'))
        //   })
      }
    } catch (error) {
      //   dispatch(fsError({data:'',msg:'Somthing went wrong please try again',status:false}))
    }
  }
}
