import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { Box, Container, TextField, Select, MenuItem, FormControl, Button, LinearProgress, Pagination, Stack, Tooltip, Divider } from '@mui/material'
import SideNav from '../../SideNav'
import MainFooter from '../footer/MainFooter'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';
import FlightIcon from '@mui/icons-material/Flight';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import './timeline.css';

const ItineraryTimeline = () => {

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <SideNav header={"Itinerary"} />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <Container>
                        <Card className='px-4 py-2 mt-3'>
                            <Row className='mb-1'>
                                <h5 className='py-2 px-3'>Itinerary Timeline</h5>
                                <Divider />
                            </Row>
                            <Row>
                                <Timeline className='timeline'>
                                    <TimelineItem>
                                        <TimelineSeparator>
                                        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                                        <TimelineDot color="secondary">
                                            <FlightIcon />
                                        </TimelineDot>
                                        <TimelineConnector sx={{ bgcolor: 'secondary.main' }}/>
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                                        <Typography variant="h6" component="span">
                                            Flight <span>(Onward)</span>
                                        </Typography>
                                        <p className='timeline-date mb-0'><span className='text-black'>Departure :</span> <b>23-09-2023 | 04:00 AM</b></p>
                                        <p className='timeline-date mb-1'><span className='text-black'>Arrival :</span> <b>23-09-2023 | 06:00 AM</b></p>
                                        <p className='mb-0'>Sector : <b>BLR-HYD</b></p>
                                        <p className='mb-0'>Flight Number : <b>AirAsia I5-2461</b></p>
                                        </TimelineContent>
                                    </TimelineItem>

                                    <TimelineItem className='tranfers-dot'>
                                        <TimelineSeparator>
                                            <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                                            <TimelineDot color="secondary">
                                                {/* <FlightIcon /> */}
                                            </TimelineDot>
                                            <TimelineConnector sx={{ bgcolor: 'secondary.main' }}/>
                                        </TimelineSeparator>
                                        <TimelineContent sx={{  px: 2 }}>
                                        <Typography variant="h6" component="span">
                                            Airport Transfers
                                        </Typography>
                                        </TimelineContent>
                                    </TimelineItem>

                                    <TimelineItem>
                                        <TimelineSeparator>
                                        <TimelineConnector sx={{ bgcolor: 'secondary.main' }}/>
                                        <TimelineDot color="primary">
                                            <HotelIcon />
                                        </TimelineDot>
                                        <TimelineConnector sx={{ bgcolor: 'secondary.main' }}/>
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                                        <Typography variant="h6" component="span">
                                            Hotel
                                        </Typography>
                                        <p className='timeline-date mb-0'><span className='text-black'>Check-in :</span> <b>23-09-2023 | 02:00 PM</b></p>
                                        <p className='timeline-date mb-1'><span className='text-black'>Check-out :</span> <b>27-09-2023 | 01:00 PM</b></p>
                                        <p className='mb-0'>Hotel : <b>Test Hotel, Bhubaneshwar, RC 1, double</b></p>
                                        <p className='mb-0'>Room Category : <b>RC1</b></p>
                                        </TimelineContent>
                                    </TimelineItem>
                                    <TimelineItem>
                                        <TimelineSeparator>
                                        <TimelineConnector sx={{ bgcolor: 'secondary.main' }}/>
                                        <TimelineDot color="primary" variant="outlined">
                                            <SportsBaseballIcon />
                                        </TimelineDot>
                                        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                                        <Typography variant="h6" component="span">
                                            Match
                                        </Typography>
                                        <p className='timeline-date mb-1'><span className='text-black'>Match Date :</span> <b>04-09-2023</b></p>
                                        <p className='mb-0'>Match : <b>India vs Australia, Chennai</b></p>
                                        <p className='mb-0'>Team : <b>India vs Australia</b></p>
                                        </TimelineContent>
                                    </TimelineItem>
                                    <TimelineItem className='tranfers-dot'>
                                        <TimelineSeparator>
                                            <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                                            <TimelineDot color="secondary">
                                                {/* <FlightIcon /> */}
                                            </TimelineDot>
                                            <TimelineConnector sx={{ bgcolor: 'secondary.main' }}/>
                                        </TimelineSeparator>
                                        <TimelineContent sx={{  px: 2 }}>
                                        <Typography variant="h6" component="span">
                                            Airport Transfers
                                        </Typography>
                                        </TimelineContent>
                                    </TimelineItem>

                                    <TimelineItem>
                                        <TimelineSeparator>
                                        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                                        <TimelineDot color="secondary">
                                            <FlightIcon />
                                        </TimelineDot>
                                        <TimelineConnector sx={{ bgcolor: 'secondary.main' }}/>
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                                        <Typography variant="h6" component="span">
                                            Flight <span>(Return)</span>
                                        </Typography>
                                        <p className='timeline-date mb-0'><span className='text-black'>Departure :</span> <b>27-09-2023 | 04:00 AM</b></p>
                                        <p className='timeline-date mb-1'><span className='text-black'>Arrival :</span> <b>27-09-2023 | 06:00 AM</b></p>
                                        <p className='mb-0'>Sector : <b>HYD-BLR</b></p>
                                        <p className='mb-0'>Flight Number : <b>AirAsia I5-2461</b></p>
                                        </TimelineContent>
                                    </TimelineItem>
                                </Timeline>
                            </Row>
                        </Card>
                    </Container>
                </Box>
            </Box>
            <MainFooter />
        </>
    )
}

export default ItineraryTimeline
