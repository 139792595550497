import React,{useEffect, useState} from 'react'
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router } from 'react-router-dom'
import theme from './core/theme'
import { Provider } from 'react-redux'
import store from '../app/store/index'
import './App.css'
import './common.css'
import Main from './SideNav'
import Footer from './pages/footer/Footer'
import Header from './pages/header/Header';
import LoginRoutes from './LoginRoutes'

function App () {
  const [login, setLogin] = useState(false)
  // useEffect(() => {
  //   document.addEventListener('contextmenu', (e) => {
  //     e.preventDefault();
  //   //  alert("Right click disabled")
  //   });
  // },[]);
 // console.log("Store123", store)
  return (
    <ThemeProvider theme={theme}>
    <Provider store={store}>
        <Router forceRefresh={false}>
        <LoginRoutes /> 
          {/* {!login ? <LoginRoutes /> : <Main />} */}
        </Router>
        {/* <Footer /> */}
    </Provider>
    </ThemeProvider>
  )
}

export default App
