import React, { useState } from 'react'
import { Box, Button, ButtonGroup } from '@mui/material'
import Sunrise from '../../../../assets/images/sunrise.svg'
import Sun from '../../../../assets/images/sun.svg'
import Evening from '../../../../assets/images/evening.svg'
import Moon from '../../../../assets/images/moon.svg'

const Timing = (props) => {
  const { handleChange, isSelected } = props
  const [time, setTime] = useState(null)

  const onChangeTimings = (time) => {
    if(time == isSelected)
    {
        time = null
    }
    handleChange(time)
    setTime(time)
  }

  // active-button
  return (
        <div className="timing-filter">
            <Box className="tlt-heading">{props.title} {props.source}</Box>
            <Box size="small" aria-label="small outlined" className="timingroup">
                <Button className={isSelected === '00-06' ? 'timing-item active-button' : 'timing-item' } onClick={() => onChangeTimings('00-06')}>
                    <div className="iconbox">
                        <div className="icon-imgs"></div>
                        <div className="icon-time"><img className="icosymbol" src={Sunrise}></img> <span>00-06 Early Morning</span></div>
                    </div>
                </Button>
                <Button className={isSelected === '06-12' ? 'timing-item active-button' : 'timing-item' } onClick={() => onChangeTimings('06-12')}>
                    <div className="iconbox">
                        {/* <div className="icon-imgs"></div> */}
                        <div className="icon-time"><img className="icosymbol" src={Sun}></img>  <span>06-12 Morning</span></div>
                    </div>
                </Button>
                <Button className={isSelected === '12-18' ? 'timing-item active-button' : 'timing-item' } onClick={() => onChangeTimings('12-18')}>
                    <div className="iconbox">
                        {/* <div className="icon-imgs"></div> */}
                        <div className="icon-time"><img className="icosymbol" src={Evening}></img><span>12-18 Afternoon</span></div>
                    </div>
                </Button>
                <Button className={isSelected === '18-24' ? 'timing-item active-button' : 'timing-item' } onClick={() => onChangeTimings('18-24')}>
                    <div className="iconbox">
                        {/* <div className="icon-imgs"></div> */}
                        <div className="icon-time"><img className='icosymbol icon-moon' src={Moon}></img> <span>18-00 Evening</span></div>
                    </div>
                </Button>
            </Box>
        </div>
  )
}

export default Timing
