import React from 'react'
import { Box } from '@mui/material'
import { Col, Row } from 'react-bootstrap';
import imgnoflight from '../../../assets/images/no-flight.png'
import { makeStyles } from '@mui/styles';
import './flights.css'

function NotFoundFlight () {
  return (
    <div className='filtererrwrp' >
          <Row>
            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
              <div className='notfoundddata'>
                <div className='imgfnd'><img src={imgnoflight} className='imgdata'/></div>
              </div>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} xl={8} className="m-auto">
              <div className='errfiltertxt'>
                <div className='para'> Sorry, No flights found for this route.</div>
              </div>
            </Col>
          </Row>
           
        </div>
        

  )
}

export default NotFoundFlight

