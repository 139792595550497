import React, {useState, useEffect} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './login.css'
import { emailValidator, OTPValidator } from './validation';
import { useNavigate } from "react-router-dom";
import { CircularProgress } from '@mui/material';
import { post } from '../../utility/apiConfig';
import { ACCESS_TOKEN, API_BASE_URL, REFRESH_TOKEN, USER_TYPE } from '../../utility/apiConstants';
import Header from '../header/Header';
import Footer from '../footer/Footer';

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Login() {
  const navigate = useNavigate();
  const [showInputOTP, setShowInputOTP] = useState(true);
  const [showInputEmail, setShowInputEmail] = useState(true);
  const [loadSignIn, setLoadSignIn] = useState(false);
  const [loadOTP, setLoadOTP] = useState(false);
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(null);
  const [otp, setOtp] = useState("");
  const [otpErr, setOtpErr] = useState(null);

  const SignInClick = (e) =>{
    e.preventDefault();
    let emailError = emailValidator(email);
    if(emailError){
        setEmailErr(emailError);
        return
    }
    else{
    // setShowInputOTP(true);
   // setShowInputEmail(false);
   handleSignIn();
    }
  }

  const validateOTP = (e) =>{
    e.preventDefault();
    let otpError = OTPValidator(otp);
    if(otpError){
        setOtpErr(otpError);
        return
    }
    else{
   // setShowInputOTP(false);
   // navigate("/sign-up");
    handleOTP();
    }
  }

  const handleSignIn = () => {
    let dataToSend = { username: email, user_type: USER_TYPE }
    setLoadSignIn(true);

    try {
        post(API_BASE_URL+'login/login_auth', dataToSend)
        .then((response)=>{
           //  //console.log"response=======>",response.data);
            if(response.data.status){
                alert(response.data.data.message)
                setLoadSignIn(false);
                setShowInputEmail(false);
            }else{
                alert(response.data.message)
                setLoadSignIn(false);
            }
         })
         .catch((error)=>{
            alert(error?.response?.data?.message)
            setLoadSignIn(false);
         })
        } catch (error) {
            
}
}

const handleOTP = () => {
  setLoadOTP(true);
  let dataToSend = {
    "username" : email,
    "otp" : otp
}

  try {
      post(API_BASE_URL+'login/verify_login_otp', dataToSend)
      .then((response)=>{
         //  //console.log"response=======>",response.data);
          if(response.data.status && response.data.type === 'login'){
             // alert(response.data.message)
             localStorage.setItem(ACCESS_TOKEN, response.data.token.access_token);
              localStorage.setItem(REFRESH_TOKEN, response.data.token.refresh_token);
              localStorage.setItem('login_data', JSON.stringify(response.data));
              setLoadOTP(false);
              setShowInputOTP(false);
              navigate("/")
          }else{
              alert('Somthing went wrong please try again')
              setLoadOTP(false);
          }
       })
       .catch((error)=>{
          alert('Somthing went wrong please try again')
          setLoadOTP(false);
       })
      } catch (error) {
          
}
}
useEffect(() => {
  const inputOTP = document.getElementById("enter_otp");
  inputOTP.focus();
})
  return (
    <>
    <Header />
    <ThemeProvider theme={defaultTheme}>
    <div className='user-container login-container'>
      <Container >
        <CssBaseline />
        <Grid container>
          <Grid item xs={12} md={4} className='m-auto'>
          <Box
          className="login-outer-box"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>

          {/* <Box component="form" noValidate sx={{ mt: 1 }}> */}
          {showInputEmail &&
            <>
                <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e)=> {setEmail(e.target.value); setEmailErr(null)}}
                error={emailErr}
                helperText={emailErr}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    SignInClick(event)
                  }
                }}
                />

                <Button
                type="submit"
                className='btnSignUp'
                fullWidth
                // color='success'
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={(e)=> SignInClick(e)}
                disabled={loadSignIn}
                >
                   { loadSignIn ? <CircularProgress /> : 'Get OTP' } 
                </Button>
            </>     
            }
          
            <>
              <Box className={`${!showInputEmail ? 'otp-box animate': 'otp-box-hide'}`}>
                  <Alert className='mt-3' severity="success">We have sent an OTP to your registered Email ID</Alert>
                  <TextField
                  margin="normal"
                  required
                  fullWidth
                  autoFocus
                  name="Enter OTP"
                  label="Enter OTP"
                  type="number"
                  id="enter_otp"
                  autoComplete="OTP"
                  value={otp}
                  onChange={(e)=> {setOtp(e.target.value); setOtpErr(null)}}
                  error={otpErr}
                  helperText={otpErr}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      validateOTP(event)
                    }
                  }}
                  />
              
                  <Button
                  type="submit"
                  fullWidth
                  className='btnSignUp'
                  variant="contained"
                  // color='success'
                  sx={{ mt: 3, mb: 2 }}
                  onClick={(e)=> validateOTP(e)}
                  disabled={loadOTP}
                  >
                  {/* <Link  href="/dashboard">Login</Link> */}
                  { loadOTP ? <CircularProgress /> : 'Login' } 
                  </Button>
              </Box>
            </>
            
            <Grid container className='signin-footer'>
             
              <Grid item xs={12} className='text-center'>
                <Link href="#" variant="body2">
                    Don't have an account? <span className='text-danger' onClick={()=> navigate("/sign-up")}>Sign Up</span>
                </Link>
              </Grid>
            </Grid>
          </Box>
          </Grid>
        </Grid>
        
        {/* </Box> */}
      </Container>
      </div>
    </ThemeProvider>
    <Footer />
    </>
  );
}